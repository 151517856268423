<app-side-bar></app-side-bar>
<div class="container">
  <div class="container-inventory">
    <div class="header">
      <h1 class="title">Configuración</h1>
      <app-back-arrow [route]="'/home'"></app-back-arrow>
    </div>

    <div class="loading" *ngIf="loading">
      <div class="spinner-border" role="status"></div>
    </div>

    <div class="box-container">
      <app-option-box title="Información General" icon="tool" [routerLink]="'/settings/general'"></app-option-box>
      <app-option-box title="Usuarios y Roles" icon="users" [routerLink]="'/users'"></app-option-box>
      <app-option-box title="Puntos de Venta" icon="shopping-bag"></app-option-box>
      <app-option-box title="Tema de la Tienda" icon="loader" [routerLink]="'/settings/theming'"></app-option-box>
      <app-option-box title="Plan Actual" icon="layers" [routerLink]="'/settings/current-plan'"></app-option-box>
      <app-option-box title="Nuestras Políticas" icon="info"></app-option-box>
    </div>

    <!-- <div class="form-card" *ngIf="!loading">
      <div class="label">
        <strong>Información General</strong>
      </div>
      <div class="info-container">
        <div class="data-container">
          <label class="data-label" for="">Nombre de la Tienda</label>
          <output class="data-output">{{ shopTheme[0].shopName }}</output>
        </div>
        <div class="data-container">
          <label class="data-label" for="">Correo Electrónico</label>
          <output class="data-output">{{ shopTheme[0].mail }}</output>
        </div>
      </div>
    </div>
    <div class="form-card" *ngIf="!loading">
      <div class="label">
        <strong>Contacto</strong>
      </div>
      <div class="info-container">
        <div class="data-container">
          <label class="data-label" for="">Número de Teléfono</label>
          <output class="data-output">{{ shopTheme[0].phone }}</output>
        </div>
        <div class="data-container">
          <label class="data-label" for="">Dirección</label>
          <output class="data-output">{{ shopTheme[0].locations }}</output>
        </div>
      </div>
    </div>
  </div> -->
  </div>

  <div class="modal product-modal" id="productModal" tabindex="-1">
    <div class="modal-dialog product-modal-dialog" role="document">
      <div class="modal-content product-modal-content">
        <div class="modal-body product-modal-body">
          <div class="loading">
            <div class="spinner-border" role="status"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
