<div class="container">
  <div class="container-payment-methods">
    <div class="header">
      <h1 class="title">Metodos de Pago</h1>
      <button class="btn btn-outline-primary primary-btn" (click)="setCreatingPaymentMethod()">Agregar</button>
    </div>
    <div class="form-card">
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>  
      </div>
      <div class="d-flex justify-content-center align-items-center form-text" *ngIf="!loading && paymentMethods.length == 0">
        <h5>Actualmente no posee métodos de pago registrados</h5>
      </div>
      <table *ngIf="!loading && paymentMethods.length > 0" class="table table-bordered table-hover">
        <thead>
          <th scope="col" class="table-start">Metodo de pago</th>
          <th scope="col">Estado</th>
          <th scope="col">Editar</th>
          <th scope="col" class="table-end">Eliminar</th>
        </thead>
        <tbody>
          <tr *ngFor="let paymentMethod of paymentMethods">
            <td>
              <a [routerLink]="['/payment', paymentMethod.paymentTypeId]" routerLinkActive="active">{{ paymentMethod.paymentTypeName }}</a>
            </td>
            <td>Activo</td>
            <td>
              <button
                class="btn btn-primary primary-btn"
                (click)="setEditingPaymentMethod(paymentMethod.paymentTypeId)"
              >
                Editar
              </button>
            </td>
            <td>
              <button
                class="btn btn-primary remove-btn" (click)="modalDelete = true;  paymentTypeIdToDelete = paymentMethod.paymentTypeId">
                Eliminar
              </button>
            </td>
          </tr>  
        </tbody>
      </table>
    </div>
  </div>
</div>
<app-confirmation-modal *ngIf="modalDelete" [title]="'Eliminar método de pago'" [message]="'¿Seguro que quiere eliminar este método de pago?'"
  [confirmText]="'Eliminar'" [cancelText]="'Cancelar'" (response)="deletePayment($event)">
</app-confirmation-modal>

<div class="modal delete-paymentMethod-confirm-modal" id="deletePaymentModal" tabindex="-1">
  <div class="modal-dialog paymentMethod-modal-dialog" role="document">
    <div class="modal-content paymentMethod-modal-content">
      <div class="modal-body paymentMethod-modal-body">
        <h3 class="text-center">¡Éxito!</h3>
        <p class="text-center">¡El punto de venta fue eliminado exitosamente!</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
      </div>
    </div>
  </div>
</div>
