import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-back-arrow',
  templateUrl: './back-arrow.component.html',
  styleUrls: ['./back-arrow.component.scss']
})
export class BackArrowComponent {

  @Input() route: string ="";

  constructor(private router: Router) { }

  /**
   * The goBack() function navigates back in the platform's history
   */
    goBack() {
      this.router.navigate([this.route]); // Navigates back in the platform's history
    }
}
