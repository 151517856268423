import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from 'src/app/settings.service';
import { ApiService } from 'src/app/api.service'
import { ThemingService } from 'src/app/theming.service';
declare var $: any;

@Component({
  selector: 'app-create-supplier',
  templateUrl: './create-supplier.component.html',
  styleUrls: ['./create-supplier.component.scss']
})
export class CreateSupplierComponent implements OnInit {
  supplier = {
    name: '',
    description: ''
}
  saveSupplier = false;
  suppliers: any [] = [];
  constructor(
    private settingsService: SettingsService,
    private apiService:ApiService,
    public themingService: ThemingService,
  ) { }

  ngOnInit(): void {
    this.saveSupplier = false;
  }
    changeSupplierName(event: Event){
      const element = event.target as HTMLInputElement;
      this.supplier.name = element.value;
    }

    ChangeSupplierDescription(event: Event){
      const element = event.target as HTMLInputElement;
      this.supplier.description = element.value;
    }
  validateFormData(){
    if (! this.supplier.name || this.supplier.name.trim() === '') {
      $('#requiredSupplierModal').modal('show');
      return;
    }
    this.createSupplier();
  }

  createSupplier (){
    $('#supplierLoadingModal').modal({ backdrop: 'static', keyboard: false });
    $('#supplierLoadingModal').modal('show');
    this.saveSupplier = false;
    this.apiService.createSupplier(
        this.supplier.name,
        this.supplier.description,
        this.themingService.shopId
        ).then((data: any) => {
          if(data.status === 200){
            this.saveSupplier = true;
            this.resetForm();
            $('#supplierLoadingModal').modal('hide');
            $('#successSuppliersModal').modal('show');
          }
          else{
            $('#errorSuppliersModal').modal('show');
            $('#supplierLoadingModal').modal('hide');
          }
         
        }) .catch((error) => {
          console.error(error);
        })
  }

  @ViewChild('supplierName')
  supplierName!: ElementRef;
  @ViewChild('supplierDescription')
  supplierDescription!: ElementRef; 
  
  resetForm() {
    this.supplierName.nativeElement.value = '';
    this.supplierDescription.nativeElement.value = '';
    this.supplier.name = '';
  }

  closeModal(){
    $('#successSuppliersModal').modal('hide');
    $('#errorSuppliersModal').modal('hide');
    $('#requiredSupplierModal').modal('hide');
  }

  setListingSuppliers(){
    this.settingsService.setListingSuppliers();
  }
}

