<div class="container">
	<div class="container-categories">
	  <div class="header">
		<h1 class="title">Sub-subcategorías</h1>
		<button *ngIf="!showCreateSubSubCategoryModal" class="btn btn-outline-primary primary-btn" (click)="openCreateSubSubCategoryModal()">Nueva</button>
		<button *ngIf="showCreateSubSubCategoryModal" class="btn btn-primary remove-btn" (click)="closeCreateSubSubCategoryModal()">Cancelar</button>
	</div>
	<app-create-sub-subcategory *ngIf="showCreateSubSubCategoryModal" (subSubCategoryCreated)="handleSubSubCategoryCreated()"[categoryId]="categoryId"></app-create-sub-subcategory>
	  <div class="form-card">
		<div class="search-container visually-hidden">
		  <input
			type="text"
			placeholder="Buscar"
			[(ngModel)]="searchString"
			(keyup)="searchSubSubCategories()"
		  />
		</div>
		<div class="loading" *ngIf="loading">
		  <div class="spinner-border" role="status"></div>
		</div>
		<table *ngIf="!loading" class="table table-bordered table-hover">
		  <thead>
			<th scope="col" class="table-start">Id</th>
			<th scope="col">Sub-subcategoría</th>
			<th scope="col">Imagen</th>
			<th scope="col">Subcategoría</th>
			<th scope="col">Categoría</th>
			<th scope="col">Editar</th>
			<th scope="col" class="table-end">Eliminar</th>
		  </thead>
		  <tbody>
			<tr *ngFor="let subSubCategory of subSubCategories">
			  <ng-container *ngIf="subSubCategory.categoryId === categoryId">
				<td>{{ subSubCategory.subSubCategoryId }}</td>
				<td>{{ subSubCategory.subSubCategoryName }}</td>
				<td>
					<img class="image-box" *ngIf="subSubCategory.subSubCategoryImageUrl" [src]="subSubCategory.subSubCategoryImageUrl" alt="subSubCategoryImageUrl">
					<span *ngIf="!subSubCategory.subSubCategoryImageUrl">Sin Imagen</span>
				</td>
				<td>{{ subSubCategory.subCategoryName }}</td>
				<td>{{ subSubCategory.categoryName }}</td>
				<td>
					<button class="btn btn-primary primary-btn" (click)="openEditSubCategoryModal(subSubCategory.subSubCategoryId)">
						Editar
					  </button>
				</td>
				<td>
				  <button class="btn btn-primary remove-btn" (click)="modalDelete = true; subSubCategoryIdToDelete = subSubCategory.subSubCategoryId">
					Eliminar
				  </button>
				</td>
			  </ng-container>
			</tr>
		  </tbody>
		</table>
	  </div>
	</div>
  
	<app-confirmation-modal
	  *ngIf="modalDelete"
	  [title]="'¿Seguro que quiere eliminar esta sub-subcategoría?'"
	  [message]="'Al eliminar esta sub-subcategoría se eliminarán los productos que esten relacionados a ella así como su sección en la pantalla de inicio y opción en el menú principal'"
	  [confirmText]="'Eliminar'"
	  [cancelText]="'Cancelar'"
	  (response)="deleteSubSubCategory($event)">
	</app-confirmation-modal>

	<section class="modal-section" *ngIf="showEditSubSubCategoryModal">
		<div class="modal-content">
		  <div class="modal-body">
			<app-edit-sub-subcategory *ngIf="showEditSubSubCategoryModal" [categoryId]="selectedCategoryId" (subSubCategoryUpdated)="handleSubSubCategoryUpdated()" [id]="selectedSubSubCategoryId"></app-edit-sub-subcategory>
			<div class="modal-btn">
			  <button class="btn btn-primary remove-btn" (click)="closeEditSubSubCategoryModal()">Cancelar</button>
			</div>
		  </div>
		</div>
	  </section>
  
	<div class="modal delete-category-confirm-modal" id="deleteSubSubCategoryModal" tabindex="-1">
	  <div class="modal-dialog category-modal-dialog" role="document">
		<div class="modal-content category-modal-content">
		  <div class="modal-body category-modal-body">
			<h3 class="text-center">¡Éxito!</h3>
			<p class="text-center">¡La sub-subcategoría fue eliminada exitosamente!</p>
		  </div>
		  <div class="modal-buttons">
			<button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
		  </div>
		</div>
	  </div>
	</div>
  </div>
  