import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/settings.service';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
declare let $: any;

@Component({
selector: 'app-supplier',
templateUrl: './supplier.component.html',
styleUrls: ['./supplier.component.scss']
})

export class SupplierComponent implements OnInit {
@Input() collectionId: string = '';

suppliers: any[] = [{}]
loading: boolean = false;
searchString: string = '';
modalDelete = false;
supplierIdToDelete: any;

constructor(
  private apiService: ApiService, 
  private settingsService: SettingsService,
  public router: Router, 
  private themingService: ThemingService
) {
  }

ngOnInit(): void {
  this.getSuppliers()
}

  async getSuppliers () {
  this.loading = true;
  await this.apiService
  .getSuppliers(
    this.themingService.shopId
    )
    .then((data: any) => {
      console.log(data);
      this.suppliers = data.suppliers;
    });
    this.loading = false;
  }
  
  searchSuppliers(){
    
  }
  
  setEditingSupplier(supplierId: string){
    this.settingsService.setEditingSupplier(supplierId);
  }
  
  setCreatingSupplier(){
    this.settingsService.setCreatingSupplier();
  }
  
  deleteSupplier(response: number) {
    if (response) {
      this.loading = true;
      this.apiService.deleteSupplier(this.themingService.shopId, this.supplierIdToDelete)
        .then((data) => {
          console.log("data: ", JSON.stringify(data));
          this.getSuppliers();
          $('#deleteSupplierModal').modal('show');
          this.loading = false; 
        })
        .catch((error) => {
          console.error(error);
          this.loading = false; 
        });
    }
    this.modalDelete = false;
  }
  

  closeModal(){
    $('#deleteSupplierModal').modal('hide');
  }
}
  

