import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/auth.service';
import { ThemingService } from 'src/app/theming.service';


@Component({
  selector: 'app-daily-sales',
  templateUrl: './daily-sales.component.html',
  styleUrls: ['./daily-sales.component.scss']
})
export class DailySalesComponent implements OnInit {

  barChart: any;
  loading = false;

  channel: number = 0;
  today = new Date();
  date: string = "";
  ventas: any[] =[];
  vendors: any[] =[];
  hoursArray: Array<any> = ["12am","1am","2am","3am","4am","5am","6am","7am","8am","9am","10am","11am","12pm","1pm","2pm","3pm","4pm","5pm","6pm","7pm","8pm","9pm","10pm","11pm"];
  salePerHour: number[] = Array.from({length: 24}, (_, index) => index);
  saleAmountPerHour: number[] = Array.from({length: 24}, (_, index) => index);
  totalSalesQuantity: number = 0;
  totalSalesAmount: number = 0;
  onlineTotalAmount: number = 0;
  posTotalAmount: number = 0;

  posSales: number[] = Array.from({length: 24}, (_, index) => index);
  onlineSales: number[] = Array.from({length: 24}, (_, index) => index);

  startDate: string = "";
  endDate: string = "";
  tempStartDate: Date = new Date();
  tempEndDate: Date = new Date();
  permissions: any = {};

  constructor(
    public themingService: ThemingService,
    private apiService: ApiService,
    public appComponent: AppComponent,    
    private authService: AuthService,
  ) {
  }


  async ngOnInit(): Promise<void> {
    this.formatDate(this.today);
    await this.authService.getUserPermissions().then((permissions: any) => {
      this.permissions = permissions;
      if(this.permissions.home == true){
        this.getDailySales();
      }
    }).catch((error) => {
      console.error('Error al obtener permisos del usuario:', error);
    });
  }

  async getDailySales(){
    await this.apiService
    .getDailySales(this.themingService.shopId, this.channel, this.startDate, this.endDate)
    .then((data: any) => {
      this.totalSalesAmount = 0;
      this.posTotalAmount = 0;
      this.onlineTotalAmount = 0;
      this.totalSalesQuantity = 0;
      this.ventas = data.sales;
      this.vendors = data.vendors;
      console.log("VENTAS: ", this.ventas);
      console.log("VENDORS: ", this.vendors);
      console.log("DATA: ", data);
      this.orderHourSales();
      this.orderAmountSales();
      this.orderSales();
      this.createBarChart();
    })
    .catch((error) => {
      console.error(error);
    });
  }

  orderSales(){
    this.saleAmountPerHour.fill(0);
    this.onlineSales.fill(0);
    this.posSales.fill(0);
    if(this.ventas){
      this.ventas.forEach((venta: any) => {
        let horaVenta = venta.saleDate.split("T")[1];
        horaVenta = horaVenta.split(":")[0];
        if (/^0\d$/.test(horaVenta)) {
          horaVenta = horaVenta.substring(1);
        }
        if(venta.channelId == 1){
          this.onlineSales[horaVenta] += venta.saleEndPrice;
        }else{
          this.posSales[horaVenta] += venta.saleEndPrice;
        }
        //this.saleAmountPerHour[horaVenta] += venta.saleEndPrice;
      });
    }
  }

  orderHourSales() {
    this.salePerHour.fill(0);
    if(this.ventas){
      this.ventas.forEach((venta: any) => {
        this.totalSalesQuantity += 1;
        this.totalSalesAmount += venta.saleEndPrice;
        if(venta.channelId == 1){
          this.onlineTotalAmount += venta.saleEndPrice;
        }else{
          this.posTotalAmount += venta.saleEndPrice;
        }
        let horaVenta = venta.saleDate.split("T")[1];
        horaVenta = horaVenta.split(":")[0];
        if (/^0\d$/.test(horaVenta)) {
          horaVenta = horaVenta.substring(1);
        }
        this.salePerHour[horaVenta] += 1;
      });
    }
  }

  orderAmountSales() {
    this.saleAmountPerHour.fill(0);
    if(this.ventas){
      this.ventas.forEach((venta: any) => {
        let horaVenta = venta.saleDate.split("T")[1];
        horaVenta = horaVenta.split(":")[0];
        if (/^0\d$/.test(horaVenta)) {
          horaVenta = horaVenta.substring(1);
        }
        this.saleAmountPerHour[horaVenta] += venta.saleEndPrice;
      });
    }
    
  }

  createBarChart() {
    if(this.barChart){
      this.barChart.destroy();
    }
    this.barChart = new Chart('canvas', {
      type: 'bar',
      data: {
        labels: this.hoursArray,
        datasets: [
          {
            label: `POS`,
            data: this.posSales,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1,
            hidden: false
          },
          {
            label: `ONLINE`,
            data: this.onlineSales,
            backgroundColor: [
              'rgba(54, 162, 235, 0.2)',
            ],
            borderColor: [
              'rgba(54, 162, 235, 1)',
            ],
            borderWidth: 1,
            hidden: false
          },
        ]
      },
      options: {
        aspectRatio:2.5,
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }

  // Date Filters
  onStartDateChange(startDate: Date): void {
    this.startDate = this.getDate(startDate);
  }
  
  onEndDateChange(endDate: Date): void {
    this.endDate = this.getDate(endDate);
    this.getDailySales();
  }

  getDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  @ViewChild('startDateInput')
  startDateInput!: ElementRef;
  @ViewChild('endDateInput')
  endDateInput!: ElementRef;
  
  resetDateFilters(): void {
    this.startDateInput.nativeElement.value = null;
    this.endDateInput.nativeElement.value = null;
    this.getDailySales();
  }

  // onDateChange() {
  //   const date = new Date(this.date + 'T00:00:00');
  //   this.formatDate(date);
  //   if(this.barChart){
  //     this.barChart.destroy();
  //   }
  //   this.totalSalesAmount = 0;
  //   this.totalSalesQuantity = 0;
  //   this.getDailySales();
  // }

  // onChannelChange(event: Event){
  //   const element = event.target as HTMLInputElement;
  //   this.channel = parseInt(element.value);
  //   if(this.barChart){
  //     this.barChart.destroy();
  //   }
  //   this.getDailySales();
  // }

  formatDate(date: any){
    // Convierte la fecha al formato yyyy-mm-dd
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    this.date = `${year}-${month}-${day}`;
    this.startDate = `${year}-${month}-${day}`;
    this.endDate = `${year}-${month}-${day}`;
  }

}
