<section [@inOutAnimation]>
  <div class="modal-content">
    <div class="modal-body">
      <h3>{{title}}</h3>
      <p>{{message}}</p>
    </div>
    <div class="modal-buttons">
      <button class="btn-confirm" (click)="confirm()">{{confirmText}}</button>
      <button class="btn-cancel" (click)="cancel()" *ngIf="twoFieldModal">{{cancelText}}</button>
    </div>
  </div>
</section>



