import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { SettingsService } from 'src/app/settings.service';
import { ThemingService } from 'src/app/theming.service';
declare let $: any;

@Component({
  selector: 'app-shop-points',
  templateUrl: './shop-points.component.html',
  styleUrls: ['./shop-points.component.scss']
})
export class ShopPointsComponent implements OnInit {

  shopPoints: any[] = [{}]
  loading: boolean = false;
  searchString: string = '';
  modalDelete = false;
  shopPointIdToDelete: any;


  constructor(
    public themingService: ThemingService,
    public apiService: ApiService,
    public settingsService: SettingsService
  ) { }

  ngOnInit() {
    this.getShopPoints();
  }
  
  async getShopPoints() {
    this.loading = true;
    await this.apiService.getShopPoints(this.themingService.shopId)
    .then((data: any) => {
      console.log(data);
      this.shopPoints = data;
    })
    .catch((error) => {
      console.error(error);
      this.loading = false; 
    });
    this.loading = false;
  }

  searchShopPoints(){
    
  }
  
  setEditingShopPoint(shopPointId: string){
    this.settingsService.setEditingShopPoint(shopPointId);
  }
  
  setCreatingShopPoint(){
    this.settingsService.setCreatingShopPoint();
  }

  deleteShopPoint(response: number) {
    if (response) {
      this.loading = true;
      this.apiService.deleteShopPoint(this.themingService.shopId, this.shopPointIdToDelete)
        .then((data) => {
          console.log("data: ", JSON.stringify(data));
          this.getShopPoints();
          $('#deleteShopPointModal').modal('show');
          this.loading = false; 
        })
        .catch((error) => {
          console.error(error);
          this.loading = false; 
        });
    }
    this.modalDelete = false;
  }

  closeModal(){
    $('#deleteShopPointModal').modal('hide');
  }

}
