<div class="container">
	<div class="container-categories">
		<div class="header">
			<h1 class="title">Subcategorías</h1>
			<button *ngIf="!showCreateSubCategoryModal" class="btn btn-outline-primary primary-btn" (click)="openCreateSubCategoryModal()">Nueva</button>
			<button *ngIf="showCreateSubCategoryModal" class="btn btn-primary remove-btn" (click)="closeCreateSubCategoryModal()">Cancelar</button>
		</div>
		<app-create-subcategory *ngIf="showCreateSubCategoryModal" (subCategoryCreated)="handleSubCategoryCreated()" [categoryId]="categoryId"></app-create-subcategory>
		<div class="form-card">
		<div class="loading" *ngIf="loading">
			<div class="spinner-border" role="status"></div>
		</div>
			<table *ngIf="!loading" class="table table-bordered table-hover">
				<thead>
				<th scope="col" class="table-start">Id</th>
				<th scope="col">Subcategoría</th>
				<th scope="col">Imagen</th>
				<th scope="col">Categoría</th>
				<th scope="col">Editar</th>
				<th scope="col" class="table-end">Eliminar</th>
				</thead>
				<tbody>
				<tr *ngFor="let subCategory of subCategories">
					<td>{{ subCategory.subCategoryId }}</td>
					<td>{{ subCategory.subCategoryName }}</td>
					<td>
						<img class="image-box" *ngIf="subCategory.subCategoryImageUrl" [src]="subCategory.subCategoryImageUrl" alt="subCategoryImageUrl">
						<span *ngIf="!subCategory.subCategoryImageUrl">Sin Imagen</span>
					</td>	
					<td>{{ subCategory.categoryName }}</td>
					<td>
					<button class="btn btn-primary primary-btn" (click)="openEditsubCategoryModal(subCategory.subCategoryId)">
						Editar
					</button>
					</td>
					<td>
					<button class="btn btn-primary remove-btn" (click)="modalDelete = true; subCategoryIdToDelete = subCategory.subCategoryId">
						Eliminar
					</button>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
  
	<app-confirmation-modal
	  *ngIf="modalDelete"
	  [title]="'¿Seguro que quiere eliminar esta subcategoría'"
	  [message]="'Al eliminar esta subcategoría se eliminarán los productos que esten relacionados a ella así como su sección en la pantalla de inicio y opción en el menú principal'"
	  [confirmText]="'Eliminar'"
	  [cancelText]="'Cancelar'"
	  (response)="deleteSubCategory($event)">
	</app-confirmation-modal>

	<section class="modal-section" *ngIf="showEditSubCategoryModal">
		<div class="modal-content">
		  <div class="modal-body"> 
			<app-edit-subcategory *ngIf="showEditSubCategoryModal" [categoryId]="selectedCategoryId" (subCategoryUpdated)="handleSubCategoryUpdated()" [id]="selectedSubCategoryId"></app-edit-subcategory>
			<div class="modal-btn">
			  <button class="btn btn-primary remove-btn" (click)="closeEditSubCategoryModal()">Cancelar</button>
			</div>
		  </div>
		</div>
	  </section>
  
	<div class="modal delete-category-confirm-modal" id="deleteSubCategoryModal" tabindex="-1">
	  <div class="modal-dialog category-modal-dialog" role="document">
		<div class="modal-content category-modal-content">
		  <div class="modal-body category-modal-body">
			<h3 class="text-center">¡Éxito!</h3>
			<p class="text-center">¡La subcategoría fue eliminada exitosamente!</p>
		  </div>
		  <div class="modal-buttons">
			<button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
		  </div>
		</div>
	  </div>
	</div>
</div>