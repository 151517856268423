import { Component, OnInit } from '@angular/core';
import { ThemingService, ShopTheming } from 'src/app/theming.service';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  loading = false;
  public shopTheme: ShopTheming[] = [];
  constructor(
    public apiService: ApiService,
    public themingService: ThemingService,
    private router: Router
  ) {
    this.shopTheme = this.themingService.shopTheming;
  }

  ngOnInit(): void {}
}
