import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { SettingsService } from 'src/app/settings.service';
import { ThemingService } from 'src/app/theming.service';
declare let $: any;

@Component({
  selector: 'app-sub-categories',
  templateUrl: './sub-categories.component.html',
  styleUrls: ['./sub-categories.component.scss']
})
export class SubCategoriesComponent implements OnInit {
  @Input() categoryId!: string;
  @Input() subCategoryId!: string;
  subCategories: any[] = [];
  loading: boolean = false;
  searchString: string = '';
  modalDelete = false;
  subCategoryIdToDelete: any;
  category: any;
  categories: any[] = [];
  subCategory: any;
  showCreateSubCategoryModal = false;
  showEditSubCategoryModal = false;
  selectedSubCategoryId!: string;
  selectedCategoryId!: string;

  constructor(
    private apiService: ApiService,
    public themingService: ThemingService,
    public router: Router,
    private settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.getSubCategoryByCategoryId(parseInt(this.categoryId));
  }

  getSubCategoryByCategoryId(categoryId: number) {
    this.loading = true;
    this.apiService
      .getSubCategoryByCategoryId(categoryId, this.themingService.shopId)
      .then((data: any) => {
        this.subCategories = data.subCategories;
        this.loading = false;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async deleteSubCategory(response: number) {
    if (response && this.subCategoryIdToDelete !== null) {
      this.loading = true;
      try {
        const subCategory = this.subCategories.find(subCat => subCat.subCategoryId === this.subCategoryIdToDelete);
        if (subCategory && subCategory.subCategoryImageUrl) {
          await this.deleteImage(subCategory.subCategoryImageUrl);
        }
        await this.apiService.deleteSubCategory(this.themingService.shopId, this.subCategoryIdToDelete);
        this.getSubCategoryByCategoryId(parseInt(this.categoryId));
        $('#deleteSubCategoryModal').modal('show');
      } catch (error) {
        console.error('Error deleting category:', error);
      } finally {
        this.loading = false;
        this.modalDelete = false;
      }
    } else {
      this.modalDelete = false;
    }
  }

  async deleteImage(imageUrl: string) {
    try {
      await this.apiService.deleteFile(imageUrl);
      console.log('Image deleted successfully');
    } catch (error) {
      console.log('Error deleting image:', error);
    }
  }
  getCategories() {
    this.apiService
      .getCategories(this.themingService.shopId)
      .then((data: any) => {
        this.categories = data.categories;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  openCreateSubCategoryModal() {
    this.showCreateSubCategoryModal = true;
  }

  closeCreateSubCategoryModal() {
    this.showCreateSubCategoryModal = false;
  }

  handleSubCategoryCreated() {
    this.showCreateSubCategoryModal = false;
    this.getSubCategoryByCategoryId(parseInt(this.categoryId));
  }

  openEditsubCategoryModal(subCategoryId: string) {
    this.selectedSubCategoryId = subCategoryId;
    this.selectedCategoryId = this.categoryId;
    this.showEditSubCategoryModal = true;
  }

  handleSubCategoryUpdated() {
    this.showEditSubCategoryModal = false;
    this.getSubCategoryByCategoryId(parseInt(this.categoryId));
  }

  closeEditSubCategoryModal() {
    this.showEditSubCategoryModal = false;
  }

  closeModal() {
    $('#deleteSubCategoryModal').modal('hide');
  }
}