import { Component, EventEmitter, OnInit, Output, } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';

declare var $: any;

@Component({
  selector: 'app-create-menu',
  templateUrl: './create-menu.component.html',
  styleUrls: ['./create-menu.component.scss']
})
export class CreateMenuComponent implements OnInit {
  @Output() creationMenuSuccess = new EventEmitter<void>();
  menu = {
    menuId: 0,
    menuName: '',
    menuStatus: 0
  };
  saveMenu = false;
  menus: any[] = [];
  categories: any[] = [];
  collections: any[] = [];
  subCategories: any[] = [];
  subSubCategories: any[] = [];
  selectedOption: string = '';
  loading = false;
  menuOptions: any[] = []; 

  constructor(
    private apiService: ApiService,
    public themingService: ThemingService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    Promise.all([
      this.getMenu(),
      this.getCategories(),
      this.getCollections(),
      this.getSubCategories(),
      this.getSubSubCategories()
    ]).then(() => {
      this.loading = false;
    }).catch((error) => {
      console.error(error);
      this.loading = false;
    });
  } 

  getMenu() { 
    this.apiService.getMenu(this.themingService.shopId).then((data: any) => {
      this.menus = data.menus;
    }).catch((error) => {
      console.error(error);
    });
  }

  async getCategories(): Promise<void> {
    try {
      const data: any = await this.apiService.getCategories(this.themingService.shopId);
      this.categories = data.categories;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getCollections(): Promise<void> {
    try {
      const data: any = await this.apiService.getCollections(this.themingService.shopId);
      this.collections = data.collections;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getSubCategories(): Promise<void> {
    try {
      const data: any = await this.apiService.getSubCategories(this.themingService.shopId);
      this.subCategories = data.subCategories;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getSubSubCategories(): Promise<void> {
    try {
      const data: any = await this.apiService.getSubSubCategories(this.themingService.shopId);
      this.subSubCategories = data.subSubCategories;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  ChangeMenuName(event: Event) {
    const element = event.target as HTMLInputElement;
    this.menu.menuName = element.value;
  }

  addMenuOption() {
    this.menuOptions.push({
      optionType: '',
      optionName: '', 
      optionId: 0,
      categoryId: 0,
      subCategoryId: 0,
      subSubCategoryId: 0,
      collectionId: 0,
      optionUrl: '',
      position: this.menuOptions.length + 1  
    });
  }

  removeMenuOption(index: number) {
    this.menuOptions.splice(index, 1);
    this.menuOptions.forEach((option, i) => {
      option.position = i + 1;
    });
  }

  onOptionChange(option: string, index: number) {
    // Reset the selections
    this.menuOptions[index].categoryId = 0;
    this.menuOptions[index].subCategoryId = 0;
    this.menuOptions[index].subSubCategoryId = 0;
    this.menuOptions[index].collectionId = 0;
    this.menuOptions[index].optionUrl = '';
    this.menuOptions[index].optionType = 0; // Reset option type to 0 (invalid state)
  
    switch (option) {
      case 'categories':
        this.menuOptions[index].optionType = 1;
        break;
      case 'subCategories':
        this.menuOptions[index].optionType = 2;
        break;
      case 'subSubcategories':
        this.menuOptions[index].optionType = 3;
        break;
      case 'collections':
        this.menuOptions[index].optionType = 4;
        break;
      case 'custom':
        this.menuOptions[index].optionType = 5;
        break;
    }
  }
  
  onCategoryChange(event: any, index: number) {
    const selectedCategory = this.categories.find(category => category.categoryId === +event.target.value);
    if (selectedCategory) {
      this.menuOptions[index].categoryId = selectedCategory.categoryId;
    }
  }

  onSubCategoryChange(event: any, index: number) {
    const selectedSubCategory = this.subCategories.find(subCategory => subCategory.subCategoryId === +event.target.value);
    if (selectedSubCategory) {
      this.menuOptions[index].subCategoryId = selectedSubCategory.subCategoryId;
    }
  }

  onSubSubCategoryChange(event: any, index: number) {
    const selectedSubSubCategory = this.subSubCategories.find(subSubCategory => subSubCategory.subSubCategoryId === +event.target.value);
    if (selectedSubSubCategory) {
      this.menuOptions[index].subSubCategoryId = selectedSubSubCategory.subSubCategoryId;
    }
  }

  onCollectionChange(event: any, index: number) {
    const selectedCollection = this.collections.find(collection => collection.collectionId === +event.target.value);
    if (selectedCollection) {
      this.menuOptions[index].collectionId = selectedCollection.collectionId;
    }
  }

  onUrlChange(event: any, index: number) {
    this.menuOptions[index].optionUrl = event.target.value; 
  }

  validateFormData() {
    if (!this.menu.menuName || this.menu.menuName.trim() === '') {
      $('#requiredMenuModal').modal('show');
      return;
    }
  
    for (let option of this.menuOptions) {
      if (!option.optionName || !option.optionType) {
        $('#requiredMenuModal').modal('show');
        return;
      }
  
      switch (option.optionType) {
        case 1: 
          if (!option.categoryId) {
            $('#requiredMenuModal').modal('show');
            return;
          }
          break;
        case 2: 
          if (!option.subCategoryId) {
            $('#requiredMenuModal').modal('show');
            return;
          }
          break;
        case 3: 
          if (!option.subSubCategoryId) {
            $('#requiredMenuModal').modal('show');
            return;
          }
          break;
        case 4: 
          if (!option.collectionId) {
            $('#requiredMenuModal').modal('show');
            return;
          }
          break;
        case 5: 
          if (!option.optionUrl) {
            $('#requiredMenuModal').modal('show');
            return;
          }
          break;
        default:
          $('#requiredMenuModal').modal('show');
          return;
      }
    }
  
    this.createMenu();
  }
  
  createMenu() {
    this.loading = true;
    this.apiService.createMenu(
      this.themingService.shopId,
      this.menu.menuId,
      this.menu.menuName,
      this.menu.menuStatus,
      this.menuOptions.map(option => ({
        menuId: this.menu.menuId,
        menuOptionId: option.optionId,
        menuOptionPosition: option.position,  
        menuOptionName: option.optionName,
        menuOptionUrl: option.optionUrl,
        categoryId: option.categoryId,
        subCategoryId: option.subCategoryId,
        subSubCategoryId: option.subSubCategoryId,
        collectionId: option.collectionId,
        menuOptionType: option.optionType
      }))
    ).then((data: any) => {
      if (data.statusCode === 200) {
        console.log("statusCode: ", data.statusCode)
        this.loading = false;
        this.resetForm();
        $('#successMenuModal').modal('show');
        $('#successMenuModal').on('hidden.bs.modal', () => {
          this.creationMenuSuccess.emit(); 
        });
      } else {
        $('#errorMenuModal').modal('show');
        this.loading = false;
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  resetForm() {
    this.menu = {
      menuId: 0,
      menuName: '',
      menuStatus: 0
    };
    this.menuOptions = [];
    this.saveMenu = false;
  }
  
  closeModal(): void {
    $('#successMenuModal').modal('hide');
    $('#errorMenuModal').modal('hide');
    $('#requiredMenuModal').modal('hide');
  }
}
