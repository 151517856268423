import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Debounce } from 'angular-debounce-throttle';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
import { ThemingService } from 'src/app/theming.service';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss'],
})
export class CouponsComponent implements OnInit {
  coupons: any[] = [];
  totalAmount: number = 0;
  searchString: string = '';

  pages: number[] = [1];
  totalPages: number = 1;
  currentPage: number = 1;
  maxNumberOfPages: number = 5; //Odd numbers only
  amount: number = 20;

  loading: boolean = false;

  constructor(
    public appComponent: AppComponent,
    private apiService: ApiService,
    public themingService: ThemingService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.currentPage = 1;
    this.getCoupons();
  }

  @Debounce(500)
  async getCoupons() {
    this.loading = true;

    await this.apiService
      .getCoupons(
        this.themingService.shopId,
        this.currentPage - 1,
        this.amount,
        this.searchString
      )
      .then((data: any) => {
        console.log(data);
        this.totalPages = data.pagination.lastPage + 1;
        this.formatPages();
        this.coupons = data.coupons.map((coupon: any) => {
          // coupon.productCreationDate = this.appComponent.formatDate(
          //   new Date(Date.parse(coupon.productCreationDate))
          // );
          return coupon;
        });
      });
    this.loading = false;
  }

  goToCoupon(couponId: string) {
    this.router.navigate(['coupons/', couponId]);
  }

  movePage(forward: boolean) {
    this.loading = true;
    this.currentPage = this.currentPage + (forward ? 1 : -1);
    this.getCoupons();
  }

  goToPage(page: number) {
    this.loading = true;
    this.currentPage = page;
    this.getCoupons();
  }

  async formatPages() {
    this.appComponent.goToTop();
    this.pages = [];
    let pagesLength = this.maxNumberOfPages;
    let startPage = 1;
    if (this.maxNumberOfPages >= this.totalPages) {
      pagesLength = this.totalPages;
    } else {
      startPage = this.currentPage - (this.maxNumberOfPages - 1) / 2;
      if (startPage < 1) {
        startPage = 1;
      }

      if (
        this.currentPage + (this.maxNumberOfPages - 1) / 2 >=
        this.totalPages
      ) {
        startPage = this.totalPages - this.maxNumberOfPages + 1;
      } else {
        pagesLength = pagesLength - 1;
      }

      if (startPage >= (this.maxNumberOfPages - 1) / 2) {
        startPage = startPage + 1;
        pagesLength = pagesLength - 1;
      }
    }
    this.pages = Array.from({ length: pagesLength }, (_, i) => i + startPage);
  }
}
