<div class="container">
  <div class=" container-inventory">
		<div class="header">
				<h1 class="title">Nuevo punto de venta</h1>
				<app-back-arrow-settings [callback]="setListingShopPoints"></app-back-arrow-settings>
		</div>
		<form #myForm="ngForm">

			<div class="form-card">
				<div class="row">
          <div class="col-12">
						<label class="form-label is-required" for="shopPointName">Nombre</label>
						<input type="text" class="form-control" id="shopPointName" placeholder="Nombre del punto de venta"
						(keyup)="changeShopPointName($event)" value="{{shopPoint.name}}" #shopPointName/>
					</div>
					<div class="col-10">
						<label class="form-label" for="shopPointPhone">Teléfono</label>
						<input type="text" class="form-control" id="shopPointPhone" placeholder="Teléfono del punto de venta"
						(keyup)="changeShopPointPhone($event)" value="{{shopPoint.phone}}" #shopPointPhone/>
					</div>
          <div class="col-2">
						<div><label class="form-label" for="shop_point_status">Estado</label></div>
						<div class="switch">
						<label class="switch-label">
							<input type="checkbox"id="shop_point_status" name="status" [(ngModel)]="isActive"/>
							<span class="slider round"></span>
						</label>
						</div>
						<div><span id="statusText" class="status-text">{{isActive ? "Activo" : "Inactivo"}}</span></div>
					</div>
					<div class="col-12">
						<label class="form-label" for="shopPointLocation">Ubicación</label>
						<textarea class="form-control" id="shopPointLocation" placeholder="Ubicación del punto de venta" (keyup)="ChangeShopPointLocation($event)" #shopPointLocation>{{shopPoint.location}}</textarea>
						<div class="form-buttons">
								<button type="button" class="btn btn-outline-secondary submit-btn" (click)="validateFormData()">
								Crear
								</button>
						</div>   
					</div>  
				</div>
			</div>
		</form>
   </div>
</div>

<div class="modal shopPoint-confirm-modal" id="shopPointLoadingModal" tabindex="-1">
	<div class="modal-dialog shopPoint-modal-dialog" role="document">
		<div class="modal-content shopPoint-modal-content">
			<div class="modal-body shopPoint-modal-body">
				<div class="loading">
					<div class="spinner-border" role="status"></div>
				</div>
			</div>
		</div>
	</div>
</div>
  
<div class="modal shopPoint-confirm-modal" id="successShopPointsModal" tabindex="-1">
	<div class="modal-dialog shopPoint-modal-dialog" role="document">
		<div class="modal-content shopPoint-modal-content">
			<div class="modal-body shopPoint-modal-body">
				<h3 class="text-center">¡Éxito!</h3>
				<p class="text-center">¡El punto de venta fue creado exitosamente!</p>
			</div>
			<div class="modal-buttons">
				<button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
			</div>
		</div>
	</div>
</div>
<div class="modal error-confirm-modal fade" id="requiredShopPointModal" tabindex="-1">
	<div class="modal-dialog error-modal-dialog" role="document">
		<div class="modal-content error-modal-content">
			<div class="modal-body error-modal-body">
				<h3 class="text-center">¡Error!</h3>
				<p class="text-center">Debe asignar un nombre al punto de venta</p>
			</div>
			<div class="modal-buttons">
				<button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
			</div>
		</div>
	</div>
</div>
<div class="modal shopPoint-confirm-modal fade" id="errorShopPointsModal" tabindex="-1">
	<div class="modal-dialog shopPoint-modal-dialog" role="document">
		<div class="modal-content shopPoint-modal-content">
			<div class="modal-body shopPoint-modal-body">
				<h3 class="text-center">¡Error!</h3>
				<p class="text-center">¡Hubo un error al guardar los cambios!</p>
			</div>
			<div class="modal-buttons">
				<button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
			</div>
		</div>
	</div>
</div>

