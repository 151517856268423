<div class="container">
  <div class="container-suppliers">
    <div class="header">
      <h1 class="title">Proveedores</h1>
      <button class="btn btn-outline-primary primary-btn" (click)="setCreatingSupplier()">Nuevo</button>
    </div>
    <div class="form-card">
      <div class="search-container visually-hidden">
        <input
          type="text"
          placeholder="Buscar"
          [(ngModel)]="searchString"
          (keyup)="searchSuppliers()"
        />
      </div>
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>  
      </div>
      <table *ngIf="!loading" class="table table-bordered table-hover">
        <thead>
          <th scope="col" class="table-start">Id</th>
          <th scope="col">Nombre</th>
          <th scope="col">Descripción</th>
          <th scope="col">Editar</th>
          <th scope="col" class="table-end">Eliminar</th>
        </thead>
        <tbody>
          <tr *ngFor="let supplier of suppliers">
            <td>{{supplier.supplierId}}</td>
            <td>
              <a [routerLink]="['/suppliers', supplier.supplierId]" routerLinkActive="active">{{ supplier.supplierName }}</a>
            </td>
            <td>{{supplier.supplierDescription}}</td>
            <td>
              <button
                class="btn btn-primary primary-btn"
                (click)="setEditingSupplier(supplier.supplierId)"
              >
                Editar
              </button>
            </td>
            <td>
              <button
                class="btn btn-primary remove-btn"
                (click)="modalDelete = true;  supplierIdToDelete = supplier.supplierId"
              >
                Eliminar
              </button>
            </td>
          </tr>  
        </tbody>
      </table>
    </div>
  </div>

  <app-confirmation-modal *ngIf="modalDelete" [title]="'¿Seguro que quiere eliminar este proveedor?'" [message]="'Al eliminar este proveedor se eliminarán los productos que estén relacionados a el'"
    [confirmText]="'Eliminar'" [cancelText]="'Cancelar'" (response)="deleteSupplier($event)">
  </app-confirmation-modal>

  <div class="modal delete-supplier-confirm-modal" id="deleteSupplierModal" tabindex="-1">
    <div class="modal-dialog supplier-modal-dialog" role="document">
      <div class="modal-content supplier-modal-content">
        <div class="modal-body supplier-modal-body">
          <h3 class="text-center">¡Éxito!</h3>
          <p class="text-center">¡El proveedor fue eliminado exitosamente!</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</div>
