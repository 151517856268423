<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>

<div class="container">
  <div class="container-order">
    <div class="header">
      <h1 class="title">Orden {{saleInfo.saleShortCode}}</h1>
      <app-back-arrow [route]="'/orders'"></app-back-arrow>
    </div>
    <div class="loading" *ngIf="loading">
      <div class="spinner-border" role="status"></div>
  </div>
    <div *ngIf="!loading" class="form-card-group">
      <div class="form-card info">
        <div class="info-container" *ngIf="!loading">
          <table class="sale-info-container">
            <tr>
              <th>Información de la Venta</th>
            </tr>
            <tr>
              <td>Número de Pedido: {{ saleInfo.saleShortCode }}</td>
            </tr>
            <tr>
              <td>Tipo de Pago: {{ saleInfo.paymentTypeName }}</td>
            </tr>
            <tr *ngIf="saleInfo.giftCardDiscount > 0">
              <td>Pago con Tarjeta de Regalo: {{ appComponent.formatMoney(saleInfo.giftCardDiscount) }}</td>
            </tr>
            <tr *ngFor="let giftCard of giftCardPurchase">
              <td>Saldo Disponible en la Tarjeta: {{ appComponent.formatMoney(giftCard.balance) }}</td>
            </tr>
            <tr *ngFor="let giftCard of giftCardPurchase">
              <td>Código de la Tarjeta: {{ giftCard.code }}</td>
            </tr>
            <tr>
              <td>
                Monto Total:
                {{ appComponent.formatMoney(saleInfo.saleEndPrice) }}
              </td>
            </tr>
            <tr>
              <td>
                  <ng-container *ngIf="giftCards.length === 1 && items.length === 0">
                   {{ formatDate(saleInfo.saleDate) }}
                  </ng-container>
                  <ng-container *ngIf="!(giftCards.length === 1 && items.length === 0)">
                      {{ saleInfo.saleDate }} 
                  </ng-container>
              </td>
          </tr>
            <tr>
              <td>Punto de venta: {{ saleInfo.shopPointName }}</td>
            </tr>
            <tr>
              <td>
                Entrega:
                {{
                saleInfo.expressShipping == 0
                ? "Retirar en tienda"
                : "Envío a domicilio"
                }}
              </td>
            </tr>
            <tr *ngIf="saleInfo.paymentTypeCode === 'SINPE' || saleInfo.paymentTypeCode === 'BankTransfer'">
              <td>
                <div class="voucher-container">
                  <div>Comprobante:</div>
                  <button data-bs-toggle="modal" data-bs-target="#voucherModal">
                    Ver
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="state-container">
                  <div>Estado:</div>
                  <select class="form-select input-form-select" id="shippingType" [ngModel]="currentSaleState"
                      (change)="saleStateChange($event)">
                      <option *ngFor="let saleStateId of getObjectKeys(saleStateName)" value="{{ saleStateId }}">
                          {{ saleStateName[saleStateId] }}
                      </option>
                  </select>
                  <button [hidden]="!saleStateChanged" (click)="changeSaleState()">
                      <i class="feather" data-feather="check" style="color: green"></i>
                  </button>
                  <button [hidden]="!saleStateChanged" (click)="cancelSaleStateChange()">
                      <i class="feather" data-feather="x" style="color: red"></i>
                  </button>
                  <div class="loading-sale-state" *ngIf="loadingSaleStateChange">
                      <div class="spinner-border" role="status"></div>
                  </div>
              </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div *ngIf="!loading" class="form-card info">
        <table class="client-info-container">
          <tr>
            <th>Información del cliente</th>
          </tr>
          <tr>
            <td>Nombre: {{ saleInfo.clientName }}</td>
          </tr>
          <tr>
            <td>Identificación: {{ saleInfo.clientId }}</td>
          </tr>
          <tr>
            <td>Correo electrónico: {{ saleInfo.clientEmail }}</td>
          </tr>
          <tr>
            <td>Teléfono: {{ saleInfo.clientPhone }}</td>
          </tr>
          <tr *ngIf="saleInfo.expressShipping != 0">
            <td>
              Dirección: {{saleInfo.exactDirection }}
            </td>
          </tr>
          <tr>
            <td *ngIf="saleInfo.expressShipping === 1" class="wazeLink">
              <a [href]="saleInfo.wazeLink" target="_blank">Waze</a>
              <button (click)="copyWazeLink()">
                <i class="feather" data-feather="copy"></i>
              </button>
            </td>                        
          </tr>
          <tr *ngIf="saleInfo.expressShipping != 0">
            <td>Notas: {{ saleInfo.notes }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div *ngIf="(!giftCards || giftCards.length === 0) && items && items.length > 0 && !loading"  class="form-card">
      <h3 class="subtitle">Artículos</h3>
      <table class="items-container table table-bordered">
          <thead>
              <th scope="col" class="table-start">Artículo</th>
              <th scope="col">Precio</th>
              <th scope="col">Cantidad</th>
              <th scope="col">Código</th>
              <th scope="col">Variantes</th>
              <th scope="col">Punto de Venta</th>
              <th scope="col" class="table-end">Imagen</th>
          </thead>
          <tbody>
              <tr *ngFor="let item of items">
                  <td>{{ item.productName }}</td>
                  <td>{{ appComponent.formatMoney(item.saleItemUnitCost) }}</td>
                  <td>{{ item.saleItemQuantity }}</td>
                  <td>{{ item.itemCode }}</td>
                  <td>
                      <table>
                          <tr *ngFor="let variant of item.variants">
                              <td>{{ variant.variantTypeName }}</td>
                              <td>{{ variant.variantValueName }}</td>
                          </tr>
                          <tr *ngIf="!item.variants">
                              <td>Sin Variantes</td>
                          </tr>
                      </table>
                  </td>
                  <td>{{ item.shopPointName }}</td>
                  <td>
                      <img *ngIf="item.imageURL" [src]="appComponent.randomCacheID(item.imageURL)" />
                      <span *ngIf="!item.imageURL">Sin Imagen</span>
                  </td>
              </tr>
          </tbody>
      </table>
      <div class="loading" *ngIf="loading">
          <div class="spinner-border" role="status"></div>
      </div>
  </div>
  
  <div *ngIf="!loading && giftCards && giftCards.length > 0 && (!items || items.length === 0)"  class="form-card">
      <h5 class="subtitle">Tarjetas de Regalo</h5>
      <table class="items-container table table-bordered">
          <thead>
              <th scope="col" class="table-start">Nombre</th>
              <th scope="col">Precio</th>
              <th scope="col">Cantidad</th>
              <th scope="col">Codigo</th>
              <th scope="col">Estatus</th>
              <th scope="col" class="table-end">Imagen</th>
          </thead>
          <tbody>
              <tr *ngFor="let giftCard of giftCards">
                  <td>{{ giftCard.name }}</td>
                  <td>{{ appComponent.formatMoney(giftCard.saleGiftCardUnitCost)}}</td>
                  <td>{{ giftCard.saleGiftCardQuantity }}</td>
                  <td>{{ giftCard.code }}</td>
                  <td>{{ giftCard.status === 0 ? 'Inactiva' : (giftCard.status === 1 ? 'Activa' : 'Pendiente') }}</td>
                  <td>
                      <img *ngIf="giftCard.images" [src]="appComponent.randomCacheID(giftCard.images)" />
                      <span *ngIf="!giftCard.images">Sin Imagen</span>
                  </td>
              </tr>
          </tbody> 
      </table>
  </div>
  
  <div *ngIf="giftCards && giftCards.length > 0 && items && items.length > 0" class="form-card">
      <h5 class="subtitle">Tarjetas de Regalo</h5>
      <table class="items-container table table-bordered">
          <thead>
              <th scope="col" class="table-start">Nombre</th>
              <th scope="col">Precio</th>
              <th scope="col">Cantidad</th>
              <th scope="col">Codigo</th>
              <th scope="col">Estatus</th>
              <th scope="col" class="table-end">Imagen</th>
          </thead>
          <tbody>
              <tr *ngFor="let giftCard of giftCards">
                  <td>{{ giftCard.name }}</td>
                  <td>{{ appComponent.formatMoney(giftCard.saleGiftCardUnitCost)}}</td>
                  <td>{{ giftCard.saleGiftCardQuantity }}</td>
                  <td>{{ giftCard.code }}</td>
                  <td>{{ giftCard.status === 0 ? 'Inactiva' : (giftCard.status === 1 ? 'Activa' : 'Pendiente') }}</td>
                  <td>
                      <img *ngIf="giftCard.images" [src]="appComponent.randomCacheID(giftCard.images)" />
                      <span *ngIf="!giftCard.images">Sin Imagen</span>
                  </td>
              </tr>
          </tbody> 
      </table>
  
      <h3 class="subtitle">Artículos</h3>
      <table class="items-container table table-bordered">
          <thead>
              <th scope="col" class="table-start">Artículo</th>
              <th scope="col">Precio</th>
              <th scope="col">Cantidad</th>
              <th scope="col">Código</th>
              <th scope="col">Variantes</th>
              <th scope="col">Punto de Venta</th>
              <th scope="col" class="table-end">Imagen</th>
          </thead>
          <tbody>
              <tr *ngFor="let item of items">
                  <td>{{ item.productName }}</td>
                  <td>{{ appComponent.formatMoney(item.saleItemUnitCost) }}</td>
                  <td>{{ item.saleItemQuantity }}</td>
                  <td>{{ item.itemCode }}</td>
                  <td>
                      <table>
                          <tr *ngFor="let variant of item.variants">
                              <td>{{ variant.variantTypeName }}</td>
                              <td>{{ variant.variantValueName }}</td>
                          </tr>
                          <tr *ngIf="!item.variants">
                              <td>Sin Variantes</td>
                          </tr>
                      </table>
                  </td>
                  <td>{{ item.shopPointName }}</td>
                  <td>
                      <img *ngIf="item.imageURL" [src]="appComponent.randomCacheID(item.imageURL)" />
                      <span *ngIf="!item.imageURL">Sin Imagen</span>
                  </td>
              </tr>
          </tbody>
      </table>
  </div>

  <div *ngIf="!loading" class="form-card">
  <h3 class="subtitle">Pagos</h3>
  <table class="items-container table table-bordered">
      <thead>
          <th scope="col" class="table-start">ID</th>
          <th scope="col">Monto</th>
          <th scope="col">Fecha de pago</th>
          <th scope="col" class="table-end">Monto total</th>
      </thead>
      <tbody>
          <tr *ngFor="let payment of salePayment">
            <td>{{ payment.salePaymentId }}</td>
            <td>{{ appComponent.formatMoney(payment.salePaymentAmount) }}</td>
            <td>{{ formatDate(payment.salePaymentDate) }}</td>
            <td>{{ appComponent.formatMoney(payment.saleTotalAmount) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
</div>
  <div class="modal" tabindex="-1" id="voucherModal" *ngIf="saleInfo.paymentTypeCode != 'CreditCard'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <!-- <h5 class="modal-title">Modal title</h5> -->
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <img [src]="saleInfo.saleVoucher" />
        </div>
        <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
      </div>
    </div>
  </div>
</div>
