import { Component, OnInit, Input } from '@angular/core';
import { SettingsService } from 'src/app/settings.service';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
declare var $: any;

@Component({
  selector: 'app-edit-supplier',
  templateUrl: './edit-supplier.component.html',
  styleUrls: ['./edit-supplier.component.scss']
})
export class EditSupplierComponent implements OnInit {

  supplier: any;
  suppliers: any =[];
  loading: boolean = true;

  @Input()
  supplierId!: string;

  constructor(
    private settingsService: SettingsService,
    private apiService: ApiService,
    private themingService: ThemingService
  ) { }

  ngOnInit(): void {
    this.getEditingSupplier();
    this.getSuppliersById(parseInt(this.supplierId),'');
  }

  getEditingSupplier(){
    this.supplierId = this.settingsService.getEditingSupplier();
  }

  setListingSuppliers(){
    this.settingsService.setListingSuppliers();
  }

getSuppliersById(id: number, filter: string) {
  this.loading = true;
  this.apiService
    .getSupplierById(id, this.themingService.shopId)
    .then((data: any) => {
      console.log(data);
      this.supplier = data.supplier;
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      setTimeout(() => {
        this.loading = false;
      }, 500); 
    });
}


  changeSupplierName(event: Event){
    const element = event.target as HTMLInputElement;
    this.supplier.supplierName = element.value;
  }
  
  ChangeSupplierDescription(event: Event){
    const element = event.target as HTMLInputElement;
    this.supplier.supplierDescription = element.value;
  }


  validateFormData(){
    this.loading = true;
    this.apiService.updateSupplier(
      parseInt(this.supplierId),
      this.supplier.supplierName,
      this.themingService.shopId,
      this.supplier.supplierDescription,
    )
    .then((data: any) => {
      console.log(data);
      if(data.status === 200){
        $('#successSupplierModal').modal('show');
        this.loading = false;
      }
      else{
        $('#errorSupplierModal').modal('show');
      }
    })
    .catch((error) => {
      console.error(error);
      this.loading = false;
    });
  
    }

  closeModal(){
    $('#successSupplierModal').modal('hide');
    $('#errorSupplierModal').modal('hide');
  }

}
