<div class="container">
    <div  class="container-inventory">

        <div class="header">
        <h1 class="title">Nueva colección</h1>
        <app-back-arrow-settings [callback]="setListingCollections"></app-back-arrow-settings>
        </div>
    <form #myForm="ngForm">
      <div class="form-card">
        <label class="form-label is-required" for="collection_name">Nombre</label>
        <input type="text" class="form-control" id="collectionName" placeholder="Nombre de la colección"
        (keyup)="ChangeCollectionName($event)" value="{{collection.name}}" #collectionName/>
        
        <label class="form-label" for="collection_description">Descripción</label>
        <textarea class="form-control" id="collectionDescription" placeholder="Descripción de la colección"
                  (keyup)="ChangeCollectionDescription($event)" #collectionDescription>{{collection.description}}</textarea>
        <div class="form-buttons">
            <button type="button" class="btn btn-outline-secondary submit-btn" (click)="validateFormData()">
              Crear
            </button>
          </div>   
      </div>  
    </form>
    </div>
</div>

<div class="modal collection-confirm-modal" id="collectionLoadingModal" tabindex="-1">
  <div class="modal-dialog collection-modal-dialog" role="document">
    <div class="modal-content collection-modal-content">
      <div class="modal-body collection-modal-body">
        <div class="loading">
          <div class="spinner-border" role="status"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal collection-confirm-modal" id="successCollectionsModal" tabindex="-1">
  <div class="modal-dialog collection-modal-dialog" role="document">
    <div class="modal-content collection-modal-content">
      <div class="modal-body collection-modal-body">
        <h3 class="text-center">¡Exito!</h3>
        <p class="text-center">¡La colección fue creada exitosamente!</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal error-confirm-modal fade" id="requiredCollectionModal" tabindex="-1">
  <div class="modal-dialog error-modal-dialog" role="document">
    <div class="modal-content error-modal-content">
      <div class="modal-body error-modal-body">
        <h3 class="text-center">¡Error!</h3>
        <p class="text-center">¡Debe asignar un nombre a la Colección</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal collection-confirm-modal fade" id="errorCollectionsModal" tabindex="-1">
  <div class="modal-dialog collection-modal-dialog" role="document">
    <div class="modal-content collection-modal-content">
      <div class="modal-body collection-modal-body">
        <h3 class="text-center">¡Error!</h3>
        <p class="text-center">¡Hubo un error al guardar los cambios!</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
      </div>
    </div>
  </div>
</div>
