<div class="container">
  <div class="container-collections">
    <div class="header">
        <h1 class="title">Colecciones</h1>
        <button class="btn btn-outline-primary primary-btn" (click)="setCreatingCollection()">Nueva</button>
    </div>
    <div class="form-card">
        <div class="loading" *ngIf="loading">
            <div class="spinner-border" role="status"></div>  
        </div>
          <table *ngIf="!loading" class="table table-bordered table-hover">
              <thead>
                <th scope="col" class="table-start">Id</th>
                <th scope="col">Nombre</th>
                <th scope="col">Descripción</th>
                <th scope="col">Editar</th>
                <th scope="col" class="table-end">Eliminar</th>
              </thead>
              <tbody>
                <tr *ngFor="let collection of collections">
                  <td>{{ collection.collectionId }}</td>
                  <td>
                    <a [routerLink]="['/collections', collection.collectionId]" routerLinkActive="active">{{ collection.collectionName }}</a>
                  </td>
                  <td>{{ collection.collectionDescription }}</td>
                  <td>
                    <button class="btn btn-primary primary-btn" (click)="setEditingCollection(collection.collectionId)">
                      Editar
                    </button>
                  </td>
                  <td>
                    <button class="btn btn-primary remove-btn" (click)="modalDelete = true; collectionIdToDelete = collection.collectionId">
                      Eliminar
                    </button>
                  </td>
                </tr>
              </tbody>
        </table>            
    </div>
</div>

  <app-confirmation-modal *ngIf="modalDelete" [title]="'¿Seguro que quiere eliminar esta colleción?'" [message]="'Al eliminar esta colección se eliminaran los productos que esten relacionados a ella'"
  [confirmText]="'Eliminar'" [cancelText]="'Cancelar'" (response)="deteleCollection($event)">
  > </app-confirmation-modal>

	<div class="modal delete-collection-confirm-modal" id="deleteCollectionModal" tabindex="-1">
		<div class="modal-dialog collection-modal-dialog" role="document">
			<div class="modal-content collection-modal-content">
				<div class="modal-body collection-modal-body">
					<h3 class="text-center">¡Exito!</h3>
					<p class="text-center">La colección fue eliminada exitosamente!</p>
				</div>
				<div class="modal-buttons">
					<button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
				</div>
			</div>
		</div>
	</div>
</div>
