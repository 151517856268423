import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop, transferArrayItem, moveItemInArray } from '@angular/cdk/drag-drop';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
declare let $: any;

@Component({
  selector: 'app-components',
  templateUrl: './components.component.html',
  styleUrls: ['./components.component.scss']
})

export class ComponentsComponent implements OnInit {
  @Output() editComponentEvent = new EventEmitter<number>();
  modalDelete: boolean = false;
  inactive: any[] = [];
  active: any[] = [];
  showSuccessAlert: boolean = false;
  showErrorAlert: boolean = false;
  loading: boolean = false; 
  component: any;
  componentToDeleteId: number | null = null;
  isCreatingComponent = false;
  isListingComponent = true;
  isEditingComponent = false;
  editComponentId!: number;

  constructor(
    private apiService: ApiService,
    private themingService: ThemingService
  ) { }

  ngOnInit() {
    this.getComponents();
  }

  editComponent(componentId: number): void {
    this.isEditingComponent = true;
    this.isCreatingComponent = false;
    this.isListingComponent = false;
    this.editComponentId = componentId;
  }

  toggleComponentVisibility(creating: boolean) {
    this.isCreatingComponent = creating;
    this.isListingComponent = !creating;
    if (!creating) {
      this.getComponents();
    }
  }

  cancelCreation() {
    this.isCreatingComponent = false;
    this.isListingComponent = true;
    this.getComponents();
  }

  cancelEdition(): void {
    this.isEditingComponent = false;
    this.isListingComponent = true;
    this.getComponents();
  }

  async getComponents() {
    this.loading = true;
    try {
      const response: any = await this.apiService.getComponents(this.themingService.shopId);
      this.component = response.data;
      this.inactive = this.component.filter((component: any) => component.status === 0);
      this.active = this.component.filter((component: any) => component.status === 1);
      this.active.sort((a, b) => a.position - b.position);
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  }
  

  drop(event: CdkDragDrop<any[]>, listType: 'inactive' | 'active') {
    const componentId = +(event.item.element.nativeElement.getAttribute('data-componentId') ?? '');
  
    if (event.previousContainer !== event.container) {
      const item = event.previousContainer.data[event.previousIndex];
  
      if (listType === 'active') {
        item.status = 1;
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
        );
        this.updatePositions();
      } else if (listType === 'inactive') {
        item.status = 0;
        item.position = 0; 
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
        );
        this.updatePositions(); 
      }
  
      this.updateStatusAndPositions();
    } else {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      if (listType === 'active') {
        this.updatePositions();
        this.updateStatusAndPositions();
      }
    }
  }
  

  updatePositions() {
    this.active.forEach((component, index) => {
      component.position = index + 1;
    });
  }

  updateStatusAndPositions() {
    const allComponents = [...this.inactive, ...this.active];
    const componentDataString = allComponents.map(component =>
      `${component.componentId},${component.position},${component.status}`
    ).join(';');

    this.apiService.updateComponentStatusAndPosition(
      componentDataString,
      this.themingService.shopId
    )
      .then((response: any) => {
        if (response.statusCode === 200) {
          this.showSuccessAlert = true;
        } else {
          this.showErrorAlert = true;
        }
        setTimeout(() => {
          this.showSuccessAlert = false;
          this.showErrorAlert = false;
        }, 1000);
      })
      .catch((error) => {
        console.error(error);
        this.showErrorAlert = true;

        setTimeout(() => {
          this.showErrorAlert = false;
        }, 1000);
      });
  }

  confirmDelete(componentId: number) {
    this.componentToDeleteId = componentId;
    this.modalDelete = true;
 }

  async deleteComponent(response: boolean) {
    if (response && this.componentToDeleteId !== null) {
        this.loading = true;
        try {
            const component: any = this.component.find((comp: any) => comp.componentId === this.componentToDeleteId);

            if (component) {
                if (component.imageUrl) {
                    await this.deleteImage(component.imageUrl);
                }
                if (component.infoImageUrl) {
                    await this.deleteImage(component.infoImageUrl);
                }
            }

            await this.apiService.deleteComponent(this.themingService.shopId, this.componentToDeleteId);
            this.getComponents();
            $('#deleteComponentModal').modal('show');
        } catch (error) {
            console.error('Error deleting component:', error);
        } finally {
            this.loading = false;
            this.modalDelete = false;
        }
    } else {
        this.modalDelete = false;
    }
  }

  async deleteImage(imageUrl: string) {
    try {
        await this.apiService.deleteFile(imageUrl);
        console.log('Image deleted successfully'); 
    } catch (error) {
        console.log('Error deleting image:', error);
    }
  }


  closeModal() {
    $('#deleteComponentModal').modal('hide');
  }
}
