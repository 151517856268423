import { Component, OnInit, Input } from '@angular/core';
import { SettingsService } from 'src/app/settings.service';

@Component({
  selector: 'app-back-arrow-settings',
  templateUrl: './back-arrow-settings.component.html',
  styleUrls: ['./back-arrow-settings.component.scss']
})
export class BackArrowSettingsComponent implements OnInit {
  @Input() callback!: () => void;
  constructor(private settingsService: SettingsService) {
    
  }

  ngOnInit(): void {
  }

  goToBackComponent() {
    if (this.callback) {
      this.callback();
    }
  }

  // /**
  //  * It calls the setListingUsers() function in the settings service
  //  */
  // setListingUsers(): void {
  //   this.settingsService.setListingUsers();
  // }

}
