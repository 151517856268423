<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>

<div class="container">
  <div *ngIf="giftCard" class="container-giftcards">
    <div class="header">
      <h1 class="title">Editar Tarjeta</h1>
      <app-back-arrow-settings [routerLink]="['/giftCardTypes']"></app-back-arrow-settings>
    </div>
    <form #myForm="ngForm">
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <div class="form-card" *ngIf="!loading">
        <div class="form-group-container">
          <div>
            <label class="form-label" for="giftcard_name">Nombre</label>
            <input type="text" class="form-control" id="giftCardName" placeholder="Nombre de la Tarjeta"
            (keyup)="ChangeGiftCardName($event)" [(ngModel)]="giftCard.name" name="giftCardName" />
          </div>
          <div>
          <label class="form-label" for="giftCard_validityMonths">Meses de vigencia</label>
            <select class="form-control" id="giftCardValidMonths"(change)="ChangeGiftCardValidMonths($event)"
              [(ngModel)]="giftCard.validMonths"name="giftCardValidMonths">
              <option value="0">Sin vencimiento</option>
              <option *ngFor="let month of monthsArray" [value]="month">{{ month }}</option>
            </select>
          </div>
          <div>
            <label class="form-label" for="giftCard_stock">Cantidad</label>
            <select class="form-control"id="giftCardStock"(change)="ChangeGiftCardStock($event)"
              [(ngModel)]="giftCard.stock"name="giftCardStock">
              <option value="999999">Ilimitada</option>
              <option *ngFor="let quantity of quantityArray" [value]="quantity">{{ quantity }}</option>
            </select>
          </div>
        </div>
        <div class="form-switch">
          <label class="form-label" for="giftCard_status">Estado</label>
          <div class="switch-title-container">
            <label class="form-check-label switch" for="flexCheckDefault">
              <input class="form-check-input" type="checkbox" id="flexCheckDefault" [checked]="giftCard.status === 1"
                (change)="ChangeGiftCardStatus($event)" />
              <span class="slider round"></span>
              <div class="switch-text">
                <span>{{ giftCard.status === 1 ? 'Activa' : 'Inactiva' }}</span>
              </div>
            </label>
          </div>
        </div>
        <div class="images-container">
          <div class="img-label">Imágenes:</div>
          <div cdkDropList cdkDropListOrientation="horizontal" class="image-list">
            <div class="image-box" cdkDragDisabled>
              <button class="add-img" (click)="triggerFileInput()">+</button>
              <input id="btn-upload" type="file" class="d-none" (change)="onFileSelected($event)">
            </div>
            <div class="image-box" *ngIf="showExistingImage && giftCard.images && giftCardImage.length === 0" cdkDrag>
              <img [src]="sanitizeImageURL(giftCard.images)" alt="Current Image">
              <button type="button" class="btn btn-danger delete-button" (click)="deleteImage(giftCard.images)">
                x
              </button>
            </div>
            <div class="image-box" *ngFor="let image of giftCardImage" cdkDrag>
              <img *ngIf="!image.selected" [src]="sanitizeImageURL(image.imageURL)" alt="New Image">
              <button type="button" class="btn btn-danger delete-button" (click)="deleteImage(image)">
                x
              </button>
            </div>
          </div>
        </div>
        <div>
        <div class="switch-container">
          <div class="input-container">
            <label class="form-label" for="giftCard_amount">Montos</label>
            <button type="button" class="btn btn-outline-secondary small-btn confirm-btn text-white" (click)="addAmount()">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus" _ngcontent-eos-c103=""><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
            </button>
          </div>
          <div class="value-container" *ngFor="let amountControl of amountFormArray.controls; let i = index">
            <input type="number" min="0" class="form-control" [formControl]="getFormControl(i)" placeholder="Monto {{ i + 1 }}"/>
            <div class="delete-btn-container">
              <button type="button" class="btn btn-outline-secondary delVariantBtn small-btn cancel-btn" (click)="removeAmount(i)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash" _ngcontent-hyp-c84=""><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
              </button>
            </div>
          </div>
        </div>
      </div>
          <div class="form-buttons">
            <button type="button" class="btn btn-outline-secondary submit-btn" (click)="validateFormData()">
              Guardar
            </button>
          </div> 
      </div>
    </form>
  </div>
  <div class="modal giftcard-confirm-modal fade" id="successGiftCardsModal" tabindex="-1">
    <div class="modal-dialog giftcard-modal-dialog" role="document">
      <div class="modal-content giftcard-modal-content">
        <div class="modal-body giftcard-modal-body">
          <h3 class="text-center">¡Éxito!</h3>
          <p class="text-center">¡La Tarjeta fue guardada exitosamente!</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal giftcard-confirm-modal fade" id="errorGiftCardsModal" tabindex="-1">
    <div class="modal-dialog giftcard-modal-dialog" role="document">
      <div class="modal-content giftcard-modal-content">
        <div class="modal-body giftcard-modal-body">
          <h3 class="text-center">¡Error!</h3>
          <p class="text-center">¡Hubo un error al guardar los cambios!</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button> 
        </div>
      </div>
    </div>
  </div>
</div>