<div class="container">
  <div class="container-inventory" [@inOutAnimation]>
    <div class="header">
      <h1>Nuevo Usuario</h1>
      <app-back-arrow-settings [callback]="setListingUsers"></app-back-arrow-settings>
    </div>
    <section>
      <div class="white-card" *ngIf="!loadModal; else confirmation" [@inOutAnimation]>
          <div class="alert-span-exeption">
            <span *ngIf="exceptionMessage != ''" [@inOutAnimation] class="error-message">{{exceptionMessage}}</span>
          </div>

          <form action="post" [formGroup]="signUpForm" (ngSubmit)="onSignUp()">
            <div class="flex-row">
              <div class="form-group">
                <label for="firstname-input">Nombre</label>
                <input type="text" name="firstname-input" class="textInput" id="firstname-input" formControlName="givenName" (focus)="exceptionMessage = ''">
                <div class="alert-span" *ngIf="signUpFormValidation['givenName'].touched && signUpFormValidation['givenName'].invalid">
                  <label class="error-message" *ngIf="signUpFormValidation['givenName'].errors && signUpFormValidation['givenName'].errors['required']">
                    Este campo es requerido
                  </label>
                  <label class="error-message" *ngIf="signUpFormValidation['givenName'].errors && (signUpFormValidation['givenName'].errors['pattern'] || signUpFormValidation['givenName'].errors['minlength'])">
                    Formato de nombre incorrecto
                  </label>
                </div>
              </div>
              <div class="form-group">
                <label for="lastname-input">Apellidos</label>
                <input type="text" name="lastname-input" class="textInput" id="lastname-input" formControlName="familyName" (focus)="exceptionMessage = ''">
                <div class="alert-span" *ngIf="signUpFormValidation['familyName'].touched && signUpFormValidation['familyName'].invalid">
                  <label class="error-message" *ngIf="signUpFormValidation['familyName'].errors && signUpFormValidation['familyName'].errors['required']">
                    Este campo es requerido
                  </label>
                  <label class="error-message" *ngIf="signUpFormValidation['familyName'].errors && (signUpFormValidation['familyName'].errors['pattern'] || signUpFormValidation['familyName'].errors['minlength'])">
                    Formato de nombre incorrecto
                  </label>
                </div>
              </div>
            </div>
            <div class="flex-row">
              <div class="form-group">
                <label for="email-input">Correo electrónico</label>
                <input type="text" name="email" class="textInput" id="email-input" formControlName="givenEmail" (focus)="exceptionMessage = ''">
                <div class="alert-span" *ngIf="signUpFormValidation['givenEmail'].touched && signUpFormValidation['givenEmail'].invalid">
                  <label class="error-message" *ngIf="signUpFormValidation['givenEmail'].errors && signUpFormValidation['givenEmail'].errors['required']" [@inOutAnimation]>
                    Este campo es requerido
                  </label>
                  <label class="error-message" *ngIf="signUpFormValidation['givenEmail'].errors && signUpFormValidation['givenEmail'].errors['pattern'] && signUpFormValidation['givenEmail'].errors['email']" [@inOutAnimation]>
                    Formato de correo incorrecto
                  </label>
                </div>
              </div>
              <div class="form-group">
                <label for="password-input">Contraseña temporal</label>
                <div class="password-input">
                  <input [type]="isPasswordVisible ? 'text':'password'" id="password-input" name="password" class="textInput" formControlName="givenPassword" (focus)="exceptionMessage = ''">
                  <i
                    class="password-icon"
                    [ngClass]="isPasswordVisible ? 'hide-password' : 'show-password'"
                    (click)="isPasswordVisible = !isPasswordVisible"
                  ></i>
                </div>
                <div class="alert-span" *ngIf="signUpFormValidation['givenPassword'].touched && signUpFormValidation['givenPassword'].invalid">
                  <label class="error-message" *ngIf="signUpFormValidation['givenPassword'].touched && signUpFormValidation['givenPassword'].errors && signUpFormValidation['givenPassword'].errors['required']" [@inOutAnimation]>
                    Este campo es requerido
                  </label>
                  <label class="error-message" *ngIf="signUpFormValidation['givenPassword'].errors && signUpFormValidation['givenPassword'].errors['minlength']">
                    La contraseña debe tener al menos 8 caracteres
                  </label>
                </div>
              </div>
            </div>
            <div class="permissions-container" *ngIf="permissionsFormGroup" [formGroup]="permissionsFormGroup">
              <h2>Permisos de acceso</h2>
              <div class="option-container">
                <div class="options-column">
                  <mat-slide-toggle formControlName="home" class="switch">Inicio</mat-slide-toggle>
                  <mat-slide-toggle formControlName="sales" class="switch">Ordenes</mat-slide-toggle>
                  <mat-slide-toggle formControlName="cashRegisters" class="switch">Cajas</mat-slide-toggle>
                  <mat-slide-toggle formControlName="products" class="switch">Productos</mat-slide-toggle>
                  <mat-slide-toggle formControlName="giftCards" class="switch">Tarjetas de regalo</mat-slide-toggle>
                </div>
                <div class="options-column">
                  <mat-slide-toggle formControlName="coupons" class="switch">Cupones</mat-slide-toggle>
                  <mat-slide-toggle formControlName="clients" class="switch">Clientes</mat-slide-toggle>
                  <mat-slide-toggle formControlName="posAccess" class="switch">Usuario POS</mat-slide-toggle>
                  <mat-slide-toggle formControlName="config" class="switch">Configuración</mat-slide-toggle>
                </div>
              </div>
              <h4 *ngIf="configAcces">Permisos de configuración</h4>
              <div class="option-container" *ngIf="configAcces">
                <div class="options-column">
                  <mat-slide-toggle formControlName="generalInfo" class="switch">Información general</mat-slide-toggle>
                  <mat-slide-toggle formControlName="userRoles" class="switch">Usuarios y roles</mat-slide-toggle>
                  <mat-slide-toggle formControlName="categories" class="switch">Categorías</mat-slide-toggle>
                  <mat-slide-toggle formControlName="collections" class="switch">Colecciones</mat-slide-toggle>
                </div>
                <div class="options-column">
                  <mat-slide-toggle formControlName="supliers" class="switch">Proveedores</mat-slide-toggle>
                  <mat-slide-toggle formControlName="shopPoints" class="switch">Puntos de venta</mat-slide-toggle>
                  <mat-slide-toggle formControlName="paymentMethods" class="switch">Métodos de pago</mat-slide-toggle>
                  <mat-slide-toggle formControlName="themingService" class="switch">Tema de la tienda</mat-slide-toggle>
                </div>
              </div>
            </div>
            
          <div class="form-buttons">
            <button class="create-btn" type="submit" [disabled]="signUpForm.invalid">Crear</button>
          </div>
        </form>
      </div>

      <ng-template #confirmation>
        <div class="white-card" [@inOutAnimation]>
          <div class="confirmation-modal">
            <h3>Usuario creado con éxito</h3>
            <img src="../../../assets/illustrations/account-created.png">
            <p>¿Desea crear otro usuario?</p>
            <div class="modal-buttons">
              <button class="submit-btn disable" (click)="onCreateAnotherUser()">Sí, agregar otro</button>
              <button class="submit-btn remove" (click)="setListingUsers()">No, ir a usuarios</button>
            </div>
          </div>
        </div>
      </ng-template>
    </section>
  </div>
</div>
