import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
declare var $: any;

@Component({
  selector: 'app-tax-settings',
  templateUrl: './tax-settings.component.html',
  styleUrls: ['./tax-settings.component.scss']
})
export class TaxSettingsComponent implements OnInit {

  loading = false;
  taxes: any[] = [];
  taxActive: any;
  taxId: number = 0;

  constructor(
    private apiService: ApiService,
    public themingService: ThemingService
  ) { 
  }

  ngOnInit(): void {
    this.loadTaxes();
    console.log("running")
  }

  loadTaxes() {
    this.loading = true;
    this.apiService
        .getTaxes(this.themingService.shopId)
        .then((data: any) => {
            console.log("DATA", data);
            this.taxes = data.filter((taxes: any) => taxes.taxValue === 0);
            const activeTaxes = data.filter((taxes: any) => taxes.taxActive === '1');
            const lastActiveTax = activeTaxes.pop(); 
            if (lastActiveTax) {
                this.taxId = lastActiveTax.taxId;
            }
            this.loading = false;
        })
        .catch((error) => {
            console.error(error);
            this.loading = false;
        });
  }

  changeTax(event: Event) {
    const element = event.target as HTMLInputElement;
    this.taxId = parseInt(element.value);
  }

  saveChanges(){
    this.loading = true;
    let taxId = 0;
    console.log("TaxID: ",this.taxId)
    this.apiService.updateTax(
      this.themingService.shopId, 
      this.taxId
    )
    .then((data: any) => {
      console.log(data);
      $('#successTaxModal').modal('show');
      this.loading = false;
    })
    .catch((error) => {
      console.error(error);
      this.loading = false;
    });
  }

  closeModal(){
    $('#successTaxModal').modal('hide');
  }

}
