<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>
<div class="container">
  <div class="container-client">
    <div class="header">
      <h1 class="title">Nuevo Cliente</h1>
      <app-back-arrow [route]="'/clients'"></app-back-arrow>
    </div>
    <div class="form-card">
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <form *ngIf="!loading" [formGroup]="clientForm" (ngSubmit)="createClient()" class="content row">
        <div class="title">
          <span>Información</span>
        </div>
        <div class="column col-md-4">
          <label class="form-label" for="firstName">Nombre</label>
          <input class="form-control" id="firstName" formControlName="firstName" type="text">
        </div>
        <div class="column col-md-4">
          <label class="form-label" for="lastName">Apellidos</label>
          <input class="form-control" id="lastName" formControlName="lastName" type="text">
        </div>
        <div class="column col-md-4">
          <label class="form-label" for="gender">Genero</label>
          <div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="gender" id="genderMale" formControlName="gender" value="male">
              <label class="form-check-label" for="genderMale">Hombre</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="gender" id="genderFemale" formControlName="gender" value="female">
              <label class="form-check-label" for="genderFemale">Mujer</label>
            </div>
          </div>
        </div>
        <div class="column col-md-4">
          <label class="form-label" for="email">Email</label>
          <input class="form-control" id="email" formControlName="email" type="email">
        </div>
        <div class="column col-md-4">
          <label class="form-label" for="phone">Telefono</label>
          <input class="form-control" id="phone" formControlName="phone" type="text">
        </div>
        <div class="column col-md-4">
          <label class="form-label" for="nationalId">Número de cédula</label>
          <input class="form-control" id="nationalId" formControlName="nationalId" type="text">
        </div>
        <div class="column col-md-6 pool-register">
          <label class="form-label" for="isPoolRegister">Habilitar compras en línea</label>
          <input class="form-check-input" id="isPoolRegister" formControlName="isPoolRegister" type="checkbox">
        </div>
        <div class="column col-md-6 ">
          <label class="form-label" for="password">Contraseña</label>
          <div class="pool-register">
            <input [type]="showPassword ? 'text' : 'password'" class="form-control" id="password" formControlName="password">
            <button type="button" class="btn btn-secondary" (click)="togglePasswordVisibility()">
              {{ showPassword ? 'Ocultar' : 'Mostrar' }}
            </button>
            <button type="button" class="btn btn-secondary" (click)="generatePassword()">Generar</button>
          </div>
        </div>
        <div class="create-btn">
          <button class="btn primary-btn" type="submit">Crear Cliente</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal client-confirm-modal" id="successClientModal" tabindex="-1">
    <div class="modal-dialog client-modal-dialog" role="document">
      <div class="modal-content client-modal-content">
        <div class="modal-body client-modal-body">
          <h3 class="text-center">¡Exito!</h3>
          <p class="text-center">¡El cliente fue registrado exitosamente!</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal error-confirm-modal fade" id="requiredClientModal" tabindex="-1">
    <div class="modal-dialog error-modal-dialog" role="document">
      <div class="modal-content error-modal-content">
        <div class="modal-body error-modal-body">
          <h3 class="text-center">¡Error!</h3>
          <p class="text-center">¡Todos los campos son requeridos</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal client-confirm-modal fade" id="errorClientModal" tabindex="-1">
    <div class="modal-dialog client-modal-dialog" role="document">
      <div class="modal-content client-modal-content">
        <div class="modal-body client-modal-body">
          <h3 class="text-center">¡Error!</h3>
          <p class="text-center">¡Hubo un error al guardar los cambios!</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
  