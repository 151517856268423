<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>

<div class="container">
  <div class="container-orders">

    <div class="header">
      <h1 class="title">Tus Cupones</h1>
      <button type="button" class="btn btn-outline-primary primary-btn" [routerLink]="['/coupons/create']">
        Crear nuevo cupón
      </button>
    </div>

    <div class="form-card">

      <div class="search-container">
        <input type="text" placeholder="Buscar" [(ngModel)]="searchString" (keyup)="getCoupons()" />
      </div>
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>

      <table *ngIf="!loading" class="table table-bordered table-hover">
        <thead>
          <th scope="col" class="table-start">Cupón</th>
          <th scope="col">Código</th>
          <th scope="col" class="table-end">Ver</th>
        </thead>
        <tbody>

          <tr *ngFor="let coupon of coupons">
            <td>{{ coupon.couponType }}</td>
            <td>{{ coupon.couponCode }}</td>
            <td>
              <button
                class="btn btn-primary primary-btn"
                (click)="goToCoupon(coupon.couponId)"
              >
                Ver
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>


    <div class="nav-container" *ngIf="!loading">
      <nav>
        <ul class="pagination">
          <li [ngClass]="{ 'page-item': true, disabled: currentPage == 1 }">
            <a class="page-link page-text-link" (click)="movePage(false)" tabindex="-1" unselectable="on">Anterior</a>
          </li>
          <li *ngIf="pages[0] >= (maxNumberOfPages - 1) / 2" class="page-item disabled">
            <a class="page-link page-number-link" unselectable="on" style="opacity: 1">...</a>
          </li>
          <li *ngFor="let page of pages" [ngClass]="{ 'page-item': true, active: currentPage == page }">
            <a class="page-link page-number-link" (click)="goToPage(page)" unselectable="on">{{ page }}</a>
          </li>
          <li *ngIf="
          currentPage + (maxNumberOfPages - 1) / 2 < totalPages &&
          totalPages > maxNumberOfPages
          " class="page-item disabled">
            <a class="page-link page-number-link" unselectable="on" style="opacity: 1">...</a>
          </li>
          <li [ngClass]="{
          'page-item': true,
          disabled: currentPage == totalPages
        }">
            <a class="page-link page-text-link" (click)="movePage(true)" tabindex="-1" unselectable="on">
              Siguiente
            </a>
          </li>
        </ul>
      </nav>
    </div>

  </div>
</div>
