import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { AuthService, alertsAnimation } from 'src/app/auth.service';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { UserPoolService } from 'src/app/userPool.service';
import { generatePassword, normalizeText } from 'src/app/helpers/util';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: [
    alertsAnimation,
    trigger('slideLeft', [
      state('void', style({ transform: 'translateX(0)' })),
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.5s ease-in-out'),
      ]),
    ]),
    trigger('fade', [
      state('void', style({ opacity: 0 })),
      transition(':enter, :leave', [animate('0s ease-in-out')]),
    ]),
  ],
  host: { '[@inOutAnimation]': '' },
})
export class RegisterComponent implements OnInit {
  exceptionMessage: String = '';
  isPasswordVisible: boolean = false;
  isConfirmPasswordVisible: boolean = false;
  estado: boolean = false;
  userLoggin: any;
  public signUpForm!: FormGroup;

  currentStep: number = 1;
  country: any;
  countries = [
    {
        countryId: 1,
        countryCode: "CR",
        countryName: "Costa Rica",
        countryPhoneExtension: "+506",
        countryTimeZone: "America/Costa_Rica",
        countryLanguage: "es-CR"
    }
  ];

  public changePasswordForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private authService: AuthService,
    private userPoolService: UserPoolService,
    private router: Router,
  ) {
    /* Creating a form group with two controls. */
    this.signUpForm = this.fb.group({
      givenEmail: ['', [Validators.required, Validators.email]],
      givenShopName: ['', [Validators.required]],
      givenCountry: ['', [Validators.required]],
    });
  }

  /**
   * If the user has previously checked the "Remember Me" checkbox, then the email field will be
   * pre-populated with the email address that was previously entered.
   */
  ngOnInit(): void {
    
  }

  /**
   * This function returns the controls of the signUpForm for validation in TypeScript.
   * @returns The `signUpFormValidation` getter method is returning the controls of the `signUpForm`
   * form group.
   */
  get signUpFormValidation() {
    return this.signUpForm.controls;
  }

  async getCountries(): Promise<any[]>{
    return this.countries;
  }

  /**
   * This function changes the selected country based on the user's input.
   * @param {Event} event - Event is a built-in interface in TypeScript that represents an event that
   * occurs in the DOM (Document Object Model). In this case, it is used as a parameter for the
   * changeCountry function to capture the event triggered by a user selecting an option from a
   * dropdown menu. The event contains information about the target
   */
  changeCountry(event: Event){
    const element = event.target as HTMLSelectElement;
    this.country = this.countries.find(item => item.countryId === parseInt(element.value));
  }

  /**
   * The function checks if the current step in a sign-up form is valid based on specific form
   * validation criteria.
   * @returns a boolean value indicating whether the current step in a sign-up form is valid or not.
   */
  isStepInvalid() {
    let stepValid = true;
    switch (this.currentStep){
      case 1:
        stepValid = this.signUpFormValidation['givenEmail'].errors ? true: false
        break;
      case 2:
        stepValid = this.signUpFormValidation['givenShopName'].errors ? true : false;
        break;
      case 3:
      stepValid = this.signUpFormValidation['givenCountry'].errors ? true : false;
        break;
      case 4:
      stepValid = false;
        break;
      default: stepValid = true;
        break;
    }
    return stepValid;
  }

  /**
   * The function increments the current step and toggles the estado variable if the current step is
   * not already at 4.
   * @param {Event} e - Event - this is an object that represents an event that has occurred, such as a
   * mouse click or a key press. It contains information about the event, such as the type of event,
   * the target element, and any data associated with the event. In this code, the event is being used
   * to
   */
  nextStep(e: Event) {
    e.preventDefault();
    if(this.currentStep !== 4){
      this.currentStep++;
      this.estado = !this.estado
    }
  }

  /**
   * This function decreases the current step and toggles the estado variable if the current step is
   * not 1.
   * @param {Event} e - The parameter "e" is an event object that is passed as an argument to the
   * function. It is used to prevent the default behavior of the event, which in this case is to
   * prevent the page from reloading when a form is submitted.
   */
  prevStep(e: Event) {
    e.preventDefault();
    if(this.currentStep !== 1){
      this.currentStep--;
      this.estado = !this.estado
    }
  }

  /**
   * It navigates to the recover-password component and passes the email value from the sign-in form as a
   * query parameter.
   */
  goToLogin() {
    this.router.navigate(['/login']);
  }

  /**
   * It takes the email and password from the form, sends it to the authService, and if the user is
   * authenticated, it navigates to the my-shops page.
   */
  onSignUp(e: Event) {
    e.preventDefault();
    this.currentStep = 5;
    this.userPoolService.registerPool(normalizeText(this.signUpForm.value.givenShopName, '-') , this.signUpForm.value.givenEmail, generatePassword());
  }
}
