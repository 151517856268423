<div class="container">
  <div class="container-inventory">
    <div class="header">
      <h1 class="title">Información General</h1>
    </div>

    <div class="loading" *ngIf="loading">
      <div class="spinner-border" role="status"></div>
    </div>

    <div class="form-card">
      <div class="label">
        <div class="label-group">
          <i class="feather" data-feather="info"></i>
          <strong>Información General</strong>
        </div>
        <div class="label-edit">
          <a class="edit-link" [ngClass]="{'visually-hidden': isEditingGeneral == true}" (click)="switchEditingGeneral()">
            <i class="feather" data-feather="edit"></i>
          </a>
          <a class="primary-btn edit-link" *ngIf="isEditingGeneral" (click)="updateShopInfo()">Guardar</a>
        </div>
      </div>
      <div class="loading" *ngIf="loadingInfo">
        <div class="spinner-border" role="status"></div>
      </div>
      <div class="info-container row" *ngIf="!loadingInfo">
        <div class="data-container col-md-4" *ngIf="!isEditingGeneral">
          <label class="data-label" for="">Nombre de la Tienda</label>
          <output class="data-output">{{ shopInfo.shopName }}</output>
        </div>
        <div class="editing data-container form-group col-md-4" *ngIf="isEditingGeneral">
          <label class="data-label" for="">Nombre de la Tienda</label>
          <input [(ngModel)]="shopInfo.shopName" class="form-control" type="text" value="{{ shopInfo.shopName }}" />
        </div>
        <div class="data-container col-md-4" *ngIf="!isEditingGeneral">
          <label class="data-label" for="">Correo Electrónico</label>
          <output class="data-output">{{ shopInfo.shopMail }}</output>
        </div>
        <div class="editing data-container form-group col-md-4" *ngIf="isEditingGeneral">
          <label class="data-label" for="">Correo Electrónico</label>
          <input [(ngModel)]="shopInfo.shopMail" class="form-control" type="text" value="{{ shopInfo.shopMail }}" />
        </div>
        <div class="data-container col-md-4" *ngIf="!isEditingGeneral">
          <label class="data-label" for="">Moneda</label>
          <output class="data-output">{{ currentCurrencyCode }}</output>
        </div>
        <div class="editing data-container form-group col-md-4" *ngIf="isEditingGeneral">
          <label class="data-label" for="">Moneda</label>
          <!-- <input class="form-control" type="text" value="{{ shopTheme[0].currencyCode }}" /> -->
          <select class="form-control" (change)="changeCurrency($event)">
            <option *ngFor="let currency of currencies" value="{{currency.currencyId}}" [selected]="shopInfo.currencyId == currency.currencyId">({{currency.currencyCode}}) - {{currency.currencyName}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="form-card">
      <div class="label">
        <div class="label-group">
          <i class="feather" data-feather="map-pin"></i>
          <strong>Ubicación</strong>
        </div>
        <div class="label-edit">
          <a class="edit-link" [ngClass]="{'visually-hidden': isEditingLocation == true}" (click)="switchEditingLocation()">
            <i class="feather" data-feather="edit"></i>
          </a>
          <a class="primary-btn edit-link" *ngIf="isEditingLocation" (click)="updateShopLocation()">Guardar</a>
        </div>
      </div>
      <div class="loading" *ngIf="loadingLocation">
        <div class="spinner-border" role="status"></div>
      </div>
      <div class="info-container row" *ngIf="!loadingLocation">

        <div class="data-container col-md-4" *ngIf="!isEditingLocation">
          <label class="data-label" for="">País</label>
          <select class="form-control" disabled>
            <option value="0"></option>
            <option *ngFor="let country of countries" value="{{country.countryId}}" [selected]="shopInfo.countryId == country.countryId">{{country.countryName}}</option>
          </select>
        </div>
        <div class="editing data-container form-group col-md-4" *ngIf="isEditingLocation">
          <label class="data-label" for="">País</label>
          <select class="form-control" (change)="changeCountry($event)">
            <option value="0">Seleccione una opción</option>
            <option *ngFor="let country of countries" value="{{country.countryId}}" [selected]="shopInfo.countryId == country.countryId">{{country.countryName}}</option>
          </select>
        </div>

        <div class="data-container col-md-4" *ngIf="!isEditingLocation">
          <label class="data-label" for="">Provincia</label>
          <select class="form-control" disabled>
            <option value="0"></option>
            <option *ngFor="let geoloc1 of geoLocation1" value="{{geoloc1.geolocation1Id}}" [selected]="shopInfo.shopGeoLocation1Id == geoloc1.geolocation1Id">{{geoloc1.geolocation1Name}}</option>
          </select>
        </div>
        <div class="editing data-container form-group col-md-4" *ngIf="isEditingLocation">
          <label class="data-label" for="">Provincia</label>
          <select class="form-control" (change)="changeGeoLocation1($event)">
            <option value="0">Seleccione una opción</option>
            <option *ngFor="let geoloc1 of geoLocation1" value="{{geoloc1.geolocation1Id}}" [selected]="shopInfo.shopGeoLocation1Id == geoloc1.geolocation1Id">{{geoloc1.geolocation1Name}}</option>
          </select>
        </div>

        <div class="data-container col-md-4" *ngIf="!isEditingLocation">
          <label class="data-label" for="">Cantón</label>
          <select class="form-control" disabled>
            <option value="0"></option>
            <option *ngFor="let geoloc2 of geoLocation2" value="{{geoloc2.geolocation2Id}}" [selected]="shopInfo.shopGeoLocation2Id == geoloc2.geolocation2Id">{{geoloc2.geolocation2Name}}</option>
          </select>
        </div>
        <div class="editing data-container form-group col-md-4" *ngIf="isEditingLocation">
          <label class="data-label" for="">Cantón</label>
          <select class="form-control" (change)="changeGeoLocation2($event)">
            <option value="0">Seleccione una opción</option>
            <option *ngFor="let geoloc2 of geoLocation2" value="{{geoloc2.geolocation2Id}}" [selected]="shopInfo.shopGeoLocation2Id == geoloc2.geolocation2Id">{{geoloc2.geolocation2Name}}</option>
          </select>
        </div>

        <div class="data-container col-md-4" *ngIf="!isEditingLocation">
          <label class="data-label" for="">Distrito</label>
          <select class="form-control" disabled>
            <option value="0"></option>
            <option *ngFor="let geoloc3 of geoLocation3" value="{{geoloc3.geolocation3Id}}" [selected]="shopInfo.shopGeoLocation3Id == geoloc3.geolocation3Id">{{geoloc3.geolocation3Name}}</option>
          </select>
        </div>
        <div class="editing data-container form-group col-md-4" *ngIf="isEditingLocation">
          <label class="data-label" for="">Distrito</label>
          <select class="form-control" (change)="changeGeoLocation3($event)">
            <option value="0">Seleccione una opción</option>
            <option *ngFor="let geoloc3 of geoLocation3" value="{{geoloc3.geolocation3Id}}" [selected]="shopInfo.shopGeoLocation3Id == geoloc3.geolocation3Id">{{geoloc3.geolocation3Name}}</option>
          </select>
        </div>

      </div>
    </div>
    <app-tax-settings></app-tax-settings>
    <div class="form-card">
      <div class="label">
        <div class="label-group">
          <i class="feather" data-feather="smartphone"></i>
          <strong>Redes Sociales</strong>
        </div>
        <div class="label-edit">
          <a class="edit-link" [ngClass]="{'visually-hidden': isEditingSocial == true}" (click)="switchEditingSocial()">
            <i class="feather" data-feather="edit"></i>
          </a>
          <a class="primary-btn edit-link" *ngIf="isEditingSocial" (click)="updateShopSocialMedia()">Guardar</a>
        </div>
      </div>
      <div class="loading" *ngIf="loadingSocial">
        <div class="spinner-border" role="status"></div>
      </div>
      <div class="info-container row" *ngIf="!loadingSocial">
        <div class="col-md-6" *ngFor="let socialNet of shopSocialMedia">
          <div class="data-container" *ngIf="!isEditingSocial">
            <label class="data-label" for="">{{socialNet.socialMediaName}}</label>
            <output class="data-output">{{socialNet.socialMediaURL}}</output>
          </div>
          <div class="editing data-container form-group" *ngIf="isEditingSocial">
            <label class="data-label" for="">{{socialNet.socialMediaName}}</label>
            <input [(ngModel)]="socialNet.socialMediaURL" class="form-control" type="text" value="{{socialNet.socialMediaURL}}" />
            <div *ngIf="socialNet.socialMediaName == 'Facebook'" id="emailHelp" class="form-text">Ej: https://www.facebook.com/mobydygLATAM</div>
            <div *ngIf="socialNet.socialMediaName == 'Instagram'" id="emailHelp" class="form-text">Ej: https://www.instagram.com/mobydyglatam</div>
            <div *ngIf="socialNet.socialMediaName == 'Whatsapp'" id="emailHelp" class="form-text">Ej: https://wa.me/50612345678
            </div>
            <div *ngIf="socialNet.socialMediaName == 'Tiktok'" id="emailHelp" class="form-text">Ej: https://www.tiktok.com/@user_name</div>
            <div *ngIf="socialNet.socialMediaName == 'Linkedin'" id="emailHelp" class="form-text">Ej: https://www.linkedin.com/company/mobydyg</div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-card">
      <app-terms-and-conditions></app-terms-and-conditions>
    </div>
    <div class="form-card">
      <app-privacy-policy></app-privacy-policy>
    </div>
  </div>
</div>

<div class="modal product-modal" id="productModal" tabindex="-1">
  <div class="modal-dialog product-modal-dialog" role="document">
    <div class="modal-content product-modal-content">
      <div class="modal-body product-modal-body">
        <div class="loading">
          <div class="spinner-border" role="status"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal shop-data-confirm-modal fade" id="successShopDataModal" tabindex="-1">
  <div class="modal-dialog shop-data-modal-dialog" role="document">
    <div class="modal-content shop-data-modal-content">
      <div class="modal-body shop-data-modal-body">
        <h3 class="text-center">¡Éxito!</h3>
        <p class="text-center">¡Cambios guardados exitosamente!</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
      </div>
    </div>
  </div>
</div>