import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { alertsAnimation, AuthService } from 'src/app/auth.service';
import { ThemingService, ShopTheming } from 'src/app/theming.service';
import { SettingsService } from 'src/app/settings.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  animations: [ alertsAnimation ],
})
export class UsersComponent implements OnInit {
  public shopTheme: ShopTheming[] = [];

  loaded: boolean = false;
  fullUserList: any = {};
  staffList: any = [];
  owner: any = {};
  staffMembers: number = 0;
  staffMaxMembers: number = 2;
  currentUser: any;
  isCurrentUserOwner: boolean = false;
  _settingsService: SettingsService;
  permissions: any = {};

  constructor(
    private authService: AuthService,
    private router: Router,
    private themingService: ThemingService,
    private settingsService: SettingsService
  ) {
      this.shopTheme = this.themingService.shopTheming;
      this._settingsService = settingsService;
  }

  ngOnInit(): void {
    this.authService.getUserPermissions().then((permissions) => {
      this.permissions = permissions;
    }).catch((error) => {
      console.error('Error al obtener permisos del usuario:', error);
    });
    this.authService.getUsers().then((usersData: any)=>{
      this.fullUserList = usersData;
    }).then(()=>{
      for (let i = 0; i < this.fullUserList.length; i++){
        if (this.fullUserList[i].user_role === 'Owner'){
            this.owner = this.fullUserList[i];
        }else {
            this.staffList.push(this.fullUserList[i]);
        }
      }
      this.staffMembers = this.staffList.length;
      this.authService.currentAuthUser().then((user: any)=>{
        this.currentUser = user;
        if(this.currentUser.username === this.owner.Username || this.permissions.userRoles === true){
          this.isCurrentUserOwner = true;
        }
      }).then(()=>{
        let currentPlan = this.shopTheme[0].currentContract;
        switch (currentPlan) {
          case 'Basic':
            this.staffMaxMembers = 2;
            break;
          case 'Advanced':
            this.staffMaxMembers = 5;
            break;
          case 'Corporate':
            this.staffMaxMembers = 15;
            break;
          default:
            this.staffMaxMembers = 2;
            break;
        }
      }).finally(()=>{
        this.loaded = true;
      });
    });
  }

  goToAdd(){
    this.router.navigate(['/add-user'], {state: {max: this.staffMaxMembers}});
  }

  goToOwnerProfile(){
    if(this.isCurrentUserOwner){
      this.router.navigate(['/profile']);
    }
  }

  routeUser(user: any){
    if(this.isCurrentUserOwner){
      this.router.navigate(['/users', user.Username]);
    }else if(this.currentUser.username === user.Username){
      this.router.navigate(['/profile']);
    }
  }

  setEditingOwner(){
    this._settingsService.setEditingOwner()
  }

  setEditingUser(username: string){
    this._settingsService.setEditingUser(username)
  }

  getCreatingUser(){
    this._settingsService.getCreatingUser()
  }

  setCreatingUser(){
    this._settingsService.setCreatingUser();
  }
}

