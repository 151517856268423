import { Debounce } from 'angular-debounce-throttle';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
import { AppComponent } from 'src/app/app.component';
import {Component, OnInit} from '@angular/core';
declare let $: any;

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  constructor(
    public appComponent: AppComponent,
    private apiService: ApiService,
    public themingService: ThemingService
  ) {}

  clients: any[] = [];
  totalAmount: number = 0;
  searchString: string = '';
  order: string = 'DESC';

  pages: number[] = [1];
  totalPages: number = 1;
  currentPage: number = 1;
  maxNumberOfPages: number = 5; //Odd numbers only
  amount: number = 20;

  loading: boolean = false;
  clientIdToDelete!: number;
  clientUsernameToDelete!: string;
  modalDelete = false;

  ngOnInit(): void {
    this.currentPage = 1;
    this.getClients();
  }

  @Debounce(500)
  serchClients() {
    this.currentPage = 1;
    this.getClients();
  }

  @Debounce(500)
  filterSearch() {
    this.currentPage = 1;
    this.getClients();
  }

  async getClients() {
    this.loading = true;
    await this.apiService
      .getClients(
        this.themingService.shopId,
        this.searchString,
        this.currentPage - 1,
        this.amount,
        this.order
      )
      .then((data: any) => {
        this.clients = data.data;
        this.totalPages = data.pagination[0].lastPage;
        this.formatPages()
      }).catch((error: Error)=>{
        console.log(error)
      })
    this.loading = false;
  }

  deleteClient(response: number) {
    if (response) {
      this.loading = true;
      this.apiService.deleteClient(this.themingService.shopId, this.clientIdToDelete, this.clientUsernameToDelete)
        .then((data) => {
          this.getClients();
          $('#deleteClientModal').modal('show');
          this.loading = false; 
        })
        .catch((error) => {
          console.error(error);
          this.loading = false; 
        });
    }
    this.modalDelete = false;
  }
  

  notVerified(){
    $('#errorClientsModal').modal('show');
  }
  closeModal(){
    $('#errorClientsModal').modal('hide');
    $('#deleteClientModal').modal('hide');
  }

  movePage(forward: boolean) {
    this.loading = true;
    this.currentPage = this.currentPage + (forward ? 1 : -1);
    this.getClients();
  }

  goToPage(page: number) {
    this.loading = true;
    this.currentPage = page;
    this.getClients();
  }

  async formatPages() {
    this.appComponent.goToTop();
    this.pages = [];
    let pagesLength = this.maxNumberOfPages;
    let startPage = 1;
    if (this.maxNumberOfPages >= this.totalPages) {
      pagesLength = this.totalPages;
    } else {
      startPage = this.currentPage - (this.maxNumberOfPages - 1) / 2;
      if (startPage < 1) {
        startPage = 1;
      }

      if (
        this.currentPage + (this.maxNumberOfPages - 1) / 2 >=
        this.totalPages
      ) {
        startPage = this.totalPages - this.maxNumberOfPages + 1;
      } else {
        pagesLength = pagesLength - 1;
      }

      if (startPage >= (this.maxNumberOfPages - 1) / 2) {
        startPage = startPage + 1;
        pagesLength = pagesLength - 1;
      }
    }
    this.pages = Array.from({ length: pagesLength }, (_, i) => i + startPage);
  }

}
