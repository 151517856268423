<div class="container" *ngIf="isLoaded" [@inOutAnimation]>
  <div class="container-inventory">
    <div class="header">
      <h1 class="title">Detalles de usuario</h1>
      <app-back-arrow-settings [callback]="setListingUsers"></app-back-arrow-settings>
    </div>
      <div class="white-card">
        <div class="staff-container">
          <div class="member-container">
            <div>
              <p class="user-name">{{user.given_name + " " + user.family_name}}</p>
              <p class="user-email">{{user.email}}</p>
            </div>
            <div class="role-container">
             <!--  <p>{{user.user_role}}</p> -->
            </div>
          </div>
          <div class="alert-span-exeption">
            <span *ngIf="exceptionMessage != ''" [@inOutAnimation] class="error-message">{{exceptionMessage}}</span>
          </div>
          <div class="user-state">
            <p>Acceso a la tienda:</p>
            <p class="state">{{user.Enabled? 'Activo': 'Inactivo'}}</p>
          </div>
          <div class="user-state">
            <p>Estado de usuario:</p>
            <p class="state">{{userStatus}}</p>
          </div>
          <hr>
          <div class="permissions-container">
            <h2>Permisos de acceso</h2>
            <div class="option-container">
              <div class="options-column">
                <mat-slide-toggle [(ngModel)]="user.permissions.home" class="switch">Inicio</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.sales" class="switch">Ordenes</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.cashRegisters" class="switch">Cajas</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.products" class="switch">Productos</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.giftCards" class="switch">Tarjetas de regalo</mat-slide-toggle>
              </div>
              <div class="options-column">
                <mat-slide-toggle [(ngModel)]="user.permissions.coupons" class="switch">Cupones</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.clients" class="switch">Clientes</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.posAccess" class="switch">Usuario POS</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.config" class="switch">Configuración</mat-slide-toggle>
              </div>
            </div>
            <h4 *ngIf="user.permissions.config">Permisos de configuración</h4>
            <div class="option-container" *ngIf="user.permissions.config">
              <div class="options-column">
                <mat-slide-toggle [(ngModel)]="user.permissions.generalInfo" class="switch">Información general</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.userRoles" class="switch">Usuarios y roles</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.categories" class="switch">Categorías</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.collections" class="switch">Colecciones</mat-slide-toggle>
              </div>
              <div class="options-column">
                <mat-slide-toggle [(ngModel)]="user.permissions.supliers" class="switch">Proveedores</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.shopPoints" class="switch">Puntos de venta</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.paymentMethods" class="switch">Métodos de pago</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.themingService" class="switch">Tema de la tienda</mat-slide-toggle>
              </div>
            </div>
          </div>
          <div class="form-buttons">
            <button type="button" class="btn primary-btn" (click)="updateUser()">Guardar permisos</button>
            <button class="disable" *ngIf="user.Enabled == true" (click)="modalDisable = true">Deshabilitar acceso</button>
            <button class="disable" *ngIf="user.Enabled == false" (click)="modalEnable = true">Habilitar acceso</button>
            <button class="remove" (click)="modalDelete = true">Remover a {{user.given_name.split(' ').slice(0, 1).join(' ')}}</button>
          </div>
        </div>
      </div>
  </div>   
</div>

<app-confirmation-modal *ngIf="modalDisable"
    [title]="'¿Estás seguro de que quieres deshabilitar el acceso a este usuario?'"
    [message]="'Si lo deshabilitas, el usuario no podrá acceder a la plataforma'"
    [cancelText]="'Cancelar'"
    [confirmText]="'Deshabilitar'"
    (response)="disableUserAccess($event)"
></app-confirmation-modal>

<app-confirmation-modal *ngIf="modalEnable"
    [title]="'¿Estás seguro de que quieres habilitar el acceso a este usuario?'"
    [message]="'Si lo habilitas, el usuario podrá acceder a la plataforma'"
    [cancelText]="'Cancelar'"
    [confirmText]="'Habilitar'"
    (response)="enableUserAccess($event)"
></app-confirmation-modal>

<app-confirmation-modal *ngIf="modalDelete"
    [title]="'¿Estás seguro de que quieres eliminar a este usuario?'"
    [message]="'Esta acción no se puede deshacer'"
    [cancelText]="'Cancelar'"
    [confirmText]="'Eliminar'"
    (response)="deleteUser($event)"
></app-confirmation-modal>

<app-confirmation-modal *ngIf="deleteFeedbackModal"
    [title]="'Usuario eliminado'"
    [message]="'El usuario ha sido eliminado con éxito'"
    [confirmText]="'Volver a usuarios'"
    [twoFieldModal]="false"
    (response)="setListingUsers()"
></app-confirmation-modal>

<app-confirmation-modal *ngIf="updatePermissions"
    [title]="'Permisos actualizados'"
    [message]="'Los permisos han sido actualizados con éxito'"
    [confirmText]="'Cerrar'"
    [twoFieldModal]="false"
    (response)="updatePermissions = false"
></app-confirmation-modal>




