import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  sales: any[] = [];
  totalAmount: number = 0;
  searchString: string = '';

  pages: number[] = [1];
  totalPages: number = 1;
  currentPage: number = 1;
  maxNumberOfPages: number = 5;
  amount: number = 20;

  loading: boolean = true;
  saleStateName: any;
  selectedPaymentType: string = 'Todos';
  selectedOrderState: string = 'Todos';
  showNoOrdersMessage: boolean = false;
  startDate: string = this.getDate(new Date('2000-01-01'));
  endDate: string = this.getDate(new Date());
  tempStartDate: Date | null = null;
  tempEndDate: Date | null = null;
  saleState: number = -1;
  paymentTypeId: string = '0';

  constructor(
    public appComponent: AppComponent,
    private apiService: ApiService,
    public themingService: ThemingService
  ) {}

  ngOnInit(): void {
    this.currentPage = 1;
    this.saleStateName = {
      0: 'Cancelado',
      1: 'Pendiente de verificación',
      2: 'Preparando orden',
      3: 'Entregado',
      4: 'Pendiente Compra Click',
      5: 'Completado',
      6: 'Parcial'
    };
    this.getSales();
    this.saleState = this.selectedOrderStateToId(this.selectedOrderState); 
  }

  // Date Filters
  onStartDateChange(startDate: Date): void {
    this.startDate = this.getDate(startDate);
    if (this.endDate !== null) {
      this.getSales();
    }
  }
  
  onEndDateChange(endDate: Date): void {
    this.endDate = this.getDate(endDate);
    if (this.startDate !== null) {
      this.getSales();
    }
  }

  // Payment Type Filter
  onPaymentTypeChange(): void {
    switch (this.selectedPaymentType) {
      case 'Tarjeta de Crédito':
        this.paymentTypeId = '1';
        break;
      case 'SINPE MOVIL':
        this.paymentTypeId = '2';
        break;
      case 'Transferencia Bancaria':
        this.paymentTypeId = '3';
        break;
      case 'Efectivo':
        this.paymentTypeId = '4';
        break;
        case 'Payvalida':
          this.paymentTypeId = '5';
          break;
        case 'Link de Pago': 
          this.paymentTypeId = '7';
          break;
      default:
        this.paymentTypeId = '0'; 
        break;
    }
    this.getSales();
  }

  // Order State Filter
  onOrderStateChange(): void {
    this.saleState = this.selectedOrderStateToId(this.selectedOrderState); 
    this.getSales();
  }
  
  selectedOrderStateToId(selectedState: string): number {
    switch (selectedState) {
      case 'Cancelado':
        return 0;
      case 'Pendiente de verificación':
        return 1;
      case 'Preparando orden':
        return 2;
      case 'Entregado':
        return 3;
      case 'Pendiente Compra Click':
        return 4;
      case 'Completado':
        return 5;
      case 'Parcial':
        return 6;
      default:
        return -1; 
    }
  }


  async getSales() {
    this.loading = true;
    await this.apiService
      .getSales(
        this.themingService.shopId,
        this.searchString,
        this.currentPage - 1,
        this.amount,
        this.startDate,
        this.endDate,
        this.paymentTypeId,
        this.saleState 
      )
      .then((data: any) => {
        this.totalPages = data.pagination[0].lastPage + 1;
        this.formatPages();
        this.sales = data.sales.map((sale: any) => {
          sale.saleShortCode = sale.saleOrderCode.substring(
            sale.saleOrderCode.length - 5
          );
          return sale;
        });
        this.loading = false;
        setTimeout(() => {
          this.showNoOrdersMessage = true;
        }, 500);
      })
      .catch((error) => {
        console.error('Error fetching sales:', error);
        this.loading = false;
      });
  }

  movePage(forward: boolean) {
    this.loading = true;
    this.currentPage = this.currentPage + (forward ? 1 : -1);
    this.getSales();
  }

  goToPage(page: number) {
    this.loading = true;
    this.currentPage = page;
    this.getSales();
  }

  async formatPages() {
    this.appComponent.goToTop();
    this.pages = [];
    let pagesLength = this.maxNumberOfPages;
    let startPage = 1;
    if (this.maxNumberOfPages >= this.totalPages) {
      pagesLength = this.totalPages;
    } else {
      startPage = this.currentPage - (this.maxNumberOfPages - 1) / 2;
      if (startPage < 1) {
        startPage = 1;
      }

      if (
        this.currentPage + (this.maxNumberOfPages - 1) / 2 >=
        this.totalPages
      ) {
        startPage = this.totalPages - this.maxNumberOfPages + 1;
      } else {
        pagesLength = pagesLength - 1;
      }

      if (startPage >= (this.maxNumberOfPages - 1) / 2) {
        startPage = startPage + 1;
        pagesLength = pagesLength - 1;
      }
    }
    this.pages = Array.from({ length: pagesLength }, (_, i) => i + startPage);
  }
  
  // Date Filter Reset
  @ViewChild('startDateInput')
  startDateInput!: ElementRef;
  @ViewChild('endDateInput')
  endDateInput!: ElementRef;

  resetDateFilters(): void {
    this.startDateInput.nativeElement.value = null;
    this.endDateInput.nativeElement.value = null;
    this.startDate = this.getDate(new Date('2000-02-01'));
    this.endDate = this.getDate(new Date());
    this.getSales();
  }
  
  formatDate(dateString: any) {
    return this.appComponent.formatDate(dateString);
  }

  getDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

}