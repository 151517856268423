import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
import { SettingsService } from 'src/app/settings.service';
import { ThemingService } from 'src/app/theming.service';

@Component({
  selector: 'app-cash-registers',
  templateUrl: './cash-registers.component.html',
  styleUrls: ['./cash-registers.component.scss']
})
export class CashRegistersComponent implements OnInit {
  @Input() cashRegisterId!: number;
  cashRegisters: any[] = [];
  loading: boolean = false;

  constructor(
    public settingsService: SettingsService,
    private apiService: ApiService,
    private themingService: ThemingService,
    public appComponent: AppComponent,
  ) { }

  ngOnInit(): void {
    this.getCashRegisters();
  }

  async getCashRegisters() {
    this.loading = true;
    try {
      const response: any = await 
      this.apiService
        .getCashRegisters(this.themingService.shopId);
        this.cashRegisters = response.data;
        console.log(response.data);
        this.loading = false;
      } catch (error) {
        console.error(error);
    }
  }
}
