import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
import { AppComponent } from 'src/app/app.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-gift-cards-item',
  templateUrl: './gift-cards-item.component.html',
  styleUrls: ['./gift-cards-item.component.scss']
})
export class GiftCardsItemComponent implements OnInit {

  @Input() giftCardId!: string;  // Recibe el ID de la tarjeta de regalo del componente padre
  giftCardDetails: any;
  giftCard: any = {}; 
  giftCardTypeId: number = 0;
  giftCards: any[] = [];
  loading: boolean = false;

  constructor(
    private apiService: ApiService,
    public appComponent: AppComponent,
    private themingService: ThemingService,
  ) { }
  
  formatDate(date: string | Date): string {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'yyyy-MM-dd HH:mm') || '';
  }

  parseAmount(amount: string): number {
    return parseFloat(amount);
  }
  
  ngOnInit(): void {
    // Utiliza el ID pasado a través del @Input() para obtener los detalles de la tarjeta de regalo
    if (this.giftCardId) {
      this.getGiftCardItemsById(this.giftCardId);
    }
  }
  
  async getGiftCardItemsById(giftCardId: string): Promise<void> {
    this.loading = true;
    try {
      const data: unknown = await this.apiService.getGiftCardItemsById(parseInt(giftCardId), this.themingService.shopId);
      if (Array.isArray(data)) {
        this.giftCard = data[0];
        console.log(this.giftCard);
      } else {
        console.error('Error al obtener los detalles de la tarjeta de regalo');
      }
    } catch (error) {
      console.error('Error en la llamada a la API', error);
    } finally {
      this.loading = false;
    }
  }
}
