import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
import { SettingsService } from 'src/app/settings.service';
declare let $: any;

@Component({
  selector: 'app-gift-cards',
  templateUrl: './gift-cards.component.html',
  styleUrls: ['./gift-cards.component.scss']
})
export class GiftCardsComponent implements OnInit {

  giftCards: any[] = [];
  modalDelete = false;
  loading: boolean = false;
  giftCardIdToDelete: any;
  activeTab = 'search';

  search(activeTab: string){
    this.activeTab = activeTab;
  }

  result(activeTab: string){
    this.activeTab = activeTab;
  }
  constructor(
    private settingsService: SettingsService,
    private apiService: ApiService,
    public themingService: ThemingService,
  ) { }

  ngOnInit(): void {
    this.getGiftCardTypes();
  }

  async getGiftCardTypes() {
    this.loading = true;
    await this.apiService
      .getGiftCardTypes(
        this.themingService.shopId)
      .then((data: any) => {
        console.log(data);
        this.giftCards = data;
      });
    this.loading = false;
  }

  setCreatingGiftCardType(){
    this.settingsService.setCreatingGiftCardType();
  }

  setEditingGiftCardType(giftCardTypeId: string){
    this.settingsService.setEditingGiftCardType(giftCardTypeId);
  }

  deleteGiftCardType(response: number) {
    if (response) {
        this.loading = true;
        this.apiService.deleteGiftCardType(this.themingService.shopId, this.giftCardIdToDelete)
            .then((data) => {
                console.log("data: ", JSON.stringify(data));
                this.getGiftCardTypes();
                $('#deleteGiftCardModal').modal('show');
                this.loading = false; 
            })
            .catch((error) => {
                console.error("Error deleting gift card type:", error);
                this.loading = false; 
            });
        this.modalDelete = false; 
    } else {
        this.modalDelete = false;
        this.loading = false; 
    }
  }

  closeModal(){
    $('#deleteGiftCardModal').modal('hide');
  }

}
