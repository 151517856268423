import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemingService } from 'src/app/theming.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-create-coupon',
  templateUrl: './create-coupon.component.html',
  styleUrls: ['./create-coupon.component.scss'],
})
export class CreateCouponComponent implements OnInit {

  isByPercentage = false;
  isEditing = false;
  loading: boolean = false; 

  limitUses = true;
  limitByCost = true;
  limitByArticlesQuantity = true;

  inputsDisabled = false;

  coupon = {
    couponId: 0,
    couponSaleId: <any>0,
    couponProductId: <any>0,
    couponCategoryId: <any>null,
    couponCategoryName: <any>null,
    couponSubCategoryId: <any>null,
    couponSubCategoryName: <any>null,
    couponCode: <any>null,
    couponType: <any>null,
    couponByPercentage: 0,
    couponByPrice: 0,
    couponPriceValue: <any>null,
    couponPercentageValue: <any>null,
    couponMinArticlesQuantity: <any>null,
    couponMaxUses: <any>null,
    couponDateStart: <any>null,
    couponDateEnd: <any>null,
    couponMinCost: <any>null,
    couponMaxCost: 1000000,
    couponActive: 1,
  };

  saveCoupon = false;
  modifyCoupon = false;

  categories: any[] = [];
  subCategories: any[] = [];

  public couponForm!: FormGroup;

  constructor(
    public themingService: ThemingService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
  ) {
    this.couponForm = this.fb.group({
      "couponCode": ["", [
        Validators.required,
        Validators.pattern("^[A-Z0-9]*$"),
      ]],
      "couponName": ["", [
        Validators.required,
        Validators.pattern("^[ A-Za-zÀ-ÖØ-öø-ÿ0-9]*$")
      ]],
      "couponMaxValue": ["", [
        Validators.min(0),
      ]],
      "couponPercentageValue": ["", [
        Validators.required,
        Validators.min(1),
        Validators.max(100),
      ]],
      "couponPriceValue": ["", [
        Validators.required,
        Validators.min(1),
      ]],
      "couponMinPriceValue": ["", [
        Validators.required,
        Validators.min(0),
      ]],
      "couponMinArticlesQuantity": ["", [
        Validators.required,
        Validators.min(1),
      ]],
      "couponMaxUses": ["", [
        Validators.required,
        Validators.min(1),
      ]],
    });

    this.couponForm.controls['couponPriceValue'].disable();
    this.couponForm.controls['couponMinPriceValue'].disable();
    this.couponForm.controls['couponMinArticlesQuantity'].disable();
    this.couponForm.controls['couponMaxUses'].disable();
  }

  get couponFormValidation() {
    return this.couponForm.controls;
  }

  ngOnInit(): void {

    this.getCategories();

    if (<any>this.route.snapshot.paramMap.get('couponId') === null) {
      this.cleanForm();
    } else {
      this.coupon.couponId = parseInt(
        <any>this.route.snapshot.paramMap.get('couponId')
      );
    }

    this.disabledInputs();
    this.init();
  }

  async init() {
    await this.getCouponById();
    $(function () {
      $('[data-toggle="popover"]').popover();
    });
  }

  //Change coupon object values
  changeObjectValue(event: Event, filter: string) {
    const element = event.target as HTMLInputElement;

    if (filter === 'code') {
      this.coupon.couponCode = element.value;
    } else if (filter === 'type') {
      this.coupon.couponType = element.value;
    } else if (filter === 'price') {
      this.coupon.couponPriceValue = parseInt(element.value);
      this.coupon.couponMaxCost = parseInt(element.value);
    } else if (filter === 'minCost') {
      this.coupon.couponMinCost = parseInt(element.value);
    } else if (filter === 'maxDiscount') {
      this.coupon.couponMaxCost = parseInt(element.value);
    } else if (filter === 'articles') {
      this.coupon.couponMinArticlesQuantity = parseInt(element.value);
    } else if (filter === 'maxUses') {
      this.coupon.couponMaxUses = parseInt(element.value);
    } else if(filter === 'percentage') {
      this.coupon.couponPercentageValue = parseInt(element.value);
    }
  }

  changeCheckboxState(event: Event, filter: string) {
    const element = event.target as HTMLInputElement;

    if (filter === 'percentage' && element.checked) {

        this.coupon.couponByPercentage = 1;
        this.isByPercentage = true;
        this.coupon.couponPercentageValue = null;

        this.coupon.couponByPrice = 0;
        this.coupon.couponPriceValue = null;

        this.couponForm.controls['couponPercentageValue'].markAsUntouched();
        this.couponForm.controls['couponPercentageValue'].enable();

        this.couponForm.controls['couponPriceValue'].disable();
        this.couponForm.controls['couponPriceValue'].markAsUntouched();

        this.coupon.couponMaxCost = 1000000;
        this.couponForm.controls['couponMaxValue'].markAsUntouched();
        this.couponForm.controls['couponMaxValue'].enable();

    } else if (filter === 'price' && element.checked) {

        this.coupon.couponByPrice = 1;
        this.isByPercentage = false;
        this.coupon.couponPriceValue = null;

        this.coupon.couponByPercentage = 0;
        this.coupon.couponPercentageValue = null;

        this.couponForm.controls['couponPriceValue'].markAsUntouched();
        this.couponForm.controls['couponPriceValue'].enable();

        this.couponForm.controls['couponPercentageValue'].disable();
        this.couponForm.controls['couponPercentageValue'].markAsUntouched();

        this.coupon.couponMaxCost = 1000000;
        this.couponForm.controls['couponMaxValue'].disable();
        this.couponForm.controls['couponMaxValue'].markAsUntouched();


    } else if (filter === 'uses') {

      if (element.checked) {
        this.limitUses = false;
        this.couponForm.controls['couponMaxUses'].enable();
        this.couponForm.controls['couponMaxUses'].markAsUntouched();
      } else {
        this.limitUses = true;
        this.coupon.couponMaxUses = null;
        this.couponForm.controls['couponMaxUses'].disable();
        this.couponForm.controls['couponMaxUses'].markAsUntouched();
      }

    } else if (filter === 'limitByCost') {

      if (element.checked) {

        this.limitByCost = false;
        this.coupon.couponMinCost = null;
        this.couponForm.controls['couponMinPriceValue'].enable();
        this.couponForm.controls['couponMinPriceValue'].markAsUntouched();

        this.limitByArticlesQuantity = true;
        this.coupon.couponMinArticlesQuantity = null;
        this.couponForm.controls['couponMinArticlesQuantity'].disable();
        this.couponForm.controls['couponMinArticlesQuantity'].markAsUntouched();

      } else {

        this.limitByCost = true;
        this.coupon.couponMinCost = null;
        this.couponForm.controls['couponMinPriceValue'].disable();
        this.couponForm.controls['couponMinPriceValue'].markAsUntouched();

      }

    } else if (filter === 'limitByArticlesQuantity') {

      if (element.checked) {
        this.limitByArticlesQuantity = false;
        this.coupon.couponMinArticlesQuantity = null;
        this.couponForm.controls['couponMinArticlesQuantity'].enable();
        this.couponForm.controls['couponMinArticlesQuantity'].markAsUntouched();

        this.limitByCost = true;
        this.coupon.couponMinCost = null;
        this.couponForm.controls['couponMinPriceValue'].disable();
        this.couponForm.controls['couponMinPriceValue'].markAsUntouched();


      } else {

        this.limitByArticlesQuantity = true;
        this.coupon.couponMinArticlesQuantity = null;
        this.couponForm.controls['couponMinArticlesQuantity'].disable();
        this.couponForm.controls['couponMinArticlesQuantity'].markAsUntouched();

      }

    } else {
      if (element.checked === true) {
        this.coupon.couponActive = 1;
      } else {
        this.coupon.couponActive = 0;
      }
    }
  }

  changeCouponDates(event: Event, filter: number) {
    const element = event.target as HTMLInputElement;

    if (filter === 1) {
      this.coupon.couponDateStart = element.value;
      console.log(this.coupon.couponDateStart);
    } else {
      this.coupon.couponDateEnd = element.value;
    }
  }

  changeCategoryId(event: Event, filter: number) {
    const element = event.target as HTMLSelectElement;
    if (filter === 1) {
      this.coupon.couponCategoryId = parseInt(element.value);
      this.subCategories = [];
      this.getSubCategories();
    } else {
      this.coupon.couponSubCategoryId = parseInt(element.value);
    }
  }

  // Validate data coming from the form
  validateFormData() {

    if(this.couponForm.valid) {
      if (this.coupon.couponPriceValue === null) {
        this.coupon.couponPriceValue = 0;
      }

      if (this.coupon.couponPercentageValue === null) {
        this.coupon.couponPercentageValue = 0;
      }

      if (this.coupon.couponMaxCost === null) {
        this.coupon.couponMaxCost = 0;
      }

      if (
        this.coupon.couponMinCost === null ||
        this.coupon.couponMinCost === undefined
      ) {
        this.coupon.couponMinCost = 0;
      }

      if (
        this.coupon.couponMinArticlesQuantity === null ||
        this.coupon.couponMinArticlesQuantity === undefined
      ) {
        this.coupon.couponMinArticlesQuantity = 0;
      }

      if (
        this.coupon.couponMaxUses === null ||
        this.coupon.couponMaxUses === undefined
      ) {
        this.coupon.couponMaxUses = null;
      }

      if (this.coupon.couponId !== 0) {
        this.updateCoupon();
      } else {
        this.createCoupon();
      }
    } else {
      this.couponForm.markAllAsTouched();
    }
  }

  // Disabled or enabled form inputs
  disabledInputs() {
    if (this.coupon.couponId !== 0) {
      this.inputsDisabled = true;
      this.couponForm.controls['couponCode'].disable();
      this.couponForm.controls['couponName'].disable();
      this.couponForm.controls['couponMaxValue'].disable();
      this.couponForm.controls['couponPercentageValue'].disable();
      this.couponForm.controls['couponPriceValue'].disable();
    }
  }

  enabledInputs() {
    this.loading = true;
    this.inputsDisabled = false;

    this.couponForm.controls['couponCode'].enable();
    this.couponForm.controls['couponCode'].markAsUntouched();

    this.couponForm.controls['couponName'].enable();
    this.couponForm.controls['couponName'].markAsUntouched();

    if(this.coupon.couponPercentageValue !== null && this.coupon.couponPercentageValue !== undefined && this.coupon.couponPercentageValue > 0) {
      this.couponForm.controls['couponPercentageValue'].enable();
      this.couponForm.controls['couponPercentageValue'].markAsUntouched();

      this.couponForm.controls['couponMaxValue'].enable();
      this.couponForm.controls['couponMaxValue'].markAsUntouched();
    }

    if(this.coupon.couponPriceValue !== null && this.coupon.couponPriceValue !== undefined && this.coupon.couponPriceValue > 0) {
      this.couponForm.controls['couponPriceValue'].enable();
      this.couponForm.controls['couponPriceValue'].markAsUntouched();
    }
 
    if (this.coupon.couponMaxUses != null && this.coupon.couponMaxUses != undefined && this.coupon.couponMaxUses > 0) {
      this.couponForm.controls['couponMaxUses'].enable();
      this.couponForm.controls['couponMaxUses'].markAsUntouched();
    }

    if (this.coupon.couponMinCost != null && this.coupon.couponMinCost != undefined && this.coupon.couponMinCost > 0) {
      this.couponForm.controls['couponMinPriceValue'].enable();
      this.couponForm.controls['couponMinPriceValue'].markAsUntouched();
    }

    if (this.coupon.couponMinArticlesQuantity != null && this.coupon.couponMinArticlesQuantity != undefined && this.coupon.couponMinArticlesQuantity > 0) {
      this.couponForm.controls['couponMinArticlesQuantity'].enable();
      this.couponForm.controls['couponMinArticlesQuantity'].markAsUntouched();
    }
    this.loading = false;
  }

  // Clean form
  cleanForm() {

      this.coupon.couponId = 0;
      this.coupon.couponSaleId = null;
      this.coupon.couponProductId = null;
      this.coupon.couponCategoryId = null;

      this.coupon.couponCategoryName = null;
      this.coupon.couponSubCategoryId = null;
      this.coupon.couponSubCategoryName = null;
      this.coupon.couponCode = null;

      this.coupon.couponType = null;
      this.coupon.couponByPercentage = 1;
      this.coupon.couponByPrice = 0;
      this.coupon.couponPriceValue = null;

      this.coupon.couponPercentageValue = null;
      this.coupon.couponMinArticlesQuantity = null;
      this.coupon.couponMaxUses = null;
      this.coupon.couponDateStart = null;

      this.coupon.couponDateEnd = null;
      this.coupon.couponMinCost = null;
      this.coupon.couponMaxCost = 1000000;
      this.coupon.couponActive = 1;

      this.isByPercentage = true;

      this.limitUses = true;
      this.limitByCost = true;

      this.limitByArticlesQuantity = true;
      this.inputsDisabled = false;
      this.saveCoupon = false;

      this.categories = [];
      this.subCategories = [];

      this.inputsDisabled = false;

      this.getCategories();

      this.couponForm.reset();
  }

  // Create coupon function
  createCoupon() {
    console.log(this.coupon);
    let startDate = null;
    let endDate = null;

    if(this.coupon.couponDateStart !== null && this.coupon.couponDateEnd !== null) {
      startDate = this.coupon.couponDateStart;
      endDate = this.coupon.couponDateEnd;
    }
    this.loading = true;
    this.apiService
      .createCoupon(
        0,
        this.themingService.shopId,
        null,
        null,
        this.coupon.couponCategoryId,
        this.coupon.couponSubCategoryId,
        this.coupon.couponCode,
        this.coupon.couponType,
        this.coupon.couponPriceValue,
        this.coupon.couponPercentageValue,
        this.coupon.couponMinArticlesQuantity,
        this.coupon.couponMaxUses,
        startDate,
        endDate,
        this.coupon.couponMinCost,
        this.coupon.couponMaxCost,
        this.coupon.couponActive
        )
        .then((data: any) => {
          this.saveCoupon = true;
          this.loading = false;
        })
      .catch((error) => {
        console.error(error);
        this.loading = false;
      });
  }

  // Update coupon function
  updateCoupon() {
    console.log(this.coupon);
    let startDate = null;
    let endDate = null;
    this.loading = true;

    if(this.coupon.couponDateStart !== null && this.coupon.couponDateEnd !== null) {
      startDate = this.coupon.couponDateStart;
      endDate = this.coupon.couponDateEnd;
    }
    this.apiService
      .updateCoupon(
        this.coupon.couponId,
        this.themingService.shopId,
        null,
        null,
        this.coupon.couponCategoryId,
        this.coupon.couponSubCategoryId,
        this.coupon.couponCode,
        this.coupon.couponType,
        this.coupon.couponPriceValue,
        this.coupon.couponPercentageValue,
        this.coupon.couponMinArticlesQuantity,
        this.coupon.couponMaxUses,
        startDate,
        endDate,
        this.coupon.couponMinCost,
        this.coupon.couponMaxCost,
        this.coupon.couponActive
      )
      .then((data: any) => {
        this.modifyCoupon = true;
        this.loading = false;
      })
      .catch((error) => {
        console.error(error);
        this.loading = false;
      });
  }

  // Get categories functions
  getCategories() {
    this.apiService
      .getCategories(this.themingService.shopId)
      .then((data: any) => {
        this.categories = data.categories;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getSubCategories() {
    this.apiService
      .getCategories(this.themingService.shopId)
      .then((data: any) => {
        for (let i = 0; i < data.categories.length; i++) {
          if (
            data.categories[i].categoryIdParent === this.coupon.couponCategoryId
          ) {
            this.subCategories.push(data.categories[i]);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getCategoryById(id: number, filter: string) {
    this.apiService
      .getCategoryById(id, this.themingService.shopId)
      .then((data: any) => {
        console.log(data);
        if (filter === 'category') {
          this.coupon.couponCategoryName = data.category.categoryName;
          console.log(this.coupon.couponCategoryName);
        } else if (filter === 'subCategory') {
          this.coupon.couponSubCategoryName = data.category.categoryName;
          console.log(this.coupon.couponSubCategoryName);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Get coupon by Id
  async getCouponById() {
    this.loading = true;
    if (this.coupon.couponId > 0) {
      this.apiService
        .getCoupon(this.coupon.couponId, this.themingService.shopId)
        .then((data: any) => {
          this.couponForm.patchValue({
            couponCode: data.coupon.couponCode,
            couponName: data.coupon.couponType,
            couponMaxValue: data.coupon.couponMaximunDiscount,
            couponPercentageValue: data.coupon.couponPercentageValue,
            couponPriceValue: data.coupon.couponPriceValue,
            couponMinPriceValue: data.coupon.couponMinimunCost,
            couponMinArticlesQuantity: data.coupon.couponMinArticlesQuantity,
            couponMaxUses: data.coupon.couponMaxUses
          });
          this.loading =false 
          this.coupon.couponProductId = data.coupon.couponProductId;
          this.coupon.couponCategoryId = data.coupon.couponCategoryId;
          this.coupon.couponSubCategoryId = data.coupon.couponSubCategoryId;
          this.coupon.couponCode = data.coupon.couponCode;

          this.coupon.couponType = data.coupon.couponType;
          this.coupon.couponPriceValue = data.coupon.couponPriceValue;
          this.coupon.couponPercentageValue = data.coupon.couponPercentageValue;
          this.coupon.couponMinArticlesQuantity = data.coupon.couponMinArticlesQuantity;

          this.coupon.couponMaxUses = data.coupon.couponMaxUses;
          this.coupon.couponMinCost = data.coupon.couponMinimunCost;
          this.coupon.couponMaxCost = data.coupon.couponMaximunDiscount;
          this.coupon.couponActive = data.coupon.couponActive;

          this.coupon.couponDateStart = this.formatDate(data.coupon.couponDateStart);
          this.coupon.couponDateEnd = this.formatDate(data.coupon.couponDateEnd);

          if (data.coupon.couponPriceValue > 0) {
            this.coupon.couponByPrice = 1;
            this.isByPercentage = false;
          } else if (data.coupon.couponPercentageValue > 0) {
            this.coupon.couponByPercentage = 1;
            this.isByPercentage = true;
          }

          if (data.coupon.couponMinimunCost > 0) {
            this.limitByCost = false;
          } else if (data.coupon.couponMinArticlesQuantity > 0) {
            this.limitByArticlesQuantity = false;
          }

          if (data.coupon.couponMaxUses) {
            this.limitUses = false;
          }

          if (data.coupon.couponCategoryId !== null) {
            this.getCategoryById(this.coupon.couponCategoryId, 'category');
          }

          if (data.coupon.couponSubCategoryId !== null) {
            this.getCategoryById(
              this.coupon.couponSubCategoryId,
              'subCategory'
            );
          }
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    } else {
      this.coupon.couponId = 0;
      this.loading = false;
    }
  }

  formatDate(date:any){
    let month: any;
    let day: any;
    let hour: any;
    let minutes: any;

    let newDate = new Date(date);
      let year =  newDate.getFullYear();
      month = newDate.getMonth() + 1;
      day = newDate.getDate();

      hour = newDate.getHours();
      minutes = newDate.getMinutes();

      if( String((Math.abs(month))).length === 1){
        month = "0"+month;
      }
      if( String((Math.abs(day))).length === 1){
        day = "0"+day;
      }
      if( String((Math.abs(hour))).length === 1){
        hour = "0"+hour;
      }
      if( String((Math.abs(minutes))).length === 1){
        minutes = "0"+minutes;
      }

    let finalDate = year + '-' + month + '-' + day + 'T' + hour + ':' + minutes;
    return finalDate;
  }

  cancel(){
    this.cleanForm();
    this.router.navigate(['/coupons']);
  }

  addModal(event: any){
   if(event){
      this.saveCoupon = false;
      this.cleanForm();
   }else{
     this.cleanForm();
     this.router.navigate(['/coupons']);
   }
  }

   updateModal(event: any){
    if(event){
      this.modifyCoupon = false;
      this.cleanForm();
      this.router.navigate(['/coupons']);
    }
  }
}
