import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { SettingsService } from 'src/app/settings.service';
import { ThemingService } from 'src/app/theming.service';
declare var $: any;

@Component({
  selector: 'app-payment-settings',
  templateUrl: './payment-settings.component.html',
  styleUrls: ['./payment-settings.component.scss']
})
export class PaymentSettingsComponent implements OnInit {

  loading= false;
  paymentMethods: any[] = [];
  paymentTypes: any[] = [];

  modalDelete = false;
  paymentTypeIdToDelete = 0;

  constructor(
    public apiService: ApiService,
    private themingService: ThemingService,
    public settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.getPaymentMethods();
  }

  setEditingPaymentMethod(paymentMethodId: string){
    this.settingsService.setEditingPaymentMethod(paymentMethodId);
  }

  setCreatingPaymentMethod(){
    this.settingsService.setCreatingPaymentMethod();
  }

  async getPaymentMethods(){
    this.loading = true;
    await this.apiService.getPaymentMethods(this.themingService.shopId)
    .then((data: any) => {
      console.log("data: ",data);
      this.paymentMethods = data.paymentMethods;
      this.paymentTypes = data.paymentTypes;
      
    })
    .catch((error: Error) => {
      console.error(error);
      this.loading = false; 
    });
    this.loading = false;
  }

  deletePayment(paymentTypeId: any){
    if (paymentTypeId) {
      this.loading = true;
      this.apiService.deletePayment(this.themingService.shopId, this.paymentTypeIdToDelete)
        .then((data) => {
          console.log("data: ", JSON.stringify(data));
          this.getPaymentMethods();
          $('#deletePaymentModal').modal('show');
          this.loading = false; 
        })
        .catch((error) => {
          console.error(error);
          this.loading = false; 
        });
    }
    this.modalDelete = false;
  }

  closeModal(){
    $('#deletePaymentModal').modal('hide');
  }

}
