import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { alertsAnimation, AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  animations: [ alertsAnimation ]
})
export class ChangePasswordComponent {
  @Input() PasswordPageActive: boolean = false;
  @Input() emailToValid: string = "";
  @Output() codePage = new EventEmitter<boolean>();

  exceptionMessage: String = "";
  isVerifing: boolean = false;
  isPasswordVisible: boolean = false;
  isConfirmPasswordVisible: boolean = false;

  public changePasswordForm!: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
    /* Creating a form group with three controls, code, givenPassword and confirmGivenPassword. */
    this.changePasswordForm = this.fb.group({
      "code": ['', [
        Validators.required,
        Validators.pattern('[^\s][0-9\u00f1\u00d1][^\s]*$')
      ]],
      "givenPassword": ['', [
        Validators.required,
        Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?!.* ).{8,16}$')
      ]],
      "confirmGivenPassword": ['',[Validators.required]],
    }, {
      validators: this.authService.confirmPasswordValidator("givenPassword", "confirmGivenPassword")
    });
  }

/**
 * It returns the form controls of the changePasswordForm
 * @returns The form controls.
 */
  get changePasswordFormValidation() {
    return this.changePasswordForm.controls;
  }

  /**
   * The function is called when the user clicks the "Change Password" button. It calls the
   * `changePassword` function in the `authService` and passes in the email address, the code, and the
   * new password. If the function is successful, the `isVerifing` variable is set to `true`. If the
   * function fails, the `isVerifing` variable is set to `false` and the `exceptionMessage` variable is
   * set to the error message returned by the `handleErrors` function in the `authService`
   */
  onChangePassword(){
    this.authService.changePassword(this.emailToValid,this.changePasswordForm.value.code,this.changePasswordForm.value.givenPassword).then(()=>{
      this.isVerifing = true;
    }).catch(error =>{
      this.isVerifing = false;
      this.exceptionMessage = this.authService.handleErrors(error.message || JSON.stringify(error), this.emailToValid);
    });
  }

/**
 * It navigates to the login page and passes the emailToValid variable as a query parameter.
 */
  goToLogin(){
    this.router.navigate(['/login']);
  }

}
