<div class="container" *ngIf="isLoaded" [@inOutAnimation]>
  <div class="container-inventory">
    <div class="header">
      <h1 class="title">Editar perfil</h1>
      <app-back-arrow-settings [callback]="setListingUsers"></app-back-arrow-settings>
    </div>
      <div class="white-card">
        <div class="staff-container">
          <div class="member-container">
            <div>
              <p class="user-name">{{user.attributes.given_name + " " + user.attributes.family_name}}</p>
            </div>
            <div class="role-container">
              <p>{{user.attributes["custom:user_role"]}}</p>
            </div>
          </div>
          <div class="user-state">
            <p>Email:</p>
            <p class="state">{{user.attributes.email}}</p>
          </div>
          <div class="user-state">
            <p>Teléfono:</p>
            <p class="state" *ngIf="user.attributes.phone_number != undefined">{{user.attributes.phone_number.split('+506').pop()}}</p>
          </div>
          <hr>
          <div class="alert-span-exeption">
            <span *ngIf="exceptionMessage != ''" [@inOutAnimation] class="error-message">{{exceptionMessage}}</span>
          </div>

        <form action="post" [formGroup]="editForm" (ngSubmit)="onEditUser()" *ngIf="isEditing">
            <div class="flex-row">
              <div class="form-group">
                <label for="firstname-input">Nombre</label>
                <input
                  type="text"
                  name="firstname-input"
                  class="textInput"
                  id="firstname-input"
                  formControlName="givenName"
                  (focus)="exceptionMessage = ''"

                >
                <div class="alert-span" *ngIf="editFormValidation['givenName'].touched && editFormValidation['givenName'].invalid">
                  <label class="error-message" *ngIf="editFormValidation['givenName'].errors && editFormValidation['givenName'].errors['required']">
                    Este campo es requerido
                  </label>
                  <label class="error-message" *ngIf="editFormValidation['givenName'].errors && (editFormValidation['givenName'].errors['pattern'] || editFormValidation['givenName'].errors['minlength'])">
                    Formato de nombre incorrecto
                  </label>
                </div>
              </div>
              <div class="form-group">
                <label for="lastname-input">Apellidos</label>
                <input type="text" name="lastname-input" class="textInput" id="lastname-input" formControlName="familyName" (focus)="exceptionMessage = ''">
                <div class="alert-span" *ngIf="editFormValidation['familyName'].touched && editFormValidation['familyName'].invalid">
                  <label class="error-message" *ngIf="editFormValidation['familyName'].errors && editFormValidation['familyName'].errors['required']">
                    Este campo es requerido
                  </label>
                  <label class="error-message" *ngIf="editFormValidation['familyName'].errors && (editFormValidation['familyName'].errors['pattern'] || editFormValidation['familyName'].errors['minlength'])">
                    Formato de apellido incorrecto
                  </label>
                </div>
              </div>
            </div>
            <div class="flex-row">
              <div class="form-group">
                <label for="phoneNumber-input">Teléfono</label>
                <input type="number" name="phoneNumber-input" class="textInput" id="phoneNumber-input" formControlName="phoneNumber" (focus)="exceptionMessage = ''">
                <div class="alert-span" *ngIf="editFormValidation['phoneNumber'].touched && editFormValidation['phoneNumber'].invalid">
                  <label class="error-message" *ngIf="editFormValidation['phoneNumber'].errors && (editFormValidation['phoneNumber'].errors['pattern'] || editFormValidation['phoneNumber'].errors['maxLength'])">
                    Formato de teléfono incorrecto
                  </label>
                </div>
              </div>
            </div>
          <div class="form-buttons">
            <button class="edit" type="submit" [ngClass]="{'disabled': editForm.invalid}" [disabled]="editForm.invalid">Guardar</button>
            <button class="cancel" type="button" (click)="toggleEditView()">Cancelar</button>
          </div>
        </form>

        <form action="post" [formGroup]="passwordForm" (ngSubmit)="onUpdatePassword()" *ngIf="isChangingPassword">
            <div class="flex-row">
              <div class="form-group">
                <label for="user-password-input">Contraseña</label>
                <div class="password-input">
                  <input id="user-password-input" [type]="isPasswordVisible ? 'text':'password'" name="password" class="textInput" formControlName="oldPassword" (focus)="exceptionMessage = ''">
                  <i
                    class="password-icon"
                    [ngClass]="isPasswordVisible ? 'hide-password' : 'show-password'"
                    (click)="isPasswordVisible = !isPasswordVisible"
                  ></i>
                </div>
                <div class="alert-span" *ngIf="passwordFormValidation['oldPassword'].touched && passwordFormValidation['oldPassword'].invalid">
                  <label class="error-message" *ngIf="passwordFormValidation['oldPassword'].errors && passwordFormValidation['oldPassword'].errors['required']">
                    Este campo es requerido
                  </label>
                  <label class="error-message" *ngIf="passwordFormValidation['oldPassword'].errors && (passwordFormValidation['oldPassword'].errors['pattern'] || passwordFormValidation['oldPassword'].errors['minlength'])">
                    Formato de contraseña incorrecto
                  </label>
                </div>
              </div>

              <div class="form-group">
                <label for="new-password-input">Nueva contraseña</label>
                <div class="password-input">
                  <input id="new-password-input" [type]="isNewPasswordVisible ? 'text':'password'" name="password" class="textInput" formControlName="newPassword" (focus)="exceptionMessage = ''">
                  <i
                    class="password-icon"
                    [ngClass]="isNewPasswordVisible ? 'hide-password' : 'show-password'"
                    (click)="isNewPasswordVisible = !isNewPasswordVisible"
                  ></i>
                </div>
                <div class="alert-span" *ngIf="passwordFormValidation['newPassword'].touched && passwordFormValidation['newPassword'].invalid">
                  <label class="error-message" *ngIf="passwordFormValidation['newPassword'].errors && passwordFormValidation['newPassword'].errors['required']">
                    Este campo es requerido
                  </label>
                  <label class="error-message" *ngIf="passwordFormValidation['newPassword'].errors && (passwordFormValidation['newPassword'].errors['pattern'] || passwordFormValidation['newPassword'].errors['minlength'])">
                    Formato de contraseña incorrecto
                  </label>
                </div>
              </div>

            </div>
          <div class="form-buttons">
            <button class="edit" type="submit" [ngClass]="{'disabled': passwordForm.invalid}" [disabled]="passwordForm.invalid">Guardar</button>
            <button class="cancel" type="button" (click)="togglePasswordView()">Cancelar</button>
          </div>
        </form>


        <div class="form-buttons" *ngIf="!isEditing && !isChangingPassword">
          <button class="submit-btn" type="button" (click)="toggleEditView()">Editar</button>
          <button class="submit-btn" type="button" (click)="togglePasswordView()">Cambiar contraseña</button>
        </div>
        </div>
      </div>
  </div>
</div>

