import { Component, OnInit } from '@angular/core';
import { alertsAnimation, AuthService } from 'src/app/auth.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { SettingsService } from 'src/app/settings.service';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  animations: [ alertsAnimation ]
})
export class UserProfileComponent implements OnInit {

  user: any;

  isLoaded: boolean = false;
  exceptionMessage: String = '';
  permissions: String = '';

  isPasswordVisible: boolean = false;
  isNewPasswordVisible: boolean = false;

  isEditing: boolean = false;
  isChangingPassword: boolean = false;

  public editForm!: FormGroup;
  public passwordForm!: FormGroup;


  constructor(
    private authService: AuthService,
    private settingsService: SettingsService,
    private fb: FormBuilder) {
      this.editForm = this.fb.group({
        "givenName": ['',[
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('[a-zA-ZÀ-ÿ][a-zA-ZÀ-ÿ ]+'),
        ]],
        "familyName": ['',[
            Validators.required,
            Validators.minLength(3),
            Validators.pattern('[a-zA-ZÀ-ÿ][a-zA-ZÀ-ÿ ]+'),
        ]],
        "phoneNumber": ['',[
            Validators.maxLength(15),
            Validators.pattern('[0-9]+'),
        ]],
      });

      this.passwordForm = this.fb.group({
        "oldPassword": ['',[
          Validators.required,
          Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?!.* ).{8,16}$')
        ]],
        "newPassword": ['',[
          Validators.required,
          Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?!.* ).{8,16}$')
        ]],
      });
    }


    get editFormValidation() {
      return this.editForm.controls;
    }

    get passwordFormValidation() {
      return this.passwordForm.controls;
    }

  ngOnInit(): void {
    this.getUserData();
  }

  getUserData(){
    this.authService.currentAuthUser().then((user: any)=>{
      this.user = user;
    }).finally(()=>{
      this.isLoaded = true;
    });
  }

  loadUserData(){
    this.editForm.patchValue({
      givenName: this.user.attributes.given_name,
      familyName: this.user.attributes.family_name
    });

    if(this.user.attributes.phone_number != null){
      this.editForm.patchValue({
        phoneNumber: this.user.attributes.phone_number.split('+506').pop()
      });
    }
  }

  toggleEditView(){
    if(this.isEditing){
      this.isEditing = false;
    }else{
      this.isEditing = true;
      this.loadUserData();
    }
  }

  togglePasswordView(){
    if(this.isChangingPassword){
      this.isChangingPassword = false;
    }else{
      this.isChangingPassword = true;
    }
  }

  onEditUser(){
    this.authService.updateUser(
      this.user.username, 
      this.editForm.value.givenName, 
      this.editForm.value.familyName, 
      this.editForm.value.phoneNumber, 
      this.permissions
    ).then(()=>{
      this.getUserData();
      this.isEditing = false;
    }).catch((error)=>{
      this.exceptionMessage = error.message;
    });
  }

  onUpdatePassword(){
    this.authService.updatePassword(this.passwordForm.value.oldPassword, this.passwordForm.value.newPassword).then(()=>{
      this.isChangingPassword = false;
    }).catch((error)=>{
      this.exceptionMessage = error.message;
    });
  }

  setListingUsers(){
    this.settingsService.setListingUsers()
  }
}
