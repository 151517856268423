<app-go-back *ngIf="currentStep !== 5" [route]="'/login'"></app-go-back>
<section>
    <img class="mobydyg-logo" src="../../../assets/static/mobydyg.png" alt="MobyDyg Logo">
    <div class="card" [@slideLeft]="estado" *ngIf="currentStep === 1">
      <div class="illustration">
        <img src="../../../assets/illustrations/sign-in.png" />
      </div>
      <div class="form" [@inOutAnimation]>
        <h2>REGÍSTRATE</h2>
        <form action="post" [formGroup]="signUpForm">
          <div class="form-group">
            <div class="alert-span-exeption">
              <span class="error-message" [@inOutAnimation]>{{exceptionMessage}}</span>
            </div>
            <div class="alert-span" *ngIf="signUpFormValidation['givenEmail'].touched && signUpFormValidation['givenEmail'].invalid">
              <label class="error-message" *ngIf="signUpFormValidation['givenEmail'].errors && signUpFormValidation['givenEmail'].errors['required']" [@inOutAnimation]>
                Este campo es requerido
              </label>
              <label class="error-message" *ngIf="signUpFormValidation['givenEmail'].errors && signUpFormValidation['givenEmail'].errors['pattern'] && signUpFormValidation['givenEmail'].errors['email']" [@inOutAnimation]>
                Formato de correo incorrecto
              </label>
            </div>
            <input type="text" name="email" placeholder="Tu correo electrónico" class="textInput" formControlName="givenEmail" (focus)="exceptionMessage = ''">
          </div>
          <div  class="button-container">
            <button class="back-button" disabled (click)="prevStep($event)"></button>
            <div class="next-skip-buttons">
              <button class="next-button" [disabled]="isStepInvalid()" (click)="nextStep($event)">Siguiente</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card" [@slideLeft]="estado" *ngIf="currentStep === 2">
      <div class="illustration">
        <img src="../../../assets/illustrations/sign-in.png" />
      </div>
      <div class="form" [@inOutAnimation]>
        <h2>REGÍSTRATE</h2>
        <form action="post" [formGroup]="signUpForm">
          <div class="form-group">
            <div class="alert-span" *ngIf="signUpFormValidation['givenShopName'].touched && signUpFormValidation['givenShopName'].invalid">
              <label class="error-message" *ngIf="signUpFormValidation['givenShopName'].errors && signUpFormValidation['givenShopName'].errors['required']" [@inOutAnimation]>
                Este campo es requerido
              </label>
            </div>
            <input type="text" name="shopName" placeholder="El nombre de tu tienda" class="textInput" formControlName="givenShopName" >
          </div>
          <div class="button-container">
            <button class="back-button" (click)="prevStep($event)">Anterior</button>
            <div class="next-skip-buttons">
              <button class="next-button" [disabled]="isStepInvalid()" (click)="nextStep($event)">Siguiente</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card" [@slideLeft]="estado" *ngIf="currentStep === 3">
      <div class="illustration">
        <img src="../../../assets/illustrations/sign-in.png" />
      </div>
      <div class="form" [@inOutAnimation]>
        <h2>REGÍSTRATE</h2>
        <form action="post" [formGroup]="signUpForm">
          <div class="form-group">
            <div class="alert-span" *ngIf="signUpFormValidation['givenCountry'].touched && signUpFormValidation['givenCountry'].invalid">
              <label class="error-message" *ngIf="signUpFormValidation['givenCountry'].errors && signUpFormValidation['givenCountry'].errors['required']" [@inOutAnimation]>
                Este campo es requerido
              </label>
            </div>
            <select class="form-select" (change)="changeCountry($event)" formControlName="givenCountry">
              <option selected disabled hidden>Selecione su país</option>
              <option *ngFor="let country of countries" [value]="country.countryId">
                {{ country.countryName }}
              </option>
            </select>
          </div>
          <div class="button-container">
            <button class="back-button" (click)="prevStep($event)">Anterior</button>
            <div class="next-skip-buttons">
              <button class="next-button" [disabled]="isStepInvalid()" (click)="nextStep($event)">Siguiente</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card" [@slideLeft]="estado" *ngIf="currentStep === 4">
      <div class="illustration">
        <img src="../../../assets/illustrations/sign-in.png" />
      </div>
      <div class="form" [@inOutAnimation]>
        <h2>REGÍSTRATE</h2>
        <form action="post" [formGroup]="signUpForm">
          <div class="form-group">
            <ul>
              <li><span>Email: {{signUpForm.value.givenEmail}}.</span></li>
              <li><span>Tienda: {{signUpForm.value.givenShopName}}.</span></li>
              <li><span>País: {{ country.countryName }}.</span></li>
            </ul>
          </div>
          <div class="button-container">
            <button class="back-button" (click)="prevStep($event)">Anterior</button>
            <div class="next-skip-buttons">
              <button type="button" [disabled]="isStepInvalid()" (click)="onSignUp($event)" class="next-button">Crear tienda</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card" [@slideLeft]="estado" *ngIf="currentStep === 5">
      <div class="illustration">
        <img src="../../../assets/illustrations/account-created.png" />
      </div>
      <div class="form" [@inOutAnimation]>
        <h2>Cuenta creada</h2>
        <form action="post" [formGroup]="signUpForm">
          <div class="form-group">
            <ul>
              <li><span>Email: {{signUpForm.value.givenEmail}}.</span></li>
              <li><span>Tienda: {{signUpForm.value.givenShopName}}.</span></li>
              <li><span>País: {{ country.countryName }}.</span></li>
            </ul>
          </div>
        </form>
        <div class="button-container">
          <div class="next-skip-buttons">
            <a (click)="goToLogin()" type="submit" class="next-button">Inicia sesión</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  