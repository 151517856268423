import { Component } from '@angular/core';
import { alertsAnimation } from 'src/app/auth.service';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss'],
  animations: [ alertsAnimation ],
  host: {'[@inOutAnimation]':''}
})
export class PasswordRecoveryComponent {

  public codePage = true;
  emailToValid: string = "";

/* A function that is called when the user clicks on the button to change the page. */
  passwordPage(value: boolean) {
    this.codePage = !value;
  }

  constructor() {}
}
