import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss']
})
export class CreateClientComponent implements OnInit {
  loading: boolean = false;
  clientForm: FormGroup;
  showPassword: boolean = false;
  username = '';

  constructor(
    private apiService: ApiService,
    public themingService: ThemingService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.clientForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      gender: ['', Validators.required],
      phone: ['', Validators.required],
      nationalId: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: [{ value: '', disabled: true }, Validators.required],
      isPoolRegister: [false]
    });
  }

  ngOnInit(): void {
    this.handlePhoneInput();
    this.handleIsPoolRegisterToggle();
  }

  handlePhoneInput(): void {
    this.clientForm.get('phone')?.valueChanges.subscribe((value) => {
      if (value && !value.startsWith('+')) {
        this.clientForm.get('phone')?.setValue('+' + value, { emitEvent: false });
      }
    });
  }

  handleIsPoolRegisterToggle(): void {
    this.clientForm.get('isPoolRegister')?.valueChanges.subscribe((isChecked) => {
      if (isChecked) {
        this.clientForm.get('password')?.enable();  
      } else {
        this.clientForm.get('password')?.disable(); 
        this.clientForm.get('password')?.reset(); 
        this.showPassword = false;
      }
    });
  }

  generatePassword(): void {
    if (this.clientForm.get('password')?.enabled) {
      const length = 12;
      const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
      let password = "";
      for (let i = 0, n = charset.length; i < length; ++i) {
        password += charset.charAt(Math.floor(Math.random() * n));
      }
      this.clientForm.controls['password'].setValue(password);
    }
  }

  togglePasswordVisibility(): void {
    if (this.clientForm.get('password')?.enabled) {
      this.showPassword = !this.showPassword;
    }
  }

  createClient(): void {
    if (this.clientForm.valid) {
      this.loading = true;
      const clientData = this.clientForm.value;
      
      this.apiService.createClient(
        this.themingService.shopId,
        clientData.firstName,
        clientData.lastName,
        clientData.phone,
        clientData.nationalId,
        clientData.email,
        this.username || '',
        clientData.isPoolRegister,
        clientData.password
      ).then((data: any) => {
        this.loading = false;
        if (data.statusCode == 200) {
          $('#successClientModal').modal('show');
          $('#successClientModal').on('hidden.bs.modal', () => {
            this.clientForm.reset();
            this.router.navigate(['/clients']);
          });
        } else {
          this.showErrorModal();
        }
      }).catch((error) => {
        console.log(error);
        this.loading = false;
        this.showErrorModal();
      });
    } else {
      this.showRequiredFieldsModal();
    }
  }

  showErrorModal(): void {
    $('#errorClientModal').modal('show');
    $('#errorClientModal').on('hidden.bs.modal', () => {
      this.clientForm.markAsPristine();
    });
  }

  showRequiredFieldsModal(): void {
    $('#requiredClientModal').modal('show');
  }

  closeModal() {
    $('#successClientModal').modal('hide');
    $('#errorClientModal').modal('hide');
    $('#requiredClientModal').modal('hide');
  }
}
