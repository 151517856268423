<article *ngIf="giftCard" class="gift-card">
  <ng-container *ngIf="giftCard.images">
    <img class="images-render-card" [src]="giftCard.images" alt="Gift Card Image">
  </ng-container>
  <div class="images-render-card" *ngIf="!giftCard.images">
    <div class="gift-card-image" *ngIf="!giftCard.images"></div>
    <section class="gift-card-content" *ngIf="!giftCard.images">
      <div class="gift-card-tittle" style="font-family: 'Playlist Script', cursive;">Gift Card</div>
      <div class="gift-card-amount">₡{{ giftCard?.amount.split(',')[0] }}</div>
    </section>
  </div>
</article>
