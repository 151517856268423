<div class="container">
  <div class="container-giftcards">
    <form #myForm="ngForm">
    <div class="form-card">
      <div class="form-group-container">
        <div>
          <label class="form-label is-required" for="giftCardName">Tipo de tarjeta</label>
          <select class="form-control" id="giftCardName" (change)="onGiftCardNameChange($event)" [(ngModel)]="selectedGiftCardName" name="giftCardName">
            <ng-container *ngIf="!selectedGiftCardName; else selectedCard">
              <option value="" selected hidden>Seleccione una tarjeta</option>
            </ng-container>
            <ng-template #selectedCard>
              <option [value]="selectedGiftCardName">{{ selectedGiftCardName }}</option>
            </ng-template>
            <option *ngFor="let giftCard of giftCards" [value]="giftCard.name">{{ giftCard.name }}</option>
          </select>
        </div>      
        <div class="input-container">
          <label class="form-label is-required" for="giftCard_amount">Montos disponibles</label>
          <select class="form-control" [(ngModel)]="selectedAmount" name="amount" (change)="onAmountChange($event)" [disabled]="!selectedGiftCardName" [disabled]="!cardSelected">
              <ng-container *ngIf="amountsArray[0] === 0">
                  <option value="" selected hidden>Debe seleccionar una tarjeta</option>
              </ng-container>
              <ng-container *ngIf="amountsArray[0] !== 0">
                  <option value="" selected hidden>Seleccione un monto</option>
                  <option *ngFor="let amount of amountsArray" [value]="amount">{{ appComponent.formatMoney(parseAmount(amount)) }}</option>
              </ng-container>
          </select>
      </div>
      </div>
      
        <div class="images-container">
          <div class="form-label">Imagen:</div>
          <div class="image-list">
            <div class="image-box">
              <img *ngIf="selectedGiftCard?.images" [src]="selectedGiftCard.images" alt="Gift Card Image"/>
            </div>
          </div>
        </div> 
        <div class="amount-container">
          <div class="input-container">
            <div class="form-label">Mensaje personalizado:</div>
            <textarea style="resize: none;  height: 80px;"(keyup)="ChangeGiftCardMessage($event)" [(ngModel)]="giftCard.giftCardMessage" name="giftCardMessage"></textarea>
          </div>
          <div class="input-container">
            <div class="form-label is-required">Correo del cliente:</div>
            <input type="text" (input)="ChangeGiftCardEmail($event)" [(ngModel)]="giftCard.giftCardEmail" name="giftCardEmail">
          </div>
        </div>
          <div class="form-buttons">
            <button type="button" class="btn btn-outline-secondary submit-btn" (click)="validateFormData()">
              Crear
            </button>
          </div> 
      </div>
    </form>
  </div>

  <div class="modal giftcard-confirm-modal" id="giftCardLoadingModal" tabindex="-1">
    <div class="modal-dialog giftcard-modal-dialog" role="document">
      <div class="modal-content giftcard-modal-content">
        <div class="modal-body giftcard-modal-body">
          <div class="loading">
            <div class="spinner-border" role="status"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal giftcard-confirm-modal" id="successGiftCardModal" tabindex="-1">
  <div class="modal-dialog giftcard-modal-dialog" role="document">
    <div class="modal-content giftcard-modal-content">
      <div class="modal-body giftcard-modal-body">
        <h3 class="text-center">¡Éxito!</h3>
        <p class="text-center">La Tarjeta fue creada exitosamente!</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
      </div>
    </div>
  </div>
  </div> 

  <div class="modal giftcard-confirm-modal fade" id="errorGiftCardModal" tabindex="-1">
    <div class="modal-dialog giftcard-modal-dialog" role="document">
      <div class="modal-content giftcard-modal-content">
        <div class="modal-body giftcard-modal-body">
          <h3 class="text-center">¡Error!</h3>
          <p class="text-center">¡Hubo un error al guardar los cambios!</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
        </div>
      </div> 
    </div>
  </div>
</div>

<div class="modal error-confirm-modal fade" id="requiredGiftCardModal" tabindex="-1">
  <div class="modal-dialog error-modal-dialog" role="document">
    <div class="modal-content error-modal-content">
      <div class="modal-body error-modal-body">
        <h3 class="text-center">¡Error!</h3>
        <p class="text-center">Debe llenar los espacios requeridos *</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
      </div>
    </div>
  </div>
</div>