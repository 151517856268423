<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>

<div class="container">
  <div class="container-orders">
    <h1 class="title">Tus Cajas</h1>
    <div class="loading" *ngIf="loading">
      <div class="spinner-border" role="status"></div>  
    </div>
    <div *ngIf="!loading" class="form-card">
      <table class="table table-bordered table-hover">
        <thead>
            <th scope="col" class="table-start">Id</th>
            <th scope="col">Estado</th>
            <th scope="col">Fecha de Apertura</th>
            <th scope="col">Monto de Apertura</th>
            <th scope="col">Fecha de Cierre</th>
            <th scope="col" class="table-end">Monto de Cierre</th>
        </thead>
        <tbody>
          <tr *ngFor="let cashRegister of cashRegisters">
            <td>
              <a [routerLink]="['/cashRegister', cashRegister.cashRegisterId]" routerLinkActive="active">{{ cashRegister.cashRegisterId }}</a>
            </td>
            <td>{{ cashRegister.status === 1 ? 'Abierta' : 'Cerrada' }}</td>
            <td>{{ appComponent.formatDate(cashRegister.openDate)}}</td>
            <td>{{ appComponent.formatMoney(cashRegister.initialAmount) }}</td>
            <td>{{ cashRegister.status === 0 ? appComponent.formatDate(cashRegister.closeDate) : 'Pendiente' }}</td>
            <td>{{ cashRegister.status === 0 ? appComponent.formatMoney(cashRegister.finalAmount) : 'Pendiente' }}</td>
        </tbody>
      </table>
    </div>
  </div>
</div>
