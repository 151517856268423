import { Component, OnInit, Input} from '@angular/core';
import { SettingsService } from 'src/app/settings.service';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
declare let $: any;

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})

export class CollectionsComponent implements OnInit {
  @Input() collectionId: string = '';
  
collections: any[] = []
searchString: string = '';
loading: boolean = false;
modalDelete = false;
collectionIdToDelete: any;


constructor(
  private settingsService: SettingsService,
  private apiService: ApiService,
  private themingService: ThemingService
) { }

ngOnInit(): void {
  this.getCollections()
}

  async getCollections () {
    this.loading = true;
    await this.apiService
      .getCollections(
        this.themingService.shopId
      )
      .then((data: any) => {
        console.log(data);
        this.collections = data.collections;
      });
    this.loading = false;
  }

  setCreatingCollection(){
    this.settingsService.setCreatingCollection();
  }

  setEditingCollection(collectionId: string){
  this.settingsService.setEditingCollection(collectionId);
  }

  searchCollections(){
  }

  deteleCollection(response: number) {
    if (response) {
      this.loading = true;
      this.apiService.deleteCollection(this.themingService.shopId, this.collectionIdToDelete)
        .then((data) => {
          console.log("data: ", JSON.stringify(data));
          this.getCollections();
          $('#deleteCollectionModal').modal('show');
          this.loading = false; 
        })
        .catch((error) => {
          console.error(error);
          this.loading = false; 
        });
    }
    this.modalDelete = false;
  }

  closeModal(){
    $('#deleteCollectionModal').modal('hide');
  }
}