<div class="container" *ngIf="loaded" [@inOutAnimation]>
  <div class="container-inventory">
    <div class="header">
      <h1 class="title">Usuarios y Roles</h1>
      <button class="button-add" *ngIf="isCurrentUserOwner" (click)="setCreatingUser()">Nuevo</button>
    </div>
    <section>
      <div class="white-card">
        <div class="user-label">
          <i class="user-icon"></i>
          <span>Dueño de la tienda</span>
        </div>
        <div class="user-container">
          <!-- <p class="user-name" (click)="goToOwnerProfile()">{{owner.given_name + " " + owner.family_name}}</p> -->
          <p class="user-name" (click)="setEditingOwner()">{{owner.given_name + " " + owner.family_name}}</p>
          <p class="user-email">{{owner.email}}</p>
        </div>
      </div>

      <div class="white-card">
        <div class="user-label">
          <i class="user-icon"></i>
          <span>Personal de la tienda ({{staffMembers}}/{{staffMaxMembers}})</span>
        </div>

        <div class="staff-container" *ngFor="let user of staffList">
          <div class="member-container">
            <div>
              <!-- <p class="user-name" (click)="routeUser(user)">{{user.given_name + " " + user.family_name}}</p> -->
              <p class="user-name" (click)="setEditingUser(user.Username)">{{user.given_name + " " + user.family_name}}</p>
              <p class="user-email">{{user.email}}</p>
            </div>
          </div>
          <hr>
        </div>
      </div>
    </section>
  </div>
</div>
