import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
import { ThemingService } from 'src/app/theming.service';
import 'quill-emoji/dist/quill-emoji.js';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import * as feather from 'feather-icons';
declare var $: any;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  loading = false;
  productId: number = 0;
  product: any;
  edit = false;
  modules = {};
  // customOptions: any = [];
  productImages: any = [];
  productImagesToDelete: any = [];

  imageSizes: any = {
    thumbnail: 300,
    small: 400,
    medium: 600,
    main: undefined,
  };

  sale = 0
  discountPercentage = 0;
  dicountPrice = 0;

  variantsOne: any = {
    variantType: '',
    variantValues: []
  };

  variantsTwo: any = {
    variantType: '',
    variantValues: []
  };

  variantsThree: any = {
    variantType: '',
    variantValues: []
  };

  items: any = [];
  categories: any;
  subCategories: any 
  subSubCategories: any; 
  industryTypes: any;
  suppliers: any;
  collections: any;

  variantValuesNames = <any[]>[];

  variantGroupCombination1 = <any[]>[];
  variantGroupCombination2 = <any[]>[];
  variantGroupCombination3 = <any[]>[];


  counterVariableInputs1: number = 0;
  counterVariableInputs2: number = 0;
  counterVariableInputs3: number = 0;
  areVariablesChecked = false;
  hiddenVar1: boolean = true;
  hiddenVar2: boolean = true;
  hiddenVar3: boolean = true;

  displayItemsTable: boolean = false;

  variantType1 = {
    variantTypeName: '',
    variantValues: <any[]>[],
  };
  variantType2 = {
    variantTypeName: '',
    variantValues: <any[]>[],
  };
  variantType3 = {
    variantTypeName: '',
    variantValues: <any[]>[],
  };

  variantGroup1Values = <any[]>[];
  variantGroup2Values = <any[]>[];
  variantGroup3Values = <any[]>[];

  combinations = <any[]>[];
  combinationsForItems = <any[]>[];

  constructor(
    public appComponent: AppComponent,
    public route: ActivatedRoute,
    public apiService: ApiService,
    public themingService: ThemingService,
    private sanitizer: DomSanitizer
  ) {
    // this.customOptions = [
    //   {
    //     import: 'attributors/style/size',
    //     whitelist: [
    //       '8px',
    //       '10px',
    //       '12px',
    //       '14px',
    //       '16px',
    //       '18px',
    //       '20px',
    //       '22px',
    //       '24px',
    //     ],
    //   },
    // ];
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        //['blockquote', 'code-block'],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text direction

        [
          {
            size: ['small', false, 'large', 'huge'],
            // size: [
            //   '8px',
            //   '10px',
            //   '12px',
            //   '14px',
            //   '16px',
            //   '18px',
            //   '20px',
            //   '22px',
            //   '24px',
            // ],
          },
        ], // custom dropdown

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],

        //['link', 'image', 'video'], // link and image, video
        ['emoji'],
      ],
    };
    this.route.paramMap.subscribe((params) => {
      let productId = Number(params.get('productId'));
      if (productId != this.productId) {
        this.appComponent.goToTop();
        this.productId = productId;
      }
      this.ngOnInit();
    });
  }

  ngOnInit(): void {
    this.loadPage();
  }

  ngAfterViewInit() {
    feather.replace();
  }

  loadPage() {
    $(document).ready(async () => {
      await this.loadProduct().then(() => {
        this.getCategory();
        this.getSubSubCategoryByCategoryId(this.product.product.productSubCategoryId);
        this.getSubCategoryByCategoryId(this.product.product.productCategoryId);
        this.getSuppliers();
        this.getCollections();
        this.getIndustry();
      });
    });
  }

  async loadProduct() {
    this.loading = true;
    await this.apiService
      .getProductById(this.productId, this.themingService.shopId)
      .then((data) => {
        //console.log("Get product by id ",data);
        this.product = data;
        this.productImages = [];
        let productImagesSet = new Set<string>();
        if(this.product && this.product.items && Array.isArray(this.product.items)) {
          this.product.items.forEach((item: any) => {
            if (item.images && item.images.small && Array.isArray(item.images.small)) {
              item.images.small.forEach((image: any) => {
                  if (image.imageURL && typeof image.imageURL === 'string') {
                    productImagesSet.add(image.imageURL);
                  }
              });
            }
          });
          this.productImages = [...productImagesSet];
        }

        this.product.product.productBaseCost = this.product.product.productBaseCost.toFixed(2);
        this.product.product.productSellPrice = this.product.product.productSellPrice.toFixed(2);

        this.product.variants[0] != undefined ? this.variantsOne = {
          ...this.product.variants[0]
        } : this.variantsOne = {
          variantType: '',
          variantValues: []
        };

        this.product.variants[1] != undefined ? this.variantsTwo = {
          ...this.product.variants[1]
        } : this.variantsTwo = {
          variantType: '',
          variantValues: []
        };

        this.product.variants[2] != undefined ? this.variantsThree = {
          ...this.product.variants[2]
        } : this.variantsThree = {
          variantType: '',
          variantValues: []
        };

        this.items = this.product.items.map((item: any) => {
          return {
            itemId: item.itemId,
            itemCode: item.itemCode,
            itemPrice: item.itemPrice,
            itemQuantity: item.quantity,
            itemStockCommitted: item.stockCommitted,
            variantValues: item.variantValues,
            itemShopPoints: {
              shopPointId: item.shopPointId,
              itemQuantity: item.itemQuantity,
              itemStockCommitted: item.stockCommitted,
            }
          };
        });

        console.log('Items:', this.items);
      }).then(() => {
        this.discountPercentage = this.calculateDiscountPercentage();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
        setTimeout(() => {
          feather.replace();
        }, 100);
      });
  }

  getVariantValueName(){
    this.variantValuesNames = [];
    let found = null;
    let found2 = null;

    for (let i = 0; i < this.items.length; i++) {
      for (let j = 0; j < this.items[i].variantValues.length; j++) {
        let variantValueId = this.items[i].variantValues[j];

        found = this.product.variants.find((element: any) => {return element.variantValues.find((element2: any) => {return element2.variantValueId === variantValueId})});
        found2 = found.variantValues.find((element: any) => {return element.variantValueId === variantValueId});

        this.variantValuesNames.push({
          variantValueName: found2.variantValueName,
          itemId: this.items[i].itemId,
          itemCode: this.items[i].itemCode
        });
      }
    }
    this.concatItemNames();
  }

  concatItemNames(){

    let itemNames: any = [];

    for (let i = 0; i < this.variantValuesNames.length; i++) {
      itemNames.push(this.variantValuesNames.filter((element: any) => {return element.itemId === this.variantValuesNames[i].itemId}));
    }

    itemNames = [...new Set(itemNames.map((item: any) => {return JSON.stringify(item)}))].map((item: any) => {return JSON.parse(item)});

    itemNames = itemNames.map((item: any) => {
      let name = '';
      for (let i = 0; i < item.length; i++) {
        name += item[i].variantValueName + ' ';
      }
      return name;
    });

    console.log(itemNames);

  }

  editProduct() {
    this.edit = true;
    console.log(this.product);
    console.log(this.items);
    this.getVariantValueName();

    if(this.product.items.length > 0){
      this.areVariablesChecked = true;
    }
  }

  cancelEdit() {
    this.edit = false;
    this.loadPage();
  }

  async saveChanges() {
    $('#productModal').modal({ backdrop: 'static', keyboard: false });
    $('#productModal').modal('show');
    // setTimeout(() => {
    //   $('#productModal').modal('hide');
    // }, 1000);
    let imageURLs: any[] = [];
    let imageUploadPromises = [];
    let imageDeletePromises: any[] = [];
    //console.log(this.productImages);
    for (let imageURL of this.productImagesToDelete) {
      imageDeletePromises.push(
        this.apiService.deleteFile(
          imageURL.replace('https://mobydyg-files.s3.amazonaws.com/', '')
        )
      );
    }
    for (const key of Object.keys(this.productImages)) {
      for (let i = 0; i < this.productImages[key].length; i++) {
        let file = this.productImages[key][i].file;
        if (file != undefined) {
          imageUploadPromises.push(() =>
            this.apiService.uploadFile(
              `${this.themingService.shopId}/${environment.stage}/images/item-images/${file.name}.png`,
              file,
              { position: i + 1, type: key }
            )
          );
        } else {
          imageURLs.push({
            imageURL: this.productImages[key][i].imageURL,
            position: i + 1,
            imageType: key,
          });
        }
      }
    }

    await this.throttleActions(imageUploadPromises, 4).then((images) => {
      for (let image of images) {
        imageURLs.push({
          imageURL: (<any>image).imageURL.split('?')[0],
          position: (<any>image).options.position,
          imageType: (<any>image).options.type,
        });
      }
    });

    //.log(imageURLs);

    let items = [];
    let variants: any[] = [];
    for (let item of this.product.items) {
      items.push({
        itemId: item.itemId,
        imageURLs: imageURLs,
      });
    }

   /*  this.apiService
      .updateProduct(
        this.product.product.productId,
        this.themingService.shopId,
        encodeURI(this.product.product.productDescription),
        this.product.product.productIsActive == true ? 1 : 0,
        items,
        this.product.product.productName,
        this.product.product.productCategoryId,
        this.product.product.productIndustryTypeId,
        this.product.product.productSupplierId == null ? 0 : this.product.product.productSupplierId,
        this.product.product.productCollectionId == null ? 0 : this.product.product.productCollectionId,
        this.product.product.productBaseCost,
        this.product.product.productTaxId,
        this.product.product.productSellPrice,
        this.dicountPrice,
        variants,
        new Date().toLocaleString('en-US', {
          timeZone: 'America/Costa_Rica',
        }),
        []
      )
      .finally(async () => {
        await Promise.all(imageDeletePromises);
        this.edit = false;
        $('#productModal').modal('hide');
        this.loadProduct();
      }); */
  }

  drop(event: CdkDragDrop<string[]>) {
    for (const key of Object.keys(this.imageSizes)) {
      moveItemInArray(
        this.productImages[key],
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  descriptionChanged(event: any) {
    //console.log(event);
    //console.log(encodeURI(event.html));

    // this.product.product.productDescription = event.innerHTML;
  }

  downloadImage(position: number) {
    fetch(this.productImages.main[position].imageURL)
      .then((response) => response.blob())
      .then((blob) => {
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.download =
          this.product.product.productCode + '-' + (position + 1) + '.png';
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    // const link = document.createElement('a');
    // let url = this.getFileLocation(this.productImages.main[position].imageURL);
    // link.href = url.origin + url.pathname;
    // link.download =
    //   this.product.product.productCode + '-' + (position + 1) + '.png';
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  }

  getFileLocation = function (href: string) {
    var l = document.createElement('a');
    l.href = href;
    return l;
  };

  deletePhoto(i: number) {
    let image = this.productImages.small[i];
    for (const key of Object.keys(this.productImages)) {
      if (image.file == undefined) {
        this.productImagesToDelete.push(
          JSON.parse(JSON.stringify(this.productImages[key][i].imageURL))
        );
      }
      this.productImages[key].splice(i, 1);
    }
    //console.log(this.productImagesToDelete);
  }

  async uploadImage() {
    var inputFile = $(
      '<input type="file" id="fileInput" multiple="true" style="visibility: hidden; height: 0; width: 0; overflow: hidden" />'
    );
    let aux_this = this;
    inputFile.bind('change', (event: any) => {
      aux_this.readFile(event.target.files);
    });
    inputFile.trigger('click');
  }

  async readFile(files: any) {
    let aux_this = this;
    for (let i = 0; i < (<any>files).length; i++) {
      var promise = new Promise(async (resolve, reject) => {
        let timestamp = Date.now().toString() + '-' + (i + 1);
        var reader = new FileReader();
        reader.readAsDataURL((<any>files)[i]); // read file as data url
        reader.onload = async () => {
          // called once readAsDataURL is completed
          if (reader.result != null) {
            var blob = (<any>files)[i].slice(
              0,
              (<any>files)[i].size,
              'image/png'
            );
            let mainFile = new File([blob], timestamp + '-main', {
              type: 'image/png',
            });
            aux_this.productImages.main = aux_this.productImages.main || [];
            aux_this.productImages.main.push({
              imageURL: this.sanitizer.bypassSecurityTrustResourceUrl(
                <any>reader.result
              ),
              file: mainFile,
            });

            let promises = [];

            for (const key of Object.keys(aux_this.imageSizes)) {
              promises.push(
                new Promise((resolve2, reject2) => {
                  if (aux_this.imageSizes[key] != undefined) {
                    let img = new Image();
                    img.src = <string>reader.result;
                    var c = document.createElement('canvas');
                    var blob;

                    img.onload = async function () {
                      c.width = aux_this.imageSizes[key];
                      c.height = c.width;

                      let newWidth = img.width;
                      let newHeight = img.height;
                      if (img.width > img.height) {
                        if (img.width > c.width) {
                          newHeight *= c.width / newWidth;
                          c.height = newHeight;
                          newWidth = c.width;
                        }
                      } else {
                        if (img.height > c.height) {
                          newWidth *= c.height / newHeight;
                          c.width = newWidth;
                          newHeight = c.height;
                        }
                      }
                      var ctx = c.getContext('2d');
                      (<any>ctx).drawImage(img, 0, 0, newWidth, newHeight); // draw in image
                      c.toBlob(
                        async function (blob0) {
                          blob = blob0;
                          (<any>blob).name = timestamp + '-' + key;
                          (<any>blob).lastModified = new Date();
                          let file = <File>blob;
                          var reader2 = new FileReader();
                          reader2.readAsDataURL(file); // read file as data url
                          reader2.onload = () => {
                            aux_this.productImages[key] =
                              aux_this.productImages[key] || [];
                            aux_this.productImages[key].push({
                              imageURL:
                                aux_this.sanitizer.bypassSecurityTrustResourceUrl(
                                  <any>reader2.result
                                ),
                              file: file,
                            });
                            resolve2(0);
                          };
                        },
                        'image/png',
                        1
                      );
                    };
                  } else {
                    resolve2(0);
                  }
                })
              );
            }
            await Promise.all(promises);
            resolve(0);
          } else {
            reject();
          }
        };
      });
    }
  }

  throttleActions(listOfCallableActions: any[], limit: number) {
    // We'll need to store which is the next promise in the list.
    let i = 0;
    let resultArray = new Array(listOfCallableActions.length);

    // Now define what happens when any of the actions completes. Javascript is
    // (mostly) single-threaded, so only one completion handler will call at a
    // given time. Because we return doNextAction, the Promise chain continues as
    // long as there's an action left in the list.
    function doNextAction(): any {
      if (i < listOfCallableActions.length) {
        // Save the current value of i, so we can put the result in the right place
        let actionIndex = i++;
        let nextAction = listOfCallableActions[actionIndex];
        return Promise.resolve(nextAction())
          .then((result) => {
            // Save results to the correct array index.
            resultArray[actionIndex] = result;
            return;
          })
          .then(doNextAction);
      }
    }

    // Now start up the original <limit> number of promises.
    // i advances in calls to doNextAction.
    let listOfPromises = [];
    while (i < limit && i < listOfCallableActions.length) {
      listOfPromises.push(doNextAction());
    }
    return Promise.all(listOfPromises).then(() => resultArray);
  }


  getCategory(): void {
    this.loading = true;
    this.apiService.getCategories(this.themingService.shopId)
      .then((data: any) => {
        this.categories = data.categories;
        this.loading = false;
      })
      .catch((error) => {
        console.error(error);
        this.loading = false;
        console.log(this.loading)
      });
  }

  getSubCategoryByCategoryId(categoryId: number) {
    this.apiService
    .getSubCategoryByCategoryId( categoryId,this.themingService.shopId, )
    .then((data: any) => {
      this.subCategories = data.subCategories;
      console.log(this.subCategories);
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
    });
}

  getSubSubCategoryByCategoryId(subCategoryId: number) {
    this.apiService
      .getSubSubCategoryByCategoryId(subCategoryId, this.themingService.shopId)
      .then((data: any) => {
        this.subSubCategories = data.subSubCategories;
        console.log(this.subSubCategories);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });
  }

  getIndustry() {
    this.apiService
      .getIndustries()
      .then((data: any) => {
        this.industryTypes = data.industries;
        console.log(this.industryTypes);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getSuppliers() {
    this.apiService
      .getSuppliers(this.themingService.shopId)
      .then((data: any) => {
        this.suppliers = data.suppliers;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getCollections() {
    this.apiService
      .getCollections(this.themingService.shopId)
      .then((data: any) => {
        this.collections = data.collections;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  changeName(event: Event) {
    const element = event.target as HTMLInputElement;
    this.product.product.productName = element.value;
  }

  changeCategory(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.product.product.productCategoryId = parseInt(element.value);
  }

  changeIndustry(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.product.product.productIndustryTypeId = parseInt(element.value);
  }

  changeSupplier(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.product.product.productSupplierId = parseInt(element.value);
  }

  changeCollection(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.product.product.productCollectionId = parseInt(element.value);
  }

  changeBaseCost(event: Event) {
    const element = event.target as HTMLInputElement;
    if (parseFloat(element.value) >= 0) {
      this.product.product.productBaseCost = parseFloat(element.value);
    } else {
      this.product.product.productBaseCost = 0;
    }
    this.calculateFinalPrice();
  }

  changeTax(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.product.product.productTaxId = parseFloat(element.value);
    this.calculateFinalPrice();
  }

  changeSellPrice(event: Event) {
    const element = event.target as HTMLInputElement;
    if (parseFloat(element.value) >= 0) {
      this.product.product.productSellPrice = parseFloat(element.value);
    } else {
      this.product.product.productSellPrice = 0;
    }
  }

  changeDiscountPrice(event: Event) {
    const element = event.target as HTMLInputElement;
    if (parseFloat(element.value) >= 0) {
      this.sale = parseFloat(element.value) * 0.01;
    } else {
      this.sale = 0;
    }
  }

  calculateFinalPrice(){
    if(this.product.product.productTaxId == 1){
      this.product.product.productSellPrice = this.product.product.productBaseCost + (this.product.product.productBaseCost * 0.13);
    }
  }

  revenueValue() {
    let revenue = this.product.product.productSellPrice - this.product.product.productBaseCost;
    return revenue.toFixed(2);
  }

  discountPrice() {
    this.dicountPrice = 0;
    if(this.sale > 0) {
      this.dicountPrice = this.product.product.productSellPrice - this.sale * this.product.product.productSellPrice;
    }
    return this.dicountPrice;
  }

  calculateDiscountPercentage(){
    let discountPercentage = 0;
    if(this.product.product.productDiscountPrice > 0){
      discountPercentage = (this.product.product.productDiscountPrice / this.product.product.productSellPrice) * 100;
    }
    //console.log(discountPercentage);
    return discountPercentage;
  }



  variablesHidden(event: Event) {
    const element = event.target as HTMLInputElement;
    this.areVariablesChecked = element.checked;
    if (this.areVariablesChecked == true) {
      this.hiddenVar1 = !this.areVariablesChecked;
    } else {
      this.hiddenVar1 = !this.areVariablesChecked;
      this.hiddenVar2 = !this.areVariablesChecked;
      this.hiddenVar3 = !this.areVariablesChecked;
    }

    this.displayItemsTable = false;
  }

  addInput(idParent: string, idBtn: string, counter: number) {
    if (counter < 10 && this.areVariablesChecked == true) {
      const parent = document.getElementById(idParent);
      counter += 1;

      const input = document.createElement('input');
      const container = document.createElement('div');
      const element = document.querySelector('.container') as HTMLElement;

      const attrs = element.getAttributeNames().reduce((acc, name) => {
        return { ...acc, [name]: element.getAttribute(name) };
      }, {});

      // 👇️ {id: 'blue', 'data-id': 'example', class: 'box'}
      //console.log(attrs);

      const emulateViewId = Object.keys(attrs)[0];

      //console.log(emulateViewId);

      input.setAttribute(emulateViewId, '');
      input.setAttribute('type', 'text');
      input.setAttribute(
        'class',
        'form-control variant-value variantValueInput ' + idParent
      );
      input.setAttribute('placeholder', 'Ingrese el valor');
      input.setAttribute('value', '');
      input.setAttribute('id', 'product_variable_value' + counter + idParent);

      container.setAttribute(emulateViewId, '');
      container.setAttribute(
        'id',
        'product_variable_container' + counter + idParent
      );
      container.className = 'value-container';
      parent?.appendChild(container);
      container.appendChild(input);

      this.addDeleteInput(
        idParent,
        container,
        'product_variable_value' + counter + idParent,
        counter,
        idBtn
      );
      this.disabledAddInputBtn(idBtn, counter);
    }
    return counter;
  }


  addDeleteInput(
    idParent: string,
    container: any,
    idInput: string,
    counter: number,
    addInputBtn: string
  ) {
    const parent = document.getElementById(idParent);
    const btn = document.createElement('button');
    const icon = document.createElement('i');
    const element = document.querySelector('.container') as HTMLElement;

    const attrs = element.getAttributeNames().reduce((acc, name) => {
      return { ...acc, [name]: element.getAttribute(name) };
    }, {});

    // 👇️ {id: 'blue', 'data-id': 'example', class: 'box'}
    //console.log(attrs);

    const emulateViewId = Object.keys(attrs)[0];

    //console.log(emulateViewId);

    btn.setAttribute(emulateViewId, '');
    btn.setAttribute('type', 'button');
    btn.className =
      'btn btn-outline-secondary variantValueInput delVariantBtn small-btn cancel-btn';
    btn.setAttribute('id', 'deleteVarBtn' + counter + idParent);
    btn.appendChild(icon);
    icon.setAttribute(emulateViewId, '');
    icon.setAttribute('data-feather', 'trash');

    icon.className = 'feather';

    btn.addEventListener('click', () => {
      this.deleteInput(
        idParent,
        container,
        idInput,
        'deleteVarBtn' + counter + idParent,
        addInputBtn
      );
    });
    container.appendChild(btn);
    feather.replace();
  }

  deleteInput(
    idParent: string,
    container: any,
    idInput: string,
    idBtn: string,
    addInputBtn: string
  ) {
    let counter = this.identifyCounter(idParent);
    if (counter > 0) {
      const parent = document.getElementById(idParent);
      const child = document.getElementById(idInput) as HTMLElement;
      const btn = document.getElementById(idBtn) as HTMLElement;

      container.removeChild(child);
      container.removeChild(btn);
      parent?.removeChild(container);
    }
    if (idParent === 'formVar1') {
      counter = this.counterVariableInputs1 -= 1;
    } else if (idParent === 'formVar2') {
      counter = this.counterVariableInputs2 -= 1;
    } else {
      counter = this.counterVariableInputs3 -= 1;
    }

    this.hideBtn('delVariantBtn', counter, idParent);
    this.disabledAddInputBtn(addInputBtn, counter);
  }

  disabledAddInputBtn(idBtn: string, counter: number) {
    const button = document.getElementById(idBtn) as HTMLButtonElement;
    if (counter === 10) {
      button.setAttribute('disabled', '');
    } else {
      button.removeAttribute('disabled');
    }
  }

  hideBtn(className: string, counter: number, parentId: string) {
    const elements = document.getElementsByClassName(className);
    let btn: any;
    let i = -1;

    if (counter === 1) {
      do {
        i++;
        if (elements[i].parentElement?.id == parentId) {
          btn = elements[i] as HTMLButtonElement;
          btn.setAttribute('hidden', '');
        }
      } while (elements[i].parentElement?.id !== parentId);
    }
  }

  identifyCounter(idParent: string) {
    if (idParent === 'formVar1') {
      return this.counterVariableInputs1;
    } else if (idParent === 'formVar2') {
      return this.counterVariableInputs2;
    } else {
      return this.counterVariableInputs3;
    }
  }

  showBtn(className: string, counter: number, parentId: string) {
    const elements = document.getElementsByClassName(className);
    let btn: any;
    let i = -1;

    if (counter > 1) {
      do {
        i++;
        if (elements[i].parentElement?.id == parentId) {
          btn = elements[i] as HTMLButtonElement;
          btn.removeAttribute('hidden');
        }
      } while (elements[i].parentElement?.id !== parentId);
    }
  }

  varBlock1() {
    this.hiddenVar1 = true;
    document.querySelectorAll('.variantValueInput');

    this.areVariablesActive();
  }

  varBlock2() {
    this.hiddenVar2 = true;

    this.areVariablesActive();
  }

  varBlock3() {
    this.hiddenVar3 = true;

    this.areVariablesActive();
  }

  areVariablesActive() {
    if (
      this.hiddenVar1 === true &&
      this.hiddenVar2 === true &&
      this.hiddenVar3 === true
    ) {
      this.areVariablesChecked = false;
      this.displayItemsTable = false;
    }
  }

  removeElementsByClass(
    className: string,
    counter: number,
    addInputBtn: string
  ) {
    const elements = document.getElementsByClassName(className);
    counter -= elements.length / 2;
    while (elements.length > 0) {
      elements[0].parentNode?.removeChild(elements[0]);
    }

    this.disabledAddInputBtn(addInputBtn, counter);

    return counter;
  }

  showVariables() {
    let counter = 0;
    let parentId = '';
    if (this.hiddenVar1 === false) {
      if (this.hiddenVar2 === false) {
        this.hiddenVar3 = false;
        parentId = 'formVar3';
        counter = this.addInput(
          'formVar3',
          'valBtn3',
          this.counterVariableInputs3
        );
        this.counterVariableInputs3 = counter;
      } else if (this.hiddenVar3 === false) {
        this.hiddenVar2 = false;
        parentId = 'formVar2';
        counter = this.addInput(
          'formVar2',
          'valBtn2',
          this.counterVariableInputs2
        );
        this.counterVariableInputs2 = counter;
      } else {
        this.hiddenVar2 = false;
        parentId = 'formVar2';
        counter = this.addInput(
          'formVar2',
          'valBtn2',
          this.counterVariableInputs2
        );
        this.counterVariableInputs2 = counter;
      }
    } else {
      this.hiddenVar1 = false;
      parentId = 'formVar1';
      counter = this.addInput(
        'formVar1',
        'valBtn1',
        this.counterVariableInputs1
      );
      this.counterVariableInputs1 = counter;
    }
    this.hideBtn('delVariantBtn', counter, parentId);
  }

  variableButtonDisabled() {
    let buttonDisabled: boolean = false;
    if (
      this.hiddenVar1 === false &&
      this.hiddenVar2 === false &&
      this.hiddenVar3 === false
    ) {
      buttonDisabled = true;
    }
    return buttonDisabled;
  }

  enableItemTable() {
    this.displayItemsTable = true;
  }

  fillVariantArrays() {
    this.variantGroupCombination1.length = 0;
    this.variantGroupCombination2.length = 0;
    this.variantGroupCombination3.length = 0;

    if (this.counterVariableInputs1 > 0) {
      const elements = document.getElementsByClassName('formVar1');
      const typeElement = document.getElementById(
        'variantTypes1'
      ) as HTMLInputElement;

      this.variantType1.variantTypeName = typeElement.value;

      for (let i = 0; i < this.counterVariableInputs1; i++) {
        let input = elements[i] as HTMLInputElement;

        this.variantGroup1Values.push({
          variantValueName: input.value,
          variantValuePosition: i,
        });
      }

      this.variantType1.variantValues = this.variantGroup1Values;
    }

    if (this.counterVariableInputs2 > 0) {
      const elements = document.getElementsByClassName('formVar2');
      const typeElement = document.getElementById(
        'variantTypes2'
      ) as HTMLInputElement;

      this.variantType2.variantTypeName = typeElement.value;

      for (let i = 0; i < this.counterVariableInputs2; i++) {
        let input = elements[i] as HTMLInputElement;

        this.variantGroup2Values.push({
          variantValueName: input.value,
          variantValuePosition: i,
        });
      }

      this.variantType2.variantValues = this.variantGroup2Values;
    }

    if (this.counterVariableInputs3 > 0) {
      const elements = document.getElementsByClassName('formVar3');
      const typeElement = document.getElementById(
        'variantTypes3'
      ) as HTMLInputElement;

      this.variantType3.variantTypeName = typeElement.value;

      for (let i = 0; i < this.counterVariableInputs3; i++) {
        let input = elements[i] as HTMLInputElement;

        this.variantGroup3Values.push({
          variantValueName: input.value,
          variantValuePosition: i,
        });
      }

      this.variantType3.variantValues = this.variantGroup3Values;
    }
  }

  fillVariantArraysToGenerateItems() {
    if (this.counterVariableInputs1 > 0) {
      const elements = document.getElementsByClassName('formVar1');
      const typeElement = document.getElementById('variantTypes1');

      for (let i = 0; i < this.counterVariableInputs1; i++) {
        let input = elements[i] as HTMLInputElement;
        let typeInput = typeElement as HTMLInputElement;

        //console.log(typeInput.value);

        this.variantGroupCombination1.push({
          type: typeInput.value,
          value: input.value,
        });
      }
    }

    if (this.counterVariableInputs2 > 0) {
      const elements = document.getElementsByClassName('formVar2');
      const typeElement = document.getElementById('variantTypes2');

      for (let i = 0; i < this.counterVariableInputs2; i++) {
        let input = elements[i] as HTMLInputElement;
        let typeInput = typeElement as HTMLInputElement;

        this.variantGroupCombination2.push({
          type: typeInput.value,
          value: input.value,
        });
      }
    }

    if (this.counterVariableInputs3 > 0) {
      const elements = document.getElementsByClassName('formVar3');
      const typeElement = document.getElementById('variantTypes3');

      for (let i = 0; i < this.counterVariableInputs3; i++) {
        let input = elements[i] as HTMLInputElement;
        let typeInput = typeElement as HTMLInputElement;

        this.variantGroupCombination3.push({
          type: typeInput.value,
          value: input.value,
        });
      }
    }

    this.fillCombinationsArray();
  }


  fillCombinationsArray() {
    let currentCombinations = <any[]>[];

    if (
      this.hiddenVar1 === false &&
      this.hiddenVar2 === false &&
      this.hiddenVar3 === false
    ) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination1.length; i++) {
        for (let j = 0; j < this.variantGroupCombination2.length; j++) {
          for (let k = 0; k < this.variantGroupCombination3.length; k++) {
            currentCombinations[r] =
              this.variantGroupCombination1[i].value +
              this.variantGroupCombination2[j].value +
              this.variantGroupCombination3[k].value;
            r++;
          }
        }
      }
    } else if (this.hiddenVar1 === false && this.hiddenVar2 === false) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination1.length; i++) {
        for (let j = 0; j < this.variantGroupCombination2.length; j++) {
          currentCombinations[r] =
            this.variantGroupCombination1[i].value +
            this.variantGroupCombination2[j].value;
          r++;
        }
      }
    } else if (this.hiddenVar1 === false && this.hiddenVar3 === false) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination1.length; i++) {
        for (let j = 0; j < this.variantGroupCombination3.length; j++) {
          currentCombinations[r] =
            this.variantGroupCombination1[i].value +
            this.variantGroupCombination3[j].value;
          r++;
        }
      }
    } else if (this.hiddenVar2 === false && this.hiddenVar3 === false) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination2.length; i++) {
        for (let j = 0; j < this.variantGroupCombination3.length; j++) {
          currentCombinations[r] =
            this.variantGroupCombination2[i].value +
            this.variantGroupCombination3[j].value;
          r++;
        }
      }
    } else if (this.hiddenVar1 === false) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination1.length; i++) {
        currentCombinations[r] = this.variantGroupCombination1[i].value;
        r++;
      }
    } else if (this.hiddenVar2 === false) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination2.length; i++) {
        currentCombinations[r] = this.variantGroupCombination2[i].value;
        r++;
      }
    } else {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination3.length; i++) {
        currentCombinations[r] = this.variantGroupCombination3[i].value;
        r++;
      }
    }

    this.combinations = currentCombinations;

    //console.log(this.combinations);
    this.generateCombinationsList(this.combinations);
    this.fillCombinationsArrayForItemValidations();
  }


  generateCombinationsList(currentCombinations: any[]) {
    let listBody: any;
    listBody = document.getElementById('variantsCombinationList');
    const element = document.querySelector('.container') as HTMLElement;

    const attrs = element.getAttributeNames().reduce((acc, name) => {
      return { ...acc, [name]: element.getAttribute(name) };
    }, {});

    // 👇️ {id: 'blue', 'data-id': 'example', class: 'box'}
    //console.log(attrs);

    const emulateViewId = Object.keys(attrs)[0];

    //console.log(emulateViewId);

    $(listBody).empty();

    for (let i = 0; i < currentCombinations.length; i++) {
      let row = listBody.insertRow();
      row.setAttribute(emulateViewId, '');

      let cellName = row.insertCell();
      cellName.setAttribute(emulateViewId, '');

      let cellPrice = row.insertCell();
      cellPrice.setAttribute(emulateViewId, '');

      let cellStock = row.insertCell();
      cellStock.setAttribute(emulateViewId, '');

      let cellCode = row.insertCell();
      cellCode.setAttribute(emulateViewId, '');

      let priceInput = document.createElement('input');
      priceInput.setAttribute(emulateViewId, '');

      let stockInput = document.createElement('input');
      stockInput.setAttribute(emulateViewId, '');

      let codeInput = document.createElement('input');
      codeInput.setAttribute(emulateViewId, '');

      priceInput.type = 'number';
      priceInput.className = 'form-control';
      priceInput.id = 'price' + i;
      priceInput.value = this.product.sellPrice.toString();

      stockInput.type = 'number';
      stockInput.className = 'form-control';
      stockInput.id = 'stock' + i;
      stockInput.value = '10';

      codeInput.type = 'text';
      codeInput.className = 'form-control';
      codeInput.id = 'code' + i;
      codeInput.value = this.product.code + i;

      cellName.innerHTML = currentCombinations[i];
      cellPrice.appendChild(priceInput);
      cellStock.appendChild(stockInput);
      cellCode.appendChild(codeInput);
    }
  }

  fillCombinationsArrayForItemValidations() {
    let combinationsForItems = <any[]>[];

    if (
      this.hiddenVar1 === false &&
      this.hiddenVar2 === false &&
      this.hiddenVar3 === false
    ) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination1.length; i++) {
        for (let j = 0; j < this.variantGroupCombination2.length; j++) {
          for (let k = 0; k < this.variantGroupCombination3.length; k++) {
            combinationsForItems[r] = this.combinationsForItems.push({
              variant1: this.variantGroupCombination1[i].value,
              variant2: this.variantGroupCombination2[j].value,
              variant3: this.variantGroupCombination3[k].value,
            });
            r++;
          }
        }
      }
    } else if (this.hiddenVar1 === false && this.hiddenVar2 === false) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination1.length; i++) {
        for (let j = 0; j < this.variantGroupCombination2.length; j++) {
          combinationsForItems[r] = this.combinationsForItems.push({
            variant1: this.variantGroupCombination1[i].value,
            variant2: this.variantGroupCombination2[j].value,
            variant3: null,
          });
          r++;
        }
      }
    } else if (this.hiddenVar1 === false && this.hiddenVar3 === false) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination1.length; i++) {
        for (let j = 0; j < this.variantGroupCombination3.length; j++) {
          combinationsForItems[r] = this.combinationsForItems.push({
            variant1: this.variantGroupCombination1[i].value,
            variant2: null,
            variant3: this.variantGroupCombination3[j].value,
          });
          r++;
        }
      }
    } else if (this.hiddenVar2 === false && this.hiddenVar3 === false) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination2.length; i++) {
        for (let j = 0; j < this.variantGroupCombination3.length; j++) {
          combinationsForItems[r] = this.combinationsForItems.push({
            variant1: null,
            variant2: this.variantGroupCombination2[i].value,
            variant3: this.variantGroupCombination3[j].value,
          });
          r++;
        }
      }
    } else if (this.hiddenVar1 === false) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination1.length; i++) {
        combinationsForItems[r] = this.combinationsForItems.push({
          variant1: this.variantGroupCombination1[i].value,
          variant2: null,
          variant3: null,
        });
        r++;
      }
    } else if (this.hiddenVar2 === false) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination2.length; i++) {
        combinationsForItems[r] = this.combinationsForItems.push({
          variant1: null,
          variant2: this.variantGroupCombination2[i].value,
          variant3: null,
        });
        r++;
      }
    } else {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination3.length; i++) {
        combinationsForItems[r] = this.combinationsForItems.push({
          variant1: null,
          variant2: null,
          variant3: this.variantGroupCombination3[i].value,
        });
        r++;
      }
    }

    //console.log('Items combination', this.combinationsForItems);
  }


  determineCombinationsLength() {
    if (this.counterVariableInputs1 > 0) {
      if (this.counterVariableInputs2 > 0 && this.counterVariableInputs3 > 0) {
        this.combinations.length =
          this.counterVariableInputs1 *
          this.counterVariableInputs2 *
          this.counterVariableInputs3;
      } else if (this.counterVariableInputs2 > 0) {
        this.combinations.length =
          this.counterVariableInputs1 * this.counterVariableInputs2;
      } else if (this.counterVariableInputs3 > 0) {
        this.combinations.length =
          this.counterVariableInputs1 * this.counterVariableInputs3;
      } else {
        this.combinations.length = this.counterVariableInputs1;
      }
    } else if (this.counterVariableInputs2 > 0) {
      if (this.counterVariableInputs3 > 0) {
        this.combinations.length =
          this.counterVariableInputs2 * this.counterVariableInputs3;
      } else {
        this.combinations.length = this.counterVariableInputs2;
      }
    } else {
      this.combinations.length = this.counterVariableInputs3;
    }
  }

  /**
   * The function concatenates the names of variant values of an item and returns the resulting string,
   * or returns the item ID if there are no variant values.
   * @param {any} item - The parameter "item" is of type "any", which means it can be any data type
   * (string, number, object, etc.). It is used as input to the function "objectValueJoin".
   * @returns The function `objectValueJoin` takes an argument `item` and returns a string. If the
   * `item` does not have a property `variantValues`, then the function returns the `itemId` property
   * of the `item`. Otherwise, the function reduces the `variantValues` array of the `item` to a single
   * string by concatenating the `variantValueName` property of each element,
   */
  objectValueJoin(item: any): string{
    if(!item.variantValues || item.variantValues.length === 0){
      return item.itemId
    }
    let data = "";
    item.variantValues.forEach((variantValue: any) => {
      data+=" "+variantValue;
    });
    return data
  }
}
