import { Component, Input, OnInit } from '@angular/core';
import { input } from 'aws-amplify';
import * as feather from 'feather-icons';

@Component({
  selector: 'app-option-box',
  templateUrl: './option-box.component.html',
  styleUrls: ['./option-box.component.scss'],
})
export class OptionBoxComponent implements OnInit {
  @Input() title!: string;

  @Input() icon!: string;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    feather.replace();
  }
}
