

<div class="container">
  <div class="container-inventory">
    <h1 class="title">Plan Actual</h1>
    <div class="form-card">
    <div class="header">
      <h3 class="store-name">{{ shopTheme[0].shopName }}</h3>
      <img class="store-logo" src="{{ shopTheme[0].shopLogoLarge }}" alt="Logo de Tienda" />
    </div>
    <div [ngClass]="['plan', planClass]">
      <div class="label">
        <strong>Tu Plan Actual</strong>
      </div>
      <div class="plan-container">
        <svg width="46" height="33" viewBox="0 0 46 33" fill="none" xmlns="http://www.w3.org/2000/svg"
          *ngIf="shopPlan == 'Basic'">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M21.724 0.389338C22.4965 -0.129779 23.503 -0.129779 24.2756 0.389338L44.9753 14.2984C45.6152 14.7284 45.9995 15.4522 45.9995 16.2273C45.9995 17.0024 45.6152 17.7262 44.9753 18.1561L24.2756 32.0652C23.503 32.5843 22.4965 32.5843 21.724 32.0652L1.02418 18.1561C0.384329 17.7262 0 17.0024 0 16.2273C0 15.4522 0.384329 14.7284 1.02418 14.2984L21.724 0.389338ZM6.44632 16.2273L22.9998 27.3503L39.5532 16.2273L22.9998 5.10429L6.44632 16.2273Z"
            fill="currentColor" />
        </svg>
        <svg width="38" height="33" viewBox="0 0 38 33" fill="none" xmlns="http://www.w3.org/2000/svg"
          *ngIf="shopPlan == 'Advanced'">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M17.9461 0.307908C18.5843 -0.102636 19.4157 -0.102636 20.0539 0.307908L37.1537 11.3079C37.6823 11.6479 37.9998 12.2204 37.9998 12.8333C37.9998 13.4463 37.6823 14.0187 37.1537 14.3588L20.0539 25.3588C19.4157 25.7693 18.5843 25.7693 17.9461 25.3588L0.846263 14.3588C0.317691 14.0187 0.000201911 13.4463 0.000201911 12.8333C0.000201911 12.2204 0.317691 11.6479 0.846263 11.3079L17.9461 0.307908ZM5.32542 12.8333L19 21.6299L32.6746 12.8333L19 4.03673L5.32542 12.8333ZM0.319303 19.1497C0.901366 18.3072 2.08101 18.0796 2.9541 18.6412L19 28.9633L35.0459 18.6412C35.919 18.0796 37.0986 18.3072 37.6807 19.1497C38.2628 19.9922 38.0268 21.1304 37.1537 21.6921L20.0539 32.6921C19.4157 33.1026 18.5843 33.1026 17.9461 32.6921L0.846263 21.6921C-0.0268328 21.1304 -0.26276 19.9922 0.319303 19.1497Z"
            fill="currentColor" />
        </svg>
        <svg width="30" height="33" viewBox="0 0 30 33" fill="none" xmlns="http://www.w3.org/2000/svg"
          *ngIf="shopPlan == 'Corporate'">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M14.168 0.251924C14.6718 -0.0839748 15.3282 -0.0839748 15.832 0.251924L29.3319 9.25192C29.7492 9.53012 29.9998 9.99847 29.9998 10.5C29.9998 11.0015 29.7492 11.4699 29.3319 11.7481L15.832 20.7481C15.3282 21.084 14.6718 21.084 14.168 20.7481L0.668102 11.7481C0.250808 11.4699 0.000159406 11.0015 0.000159406 10.5C0.000159406 9.99847 0.250808 9.53012 0.668102 9.25192L14.168 0.251924ZM4.20428 10.5L15 17.6972L25.7957 10.5L15 3.30278L4.20428 10.5ZM0.252081 15.668C0.711605 14.9787 1.6429 14.7924 2.33219 15.2519L15 23.6972L27.6678 15.2519C28.3571 14.7924 29.2884 14.9787 29.7479 15.668C30.2074 16.3572 30.0212 17.2885 29.3319 17.7481L15.832 26.7481C15.3282 27.084 14.6718 27.084 14.168 26.7481L0.668102 17.7481C-0.0211838 17.2885 -0.207442 16.3572 0.252081 15.668ZM0.252081 21.668C0.711605 20.9787 1.6429 20.7924 2.33219 21.2519L15 29.6972L27.6678 21.2519C28.3571 20.7924 29.2884 20.9787 29.7479 21.668C30.2074 22.3572 30.0212 23.2885 29.3319 23.7481L15.832 32.7481C15.3282 33.084 14.6718 33.084 14.168 32.7481L0.668102 23.7481C-0.0211838 23.2885 -0.207442 22.3572 0.252081 21.668Z"
            fill="currentColor" />
        </svg>
        <h2>{{ shopPlan }}</h2>
      </div>
    </div>

    <div class="form-card visually-hidden">
      <div class="label">
        <strong>Información de la Tienda</strong>
      </div>
      <div class="info-container">
        <div class="data-container">
          <label class="data-label" for="">País</label>
          <output class="data-output">{{ shopTheme[0].country }}</output>
        </div>
        <div class="data-container">
          <label class="data-label" for="">Moneda</label>
          <output class="data-output">{{ shopTheme[0].currencyCode }}</output>
        </div>
        <div class="data-container">
          <label class="data-label" for="">Ubicación Principal</label>
          <output class="data-output">{{ shopTheme[0].locations }}</output>
        </div>
        <div class="data-container">
          <label class="data-label" for="">Correo Electrónico</label>
          <output class="data-output">{{ shopTheme[0].mail }}</output>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>

