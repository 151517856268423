import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService, alertsAnimation } from 'src/app/auth.service';
import { ThemingService, ShopTheming } from 'src/app/theming.service';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/settings.service';

@Component({
  selector: 'app-add-staff',
  templateUrl: './add-staff.component.html',
  styleUrls: ['./add-staff.component.scss'],
  animations: [ alertsAnimation ]
})
export class AddStaffComponent implements OnInit {
  public shopTheme: ShopTheming[] = [];
  public permissionsFormGroup!: FormGroup;

  isException: boolean = false;
  exceptionMessage: String = '';
  isPasswordVisible: boolean = false;
  roles: any = [];
  loadModal:boolean = false;

  staffMembers: number = 0;
  staffMaxMembers: number = 2;

  public signUpForm!: FormGroup;
  user_role: string = 'User';
  configAcces: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private themingService: ThemingService,
    private settingsService: SettingsService) {
      this.shopTheme = this.themingService.shopTheming;

    /* Creating a form group with three controls. */
    this.signUpForm = this.fb.group({
      "givenName": ['',[
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('[a-zA-ZÀ-ÿ][a-zA-ZÀ-ÿ ]+'),
      ]],
      "familyName": ['',[
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('[a-zA-ZÀ-ÿ][a-zA-ZÀ-ÿ ]+'),
      ]],
      "givenEmail": ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
      ]],
      "givenPassword": ['', [
        Validators.required,
        Validators.minLength(8),
      ]],
      permissions: this.fb.group({
        home: [false],
        sales: [false],
        cashRegisters: [false],
        products: [false],
        giftCards: [false],
        coupons: [false],
        clients: [false],
        calendar: [false],
        config: [false],
        posAccess: [false],
        //config permissions
        generalInfo:[false],
        userRoles:[false],
        categories:[false],
        collections:[false],
        supliers:[false],
        shopPoints:[false],
        paymentMethods:[false],
        themingService:[false],
      })
    });
    this.permissionsFormGroup = this.signUpForm.get('permissions') as FormGroup;
  }

  get signUpFormValidation() {
    return this.signUpForm.controls;
  }

  ngOnInit(): void {
    this.signUpForm.get('permissions.config')?.valueChanges.subscribe((value: boolean) => {
      this.configAcces = value; 
    });
    this.authService.listGroups().then((groupsData: any)=>{
      this.roles = groupsData.Groups.filter((data:any) => data.GroupName != 'Owner');
    }).then(()=>{
      let currentPlan = this.shopTheme[0].currentContract;
      switch (currentPlan) {
        case 'Basic':
            this.staffMaxMembers = 2;
            break;
          case 'Advanced':
            this.staffMaxMembers = 5;
            break;
          case 'Corporate':
            this.staffMaxMembers = 15;
            break;
          default:
            this.staffMaxMembers = 2;
            break;
        }
    }).then(()=>{
      this.loadMaxMembers()
    });
  }

  loadMaxMembers(){
    this.authService.getUsers().then((usersData: any)=>{
      this.staffMembers = usersData.length - 1;
    })
  }

  getPermissionsObject() {
    console.log(this.signUpForm.get('permissions')?.value);
    return this.signUpForm.get('permissions')?.value;
  }
  

  onSignUp(){
    if(this.staffMembers < this.staffMaxMembers){
      const permissions = JSON.stringify(this.getPermissionsObject()); 
  
      this.authService.createUserAdmin(
        this.signUpForm.value.givenName,
        this.signUpForm.value.familyName,
        this.signUpForm.value.givenEmail,
        this.user_role,
        this.signUpForm.value.givenPassword,
        permissions
      ).then(()=>{
        this.authService.adminAddUserToGroup(this.signUpForm.value.givenEmail, this.user_role)
        .then(()=>{
          this.isException = false;
          this.loadMaxMembers();
          this.signUpForm.reset();
          this.loadModal = true;
        }).catch((error)=>{
          this.isException = true;
          this.exceptionMessage = error.message;
        });
      }).catch((error: any)=>{
        console.log(error);
        this.isException = true;
        this.exceptionMessage =  this.authService.handleErrors(error.message + 'backoffice', "");
      });
    }else{
      this.isException = true;
      this.exceptionMessage = 'Haz alcanzado el límite de usuarios permitidos para tu plan.';
    }
  }

  onCreateAnotherUser(){
    this.signUpForm.reset();
    this.loadModal = false;
  }

  onClose(){
    this.router.navigate(['/users']);
  }

  /**
   * The function calls the setListingUsers() function in the settingsService
   */
  setListingUsers(): void {
    this.settingsService.setListingUsers();
  }
}
