import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
declare let $: any;

interface MenuOption {
  menuOptionId: number;
  menuOptionName: string;
  menuOptionUrl: string;
  categoryId: number;
  subCategoryId: number;
  subSubCategoryId: number;
  collectionId: number;
  menuOptionType: number;
  menuOptionPosition: number;
}

interface Menu {
  menuId: number;
  shopId: number;
  menuName: string;
  menuStatus: number;
  options: MenuOption[];
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Output() editMenuEvent = new EventEmitter<number>();
  menus: Menu[] = [];
  isCreatingMenu = false
  isListingMenu = true;
  isEditingMenu = false;
  loading = false;
  modalDelete = false;
  menuIdToDelete: any;
  editMenuId!: number;
  constructor(
    private apiService: ApiService,
    public themingService: ThemingService,
  ) { }

  ngOnInit(): void {
    this.getMenu();
  }

  cancelCreation() {
    this.isCreatingMenu= false;
    this.isListingMenu= true;
    this.getMenu();
  }

  cancelEdition(): void {
    this.isEditingMenu= false;
    this.isListingMenu= true;
    this.getMenu();
  }

  editMenu(menuId: number): void {
    this.isEditingMenu = true;
    this.isCreatingMenu = false;
    this.isListingMenu = false;
    this.editMenuId = menuId;
  }

  toggleMenuVisibility(creating: boolean) {
    this.isCreatingMenu = creating;
    this.isListingMenu = !creating;
    if (!creating) {
      this.getMenu();
    }
  }

  getMenu() {
    this.loading = true;
    this.apiService.getMenu(this.themingService.shopId)
      .then((response: any) => {
        const menuMap = new Map<number, Menu>();
        response.menu.forEach((item: any) => {
          if (!menuMap.has(item.menuId)) {
            menuMap.set(item.menuId, {
              menuId: item.menuId,
              shopId: item.shopId,
              menuName: item.menuName,
              menuStatus: item.menuStatus,
              options: []
            });
          }
          menuMap.get(item.menuId)?.options.push({
            menuOptionId: item.menuOptionId,
            menuOptionName: item.menuOptionName,
            menuOptionUrl: item.menuOptionUrl,
            categoryId: item.categoryId,
            subCategoryId: item.subCategoryId,
            subSubCategoryId: item.subSubCategoryId,
            collectionId: item.collectionId,
            menuOptionType: item.menuOptionType,
            menuOptionPosition: item.menuOptionPosition,
          });
        });

        this.menus = Array.from(menuMap.values());
        this.menus.forEach(menu => {
          menu.options.sort((a, b) => a.menuOptionPosition - b.menuOptionPosition);
        });
        this.loading = false;
      })
      .catch((error) => {
        console.error('Error fetching menu:', error);
      });
  }

  getMenuOptionValue(option: MenuOption): string {
    if (option.categoryId > 0) {
      return option.categoryId.toString();
    } else if (option.subCategoryId > 0) {
      return option.subCategoryId.toString();
    } else if (option.subSubCategoryId > 0) {
      return option.subSubCategoryId.toString();
    } else if (option.collectionId > 0) {
      return option.collectionId.toString();
    } else {
      return '';
    }
  }
  
  drop(event: CdkDragDrop<MenuOption[]>, menuItem: Menu) {
    moveItemInArray(menuItem.options, event.previousIndex, event.currentIndex);
    menuItem.options.forEach((option, index) => {
      option.menuOptionPosition = index + 1;
    });

    const positions = menuItem.options.reduce((acc, option) => {
      acc[option.menuOptionId] = option.menuOptionPosition;
      return acc;
    }, {} as { [key: number]: number });

    const updateData = {
      shopId: this.themingService.shopId,
      menuId: menuItem.menuId,
      positions: positions
    };
    this.updateMenuOptionsPositions(updateData);
  }

  toggleMenuStatus(menuItem: Menu) {
    if (menuItem.menuStatus === 1) {
      menuItem.menuStatus = 0;
    } else {
      this.menus.forEach(item => {
        item.menuStatus = 0;
      });
      menuItem.menuStatus = 1;
    }
    this.updateMenuStatus(menuItem.menuId, menuItem.menuStatus);
  }
  

  updateMenuStatus(menuId: number, status: number) {
    this.apiService.updateMenuStatus(status, menuId, this.themingService.shopId)
      .then(response => console.log('Status updated:', response))
      .catch(error => console.error('Error updating status:', error));
  }

  updateMenuOptionsPositions(updateData: { shopId: number, menuId: number, positions: { [key: number]: number } }) {
    this.apiService.updateMenuOptionsPositions(updateData)
      .then(response => console.log('Menu options positions updated:', response))
      .catch(error => console.error('Error updating menu options positions:', error));
  }

  deleteMenu(response: number){
    if(response){
      this.loading = true; 
      this.apiService.deleteMenu(this.themingService.shopId, this.menuIdToDelete)
      .then((data)=>{
        console.log("data: ", JSON.stringify(data));
        this.getMenu();
        $('#deleteMenuModal').modal('show');
        this.loading = false;
      })
      this.modalDelete = false;
    }else {
      this.modalDelete = false;
    }
  }

  confirmDelete(menuId: number) {
    this.menuIdToDelete = menuId;
    this.modalDelete = true;
 }


  closeModal(){
    $('#deleteMenuModal').modal('hide');
  }
}
