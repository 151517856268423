import { Injectable } from '@angular/core';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserPoolService {
  private readonly cognito = new CognitoIdentityServiceProvider({
    region: 'us-east-1',
    credentials: {
      accessKeyId: 'AKIA3ITLEVL4SUCPY25D',
      secretAccessKey: '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',
    },
  });

  private readonly groups = [
    {
      name: 'Owner',
      description:
        'Es el dueño de la tienda. Debe existir solamente uno por tienda y es el primer usuario a crear. Lo creamos nosotros (idealmente debería generarse automáticamente cuando el dueño crea la tienda desde el SITE, pero toda esta funcionalidad aún no está disponible)',
      precedence: 1,
    },
    {
      name: 'Admin',
      description:
        'Son los administradores, la aplicación va a variar su comportamiento si este rol está presente (TO DO)',
      precedence: 2,
    },
    {
      name: 'Vendor',
      description:
        'Son los vendedores, la aplicación va a variar su comportamiento si este rol está presente (TO DO)',
      precedence: 3,
    },
  ];

  private readonly backOfficeParams = {
    Policies: {
      PasswordPolicy: {
        TemporaryPasswordValidityDays: 365,
        MinimumLength: 6,
      },
    },
    DeletionProtection: 'ACTIVE',
    Schema: [
      {
        AttributeDataType: 'String',
        Name: 'email',
        Required: true,
      },
      {
        Name: 'user_role', //en core van otros
        AttributeDataType: 'String',
        DeveloperOnlyAttribute: false,
      },
    ],
    AutoVerifiedAttributes: ['email'],
    UsernameAttributes: ['email', 'phone_number'],
    VerificationMessageTemplate: {
      DefaultEmailOption: 'CONFIRM_WITH_CODE',
    },
    UserAttributeUpdateSettings: {
      AttributesRequireVerificationBeforeUpdate: ['email'],
    },

    MfaConfiguration: 'OPTIONAL',
    EmailConfiguration: {
      EmailSendingAccount: 'DEVELOPER',
      SourceArn:
        'arn:aws:ses:us-east-1:774392556281:identity/verificacion@mobydyg.com',
    },
    SmsConfiguration: {
      SnsCallerArn: 'arn:aws:iam::774392556281:role/snsa448b259120908-prod',
      ExternalId: 'mobydya448b259_role_external_id',
      SnsRegion: 'us-east-1',
    },
    UserPoolTags: {},
    AdminCreateUserConfig: {
      AllowAdminCreateUserOnly: true,
    },
    UsernameConfiguration: {
      CaseSensitive: false,
    },
    AccountRecoverySetting: {
      RecoveryMechanisms: [
        {
          Priority: 1,
          Name: 'verified_email',
        },
      ],
    },
  };

  private readonly clientCoreParams = {
    Policies: {
      PasswordPolicy: {
        MinimumLength: 6,
        RequireUppercase: false,
        RequireLowercase: false,
        RequireNumbers: false,
        RequireSymbols: false,
        TemporaryPasswordValidityDays: 365,
      },
    },
    DeletionProtection: 'ACTIVE',
    Schema: [
      {
        AttributeDataType: 'String',
        Name: 'email',
        Required: true,
      },
      {
        Name: 'nationalId',
        AttributeDataType: 'String',
        DeveloperOnlyAttribute: false,
      },
      {
        Name: 'shopId',
        AttributeDataType: 'Number',
        DeveloperOnlyAttribute: false,
      },
      {
        Name: 'stage',
        AttributeDataType: 'String',
        DeveloperOnlyAttribute: false,
      },
      {
        Name: 'usercart',
        AttributeDataType: 'String',
        DeveloperOnlyAttribute: false,
      },
    ],
    AutoVerifiedAttributes: ['email'],
    UsernameAttributes: ['email', 'phone_number'],
    VerificationMessageTemplate: {
      DefaultEmailOption: 'CONFIRM_WITH_CODE',
    },
    UserAttributeUpdateSettings: {
      AttributesRequireVerificationBeforeUpdate: ['email'],
    },
    MfaConfiguration: 'OPTIONAL',
    EmailConfiguration: {
      SourceArn:
        'arn:aws:ses:us-east-1:774392556281:identity/verificacion@mobydyg.com',
      EmailSendingAccount: 'DEVELOPER',
    },
    SmsConfiguration: {
      SnsCallerArn: 'arn:aws:iam::774392556281:role/snsa448b259120908-prod',
      ExternalId: 'mobydya448b259_role_external_id',
      SnsRegion: 'us-east-1',
    },
    AdminCreateUserConfig: {
      AllowAdminCreateUserOnly: false,
    },
    UsernameConfiguration: {
      CaseSensitive: false,
    },
    AccountRecoverySetting: {
      RecoveryMechanisms: [
        {
          Priority: 1,
          Name: 'verified_email',
        },
      ],
    },
  };

  constructor() {}

  
  /**
   * This function registers a back office pool and a client core pool with a given name, email, and
   * password.
   * @param {string} name - A string representing the name of the pool being registered.
   * @param {string} email - The email parameter is a string that represents the email address of the
   * user who is registering the pool.
   * @param {string} password - The password parameter is a string that represents the password that
   * will be used for the registration process.
   */
  async registerPool(name: string, email: string, password: string){
    await this.registerBackOfficePool(`${name}-backoffice-prod`, email, password);
    await this.registerClientCorePool(`${name}-client-prod`);
  }

  /**
   * This function registers a client core pool by creating a user pool with a given name and client
   * core parameters.
   * @param {string} name - The name of the client core pool that needs to be registered.
   */
  async registerClientCorePool(name: string) {
    await this.createUserPool(name, this.clientCoreParams);
  }

  /**
   * This function registers a new back office pool by creating a user pool, a user pool client, user
   * groups, and adding a user to a group.
   * @param {string} name - A string representing the name of the back office pool to be created.
   * @param {string} email - The email parameter is a string that represents the email address of the
   * user who will be added to the user group in the back office pool.
   * @param {string} password - A string representing the password for the user being added to the back
   * office pool.
   */
  async registerBackOfficePool(name: string, email: string, password: string) {
    try {
      const result = await this.createUserPool(name, this.backOfficeParams);
      await this.createUserPoolClient(
        result?.UserPool?.Id,
        result?.UserPool?.Name
      );
      for (const group of this.groups) {
        const { name, description, precedence } = group;
        await this.createUserGroup(
          result?.UserPool?.Id,
          name,
          description,
          precedence
        );
      }
      this.addUserToGroup(result?.UserPool?.Id, email, name, password);
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * This function adds a user to a group in a Cognito user pool and creates a temporary password for
   * the user.
   * @param {string | undefined} poolId - a string representing the ID of the user pool in Amazon
   * Cognito.
   * @param {string} username - The username of the user being added to the group.
   * @param {string} name - The name parameter is a string representing the name of the user being
   * added to the group.
   * @param {string} password - A string representing the temporary password for the user being
   * created.
   */
  async addUserToGroup(poolId: string | undefined, username: string, name: string, password: string) {
    const [group] = this.groups;
    const { name: groupName } = group;
    // const password = `${name.split('-')[0]}${new Date().getFullYear()}`;
    const adminCreateUserParams = {
      UserPoolId: poolId ?? '',
      Username: username,
      TemporaryPassword: password,
      UserAttributes: [
        { Name: 'email', Value: username },
        { Name: 'email_verified', Value: 'true' },
      ],
    };

    await this.cognito.adminCreateUser(adminCreateUserParams).promise();

    const addUserToGroupParams = {
      GroupName: groupName,
      UserPoolId: poolId ?? '',
      Username: username,
    };

    this.cognito.adminAddUserToGroup(addUserToGroupParams, (err, data) => {
      if (err) console.log(err, err.stack);
    });
  }

  /**
   * This function creates a user group in a Cognito user pool.
   * @param {string | undefined} poolId - The ID of the user pool in which the group will be created.
   * It is optional and can be undefined.
   * @param {string} groupName - The name of the user group that needs to be created.
   * @param {string} groupDescription - The groupDescription parameter is a string that describes the
   * purpose or characteristics of the user group being created. It can be used to provide additional
   * information about the group to help users understand its function.
   * @param {number} precedence - The `precedence` parameter is a number that determines the priority
   * of the user group in relation to other groups within the same user pool. User groups with lower
   * precedence values have higher priority than those with higher values. For example, a user group
   * with a precedence of 1 will have higher priority than
   */
  async createUserGroup(
    poolId: string | undefined,
    groupName: string,
    groupDescription: string,
    precedence: number
  ) {
    const createGroupParams = {
      GroupName: groupName,
      UserPoolId: poolId ?? '',
      Description: groupDescription,
      Precedence: precedence,
    };

    this.cognito.createGroup(createGroupParams, (err, data) => {
      if (err) console.log(err, err.stack);
    });
  }

  
  /**
   * This function creates a user pool client in AWS Cognito with the given ID and name.
   * @param {string | undefined} id - The ID of the user pool to which the client belongs. It is a
   * string type or undefined.
   * @param {string | undefined} name - The name of the user pool client that will be created. If the
   * name parameter is undefined, an empty string will be used instead.
   */
  async createUserPoolClient(id: string | undefined, name: string | undefined) {
    const params = {
      ClientName: name ?? '',
      UserPoolId: id ?? '',
      GenerateSecret: false,
    };

    this.cognito.createUserPoolClient(params, (err, data) => {
      if (err) console.log(err, err.stack);
    });
  }

  /**
   * This is an async function that creates a user pool with a given name and parameters using AWS
   * Cognito.
   * @param {string} name - A string representing the name of the user pool to be created.
   * @param {any} params - The `params` parameter is an object that contains additional parameters to
   * be passed to the `createUserPool` method of the `cognito` object. These parameters may include
   * options such as `policies`, `schema`, `aliasAttributes`, `autoVerifiedAttributes`, and more,
   * depending on the
   * @returns the result of the `createUserPool` method call if it is successful, and `null` if there
   * is an error.
   */
  async createUserPool(name: string, params: any) {
    try {
      const result = await this.cognito
        .createUserPool({ ...params, PoolName: name })
        .promise();
      return result;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * This function retrieves an array of user pools based on a given email address.
   * @param {string} email - The email parameter is a string representing the email address of a user.
   * The function retrieves a list of user pools that the user belongs to based on their email address.
   * @returns This function returns a Promise that resolves to an array of strings representing the
   * user pools that the given email belongs to.
   */
  async getUserPools(email: string): Promise<Array<string>> {
    const stage = environment.stage;
    const stores: any[] = []; //user pools de prod (meraki y optica)
    switch(stage){
      case 'prod':
        stores.push('us-east-1_lWxrLBMKP');
        stores.push('us-east-1_z6EnPTrkV');
        break;
      case 'dev':
        stores.push('us-east-1_4m4MNNwWx');
        break;
      default:
        break;
    }
    const shop: Array<string> = [];
    try {
      let userPools = (await this.cognito.listUserPools({MaxResults: 50}).promise()).UserPools;
      if(!userPools) return [];
      userPools = userPools
        .filter(item => item.Name?.toLowerCase().includes('backoffice') || stores.includes(item.Id ?? ''))
        .filter(item => item.Name?.toLowerCase().includes(stage) || stores.includes(item.Id ?? ''));
      console.log(stage, stores, userPools)
      for (const userPool of userPools) {
        const params = {
          UserPoolId: userPool.Id ?? ''
        };
        const users = await this.cognito.listUsers(params).promise();
        const { Users } = users;
        const exist = Users?.some(item => item.Attributes?.some(item => item.Name === 'email' && item.Value === email));
        if(exist && userPool.Id){
          shop.push(userPool.Id)
        }
      }
      
      return shop;
    } catch (error) {
      console.error('Error retrieving user pool groups:', error);
      return [];
    }
  }
}
