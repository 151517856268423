import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { ThemingService } from 'src/app/theming.service';
import { ApiService } from 'src/app/api.service'; 
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  @ViewChild('editor') editorElement!: ElementRef;
  modules = {};
  editor: any;
  privacyPolicyContent: string = ''; 

  loading = false;
  isEditingPrivacy: boolean = false;
 
  constructor(
    private appComponent: AppComponent,
    private themingService: ThemingService,
    private apiService: ApiService 
  ) {}

  ngOnInit(): void {
    this.appComponent.goToTop();
    const url = this.themingService.privacyPolicies;
    console.log("url privacy: ",url)
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    const self = this;
    xhr.onreadystatechange = function process() {
      if (xhr.readyState == 4 && xhr.status === 200) {
        self.privacyPolicyContent = xhr.responseText;
      }
    };
    xhr.send();
  }

  switchEditingPrivacy(){
    this.isEditingPrivacy = !this.isEditingPrivacy;
  }

  savePrivacyPolicy() {
    this.loading = true;
    console.log("html a subir",this.privacyPolicyContent)
    let stage = environment.stage == "test"? "dev": environment.stage; 
    this.apiService.uploadHtmlContent(`${this.themingService.shopId}/${stage}/config-files/policy-file.html`, this.privacyPolicyContent, {})
    .then((response: any) => {
      console.log('Changes saved successfully:', response);
      $('#successPolicyModal').modal('show');
    })
    .catch((error: any) => {
      console.error('Error saving changes:', error);
      $('#errorPolicyModal').modal('show');
    });
    this.switchEditingPrivacy();
  }

  closeModal(){
    $('#successPolicyModal').modal('hide');
    $('#errorPolicyModal').modal('hide');
  }
}
