<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>
<app-gift-card-thumbnail></app-gift-card-thumbnail>

<div class="container">
  <div class="container-giftcards">
    <div class="nav-tab-container">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="home-tab" [routerLink]="['/giftCardTypes']" routerLinkActive="active" role="tab" aria-controls="home" aria-selected="true">Tipos de Tarjeta de Regalo</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="home-tab" [routerLink]="['/giftCardItems']" routerLinkActive="active" role="tab" aria-controls="home" aria-selected="true">Tarjetas de Regalo</a>
        </li>
      </ul>
    </div>

    <div class="header">
      <h1 class="title">Tipos de Tarjetas de Regalo</h1>
      <button type="button" class="btn btn-outline-primary primary-btn" [routerLink]="['/giftCardTypes/create']">
        Crear 
      </button>
    </div>
    <div class="form-card">
      <div class="loading" *ngIf="loading"> 
        <div class="spinner-border" role="status"></div> 
      </div>
      <table *ngIf="!loading" class="table table-bordered table-hover"> 
        <thead>
          <th scope="col" class="table-start">Nombre</th>
          <th scope="col">Miniatura</th>
          <th scope="col">Monto</th>
          <th scope="col">Vigencia</th> 
          <th scope="col">Cantidad</th>
          <th scope="col">Editar</th>
          <th scope="col"class="table-end">Eliminar</th>
        </thead>
        <tbody>
          <tr *ngFor="let giftCard of giftCards">
            <td>{{ giftCard.name }}</td>
            <td>
              <img [src]="giftCard.images" alt="Imagen de la tarjeta de regalo">
            </td>
            <td class="vertical-amount-column">
              <ng-container *ngFor="let amount of giftCard.amount.split(',')">
                {{ amount }}
                <br>
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="giftCard.validMonths === 0; else showMonths">
                <span class="infinity-symbol">&#8734;</span>
              </ng-container>
              <ng-template #showMonths>
                {{ giftCard.validMonths }} {{ giftCard.validMonths === 1 ? 'mes' : 'meses' }}
              </ng-template>
            </td>            
            <td>
              {{ giftCard.stock === 999999 ? 'Ilimitada' : giftCard.stock }}
            </td>
            <td>
              <button type="button" class="btn btn-primary primary-btn" [routerLink]="['/giftCardTypes/', giftCard.giftCardTypeId]"> 
                Editar
              </button>
            </td>
            <td>
              <button class="btn btn-primary remove-btn" (click)="modalDelete = true; giftCardIdToDelete = giftCard.giftCardTypeId">
                Eliminar
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <app-confirmation-modal *ngIf="modalDelete" [title]="'¿Seguro que quiere eliminar esta tarjeta?'" [message]="'Al eliminar esta tarjeta se eliminaran los datos que esten relacionados a ella'"
    [confirmText]="'Eliminar'" [cancelText]="'Cancelar'" (response)="deleteGiftCardType($event)">
    > </app-confirmation-modal>
  
    <div class="modal delete-giftcard-confirm-modal" id="deleteGiftCardModal" tabindex="-1">
      <div class="modal-dialog giftcard-modal-dialog" role="document">
        <div class="modal-content giftcard-modal-content">
          <div class="modal-body giftcard-modal-body">
            <h3 class="text-center">¡Éxito!</h3>
            <p class="text-center">¡La tarjeta fue eliminada exitosamente!</p>
          </div>
          <div class="modal-buttons">
            <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>