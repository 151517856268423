import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
import { ThemingService } from 'src/app/theming.service';
declare var $: any;

@Component({
  selector: 'app-create-gift-card-items',
  templateUrl: './create-gift-card-items.component.html',
  styleUrls: ['./create-gift-card-items.component.scss']
})
export class CreateGiftCardItemsComponent implements OnInit {

  myForm!: FormGroup;
  amountFormArray!: FormArray;
  giftCards: any[] = [];
  loading: boolean = false;
  selectedGiftCardName: string = "";
  selectedGiftCard: any;
  selectedAmount: any;

  giftCard = {
    status: 2, 
    giftCardEmail: "",
    giftCardMessage: '',
    giftCardIsVirtual: 1,
  };

  saveGiftCardItem = false;
  amountsArray: any;
  loadingSaleStateChange!: boolean;
  cardSelected: boolean = false;
  
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    public themingService: ThemingService,
    public appComponent: AppComponent,
  ) {
    this.amountFormArray = this.formBuilder.array([this.formBuilder.control(0)]);
    this.myForm = this.formBuilder.group({
      giftCardName: [''],
      giftCardValidMonths: [0],
      giftCardStock: [999999],
      giftCardStatus: [1],
      amount: this.amountsArray = [0],
      giftCardEmail: [''],
      giftCardMessage: [''],
    });}

  ngOnInit(): void {
    this.getGiftCardTypes();
    this.onGiftCardNameChange({ target: { value: this.selectedGiftCardName } });
  }

  async getGiftCardTypes() {
    this.loading = true;
    await this.apiService
      .getGiftCardTypes(
        this.themingService.shopId)
      .then((data: any) => {
        console.log(data);
        this.giftCards = data;
      });
    this.loading = false;
  }

  onGiftCardNameChange(event: any): void {
    const selectedGiftCardName = event.target.value;
    const selectedGiftCard = this.giftCards.find(card => card.name === selectedGiftCardName);
    this.cardSelected = !!this.selectedGiftCardName; 
    if (selectedGiftCard) {
      this.selectedGiftCard = { ...selectedGiftCard };
      if (this.selectedGiftCard.amount) {
        this.amountsArray = this.selectedGiftCard.amount.split(',').map((amount: string) => +amount.trim());
      } else {
        this.amountsArray = [];
      }
    } else {
      this.selectedAmount = ''; 
    }
  }
  
  ChangeGiftCardMessage(event: Event) {
    const element = event.target as HTMLInputElement;
    this.giftCard.giftCardMessage = element.value;
  }

  ChangeGiftCardEmail(event: Event) {
    const element = event.target as HTMLInputElement;
    this.giftCard.giftCardEmail = element.value;
  }

  onAmountChange(event: Event): void {
    const element = event.target as HTMLInputElement;
    const selectedAmount = element.value;
    this.myForm.get('amount')?.setValue([selectedAmount]);
  }
  
  parseAmount(amount: string): number {
    return parseFloat(amount);
  }
  
  getFormControl(i: number): FormControl {
    return this.amountFormArray.at(i) as FormControl;
  }

  createAmountControl(): FormControl {
    return this.formBuilder.control(0);
  }

  validateFormData() {
    if (!this.selectedGiftCardName || !this.selectedAmount || !this.giftCard.giftCardEmail ) {
      $('#requiredGiftCardModal').modal('show');
      return;
    }
    if (this.myForm.valid) {
      this.createGiftCardItem();
    } 
  }
  
  createGiftCardItem() {
    $('#giftCardLoadingModal').modal({ backdrop: 'static', keyboard: false });
    $('#giftCardLoadingModal').modal('show');
    this.saveGiftCardItem = false;
    const amount = parseInt(this.myForm.value.amount[0], 10);
    
    this.apiService.createGiftCardItem(
      this.selectedGiftCard.giftCardTypeId,
      this.themingService.shopId,
      amount,
      this.giftCard.status,
      this.giftCard.giftCardEmail,
      this.giftCard.giftCardMessage,
      this.giftCard.giftCardIsVirtual,
      )
      .then(async (result: any) => {
        if (result) {
          this.saveGiftCardItem = true;
          this.resetForm();
          $('#giftCardLoadingModal').modal('hide');
          $('#successGiftCardModal').modal('show');
          const latestGiftCard = await this.getLatestGiftCardItem();
          if (latestGiftCard) {
            this.sendGiftCardEmail([latestGiftCard.giftCardId]);
            this.resetForm();
          } else {
            console.error("Error");
          }
        } else {
          $('#errorGiftCardModal').modal('show');
          $('#giftCardLoadingModal').modal('hide');
        }
      })
      .catch((error) => {
        $('#errorGiftCardModal').modal('show');
        $('#giftCardLoadingModal').modal('hide');
      });
    } 
    
    closeModal() {
      $('#successGiftCardModal').modal('hide');
      $('#errorGiftCardModal').modal('hide');
      $('#requiredGiftCardModal').modal('hide');
    }
    
    resetForm() {
      this.selectedGiftCardName = ''; 
      this.selectedAmount = ''; 
      this.giftCard.giftCardMessage = ''; 
      this.giftCard.giftCardEmail = ''; 
      this.amountsArray = [0];
    }
    
    sendGiftCardEmail(giftCards: any[]) { 
      this.loadingSaleStateChange = true;
      if (giftCards && giftCards.length > 0) {
        const shopId = this.themingService.shopId;
        const requestBody = {
          stage: this.apiService['stage'],
          shopId: shopId,
          giftCards: giftCards.map(giftCardId => ({ giftCardId: giftCardId }))
        };
      this.apiService.sendGiftCardEmail(requestBody)
        .then(() => {
          console.log('Correo electrónico enviado con éxito');
        })
        .catch((error: any) => {
          console.error('Error al enviar el correo electrónico:', error);
        })
        .finally(() => {
          this.loadingSaleStateChange = false;
          this.resetForm();
        });
      } else {
        this.loadingSaleStateChange = false;
      }
    }

    async getLatestGiftCardItem() {
      this.loading = true;
      try {
        const data: any = await this.apiService.getGiftCardItems(this.themingService.shopId);
        if (data && data.giftCard && data.giftCard.length > 0) {
          const sortedGiftCards = data.giftCard.sort((a: any, b: any) => {
            return new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime();
          });
          return sortedGiftCards[sortedGiftCards.length - 1];
        } else {
          return null;
        }
      } catch (error) {
        return null;
      } finally {
        this.loading = false; 
      }
    }

}  