import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import { SettingsService } from 'src/app/settings.service';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
declare var $: any;


@Component({
  selector: 'app-create-collection',
  templateUrl: './create-collection.component.html',
  styleUrls: ['./create-collection.component.scss']
})

export class CreateCollectionComponent implements OnInit {

  collection = {
    collectionId: <any> 0,
    name: '',
    description: '',
  }
 
  savecollection = false;
  collections: any[] = [];
  
  constructor(
    private apiService:ApiService,
    private settingsService: SettingsService,
    public themingService: ThemingService

  ) { }

  ngOnInit(): void {
    this.savecollection = false;
    this.getCollections();
  }

  ChangeCollectionName(event: Event){
    const element = event.target as HTMLInputElement;
    this.collection.name = element.value;
  }

  ChangeCollectionDescription(event: Event){
    const element = event.target as HTMLInputElement;
    this.collection.description = element.value;
  }

  
  validateFormData(){
    if (!this.collection.name || this.collection.name.trim() === '') {
      $('#requiredCollectionModal').modal('show');
      return;
    }
    this.createCollection();
  }
  
  createCollection (){
    $('#collectionLoadingModal').modal({ backdrop: 'static', keyboard: false });
    $('#collectionLoadingModal').modal('show');
    this.savecollection = false;
    this.apiService.createCollection(
      this.collection.name,
      this.collection.description,
      this.themingService.shopId
      ).then((data: any) => {
        if(data.status === 200){
          this.savecollection = true;
          this.resetForm();
          $('#collectionLoadingModal').modal('hide');
          $('#successCollectionsModal').modal('show');
        }
        else{
          $('#errorCollectionsModal').modal('show');
          $('#collectionLoadingModal').modal('hide');
        }
        
      }) .catch((error) => {
        console.error(error);
      })
    }
    
    closeModal(){
      $('#successCollectionsModal').modal('hide');
      $('#errorCollectionsModal').modal('hide');
      $('#requiredCollectionModal').modal('hide');
    }
    
    getCollections(){ 
      this.apiService.getCollections(
        this.themingService.shopId
        ).then((data: any) => {
          this.collections = data.collections;
        }).catch((error) => {
          console.error(error);
        })
      }
      
      @ViewChild('collectionName')
      collectionName!: ElementRef;
      @ViewChild('collectionDescription')
      collectionDescription!: ElementRef;
      
      // Revisar 
      resetForm() {
        this.collectionName.nativeElement.value = null;
        this.collectionDescription.nativeElement.value = null; 
        this.collection.name = '';
      }
      
      setListingCollections(){
        this.settingsService.setListingCollections();
      }
      
}

