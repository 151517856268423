import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
import { SettingsService } from 'src/app/settings.service';
import { DatePipe } from '@angular/common';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-gift-cards-items',
  templateUrl: './gift-cards-items.component.html',
  styleUrls: ['./gift-cards-items.component.scss']
})
export class GiftCardsItemsComponent implements OnInit {

  giftCards: any[] = [];
  loading: boolean = false;
  isCreating: boolean = false;
  isViewingDetail: boolean = false;
  isViewingList: boolean = true;
  selectedGiftCardId: number | null = null;

  constructor(
    private settingsService: SettingsService,
    private apiService: ApiService,
    public themingService: ThemingService,
    public appComponent: AppComponent,
  ) { }

  ngOnInit(): void {
    this.getGiftCardItems();
  }

  async getGiftCardItems() {
    this.loading = true;
    try {
      const data: any = await this.apiService.getGiftCardItems(this.themingService.shopId);
      if (data && data.giftCard) {
        this.giftCards = data.giftCard;
      } else {
        console.error(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  showCreateView() {
    this.isCreating = true;
    this.isViewingDetail = false;
    this.isViewingList = false;
  }

  showDetailView(giftCardId: string) {
    this.isViewingDetail = true;
    this.isCreating = false;
    this.isViewingList = false;
    this.selectedGiftCardId = parseFloat(giftCardId); 
    this.setEditingGiftCardType(giftCardId);
  }

  showListView() {
    this.isCreating = false;
    this.isViewingDetail = false;
    this.isViewingList = true;
    this.selectedGiftCardId = null;  
  }

  getTitle(): string {
    if (this.isCreating) {
      return 'Crear Tarjeta de Regalo';
    } else if (this.isViewingDetail) {
      return 'Tarjeta de Regalo';
    } else {
      return 'Tarjetas de Regalo';
    }
  }

  setCreatingGiftCardItem(){
    this.settingsService.setCreatingGiftCardItem();
  }

  setEditingGiftCardType(giftCardTypeId: string){
    this.settingsService.setEditingGiftCardItem(giftCardTypeId);
  }

  formatDate(date: string | Date): string {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'yyyy-MM-dd HH:mm') || '';
  }
}
