import { ProductsComponent } from './pages/products/products.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, AuthGuardOwner, AuthGuardPermission, UnauthGuard } from './auth.service';
import { HomeComponent } from './pages/home/home.component';
import { PasswordRecoveryComponent } from './pages/password-recovery/password-recovery.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { CreateProductComponent } from './components/create-product/create-product.component';
import { UsersComponent } from './components/users/users.component';
import { AddStaffComponent } from './components/add-staff/add-staff.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { CreateCategoryComponent } from './components/create-category/create-category.component';
import { CreateCouponComponent } from './components/create-coupon/create-coupon.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { OrderComponent } from './pages/order/order.component';
import { CouponsComponent } from './pages/coupons/coupons.component';
import { ProductComponent } from './pages/product/product.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { GeneralInfoComponent } from './components/general-info/general-info.component';
import { ThemingSettingsComponent } from './components/theming-settings/theming-settings.component';
import { EditProductComponent } from './components/edit-product/edit-product.component';
import { RegisterComponent } from './pages/register/register.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { ClientComponent } from './pages/client/client.component';
import { GiftCardsComponent } from './pages/gift-cards/gift-cards.component';
import { CreateGiftCardComponent } from './components/create-gift-card/create-gift-card.component';
import { EditGiftCardComponent } from './components/edit-gift-card/edit-gift-card.component';
import { GiftCardsItemsComponent } from './pages/gift-cards-items/gift-cards-items.component';
import { CreateGiftCardItemsComponent } from './components/create-gift-card-items/create-gift-card-items.component';
import { GiftCardsItemComponent } from './pages/gift-cards-item/gift-cards-item.component';
import { SubCategoriesComponent } from './pages/sub-categories/sub-categories.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { SubSubcategoriesComponent } from './pages/sub-subcategories/sub-subcategories.component';
import { CurrentPlanComponent } from './pages/current-plan/current-plan.component';
import { CashRegistersComponent } from './pages/cash-registers/cash-registers.component';
import { CashRegisterComponent } from './pages/cash-register/cash-register.component';
import { EditComponentComponent } from './components/edit-component/edit-component.component';
import { CreateClientComponent } from './components/create-client/create-client.component';
import { EditClientComponent } from './components/edit-client/edit-client.component';

const routes: Routes = [
  {
    path: 'login',
    title: 'Backoffice | Iniciar sesión',
    component: SignInComponent,
    canActivate: [UnauthGuard],
  },
  {
    path: 'recover-password',
    title: 'Backoffice | Recuperar contraseña',
    component: PasswordRecoveryComponent,
    canActivate: [UnauthGuard],
  },
  {
    path: 'register',
    title: 'Backoffice | Registrarse',
    component: RegisterComponent,
    canActivate: [UnauthGuard],
  },
  {
    path: 'home',
    title: 'Backoffice | Home',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'giftCardTypes',
    component: GiftCardsComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'giftCards' },
  },
  {
    path: 'giftCardTypes/create',
    component: CreateGiftCardComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'giftCards' },
  },
  {
    path: 'giftCardTypes/:giftCardId',
    component: EditGiftCardComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'giftCards' },
  },
  {    
    path: 'giftCardItems',
    component: GiftCardsItemsComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'giftCards' },
  },
  {    
    path: 'giftCardItems/create',
    component: CreateGiftCardItemsComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'giftCards' },
  },
  {
    path: 'giftCardItems/:giftCardId', 
    component:  GiftCardsItemComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'giftCards' },
  },
  {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'sales' },
  },
  {
    path: 'orders/:saleId',
    component: OrderComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'sales' },
  },
  {
    path: 'cashRegisters',
    component: CashRegistersComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'cashRegisters' },
  },
  {
    path: 'cashRegister/:cashRegisterId',
    component: CashRegisterComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'cashRegisters' },
  },
  {
    path: 'clients',
    component: ClientsComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'clients' },
  },
  {
    path: 'client/:clientId',
    component: ClientComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'clients' },
  },
  {
    path: 'clients/create',
    component: CreateClientComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'clients' },
  },
  {
    path: 'edit-clients/:clientId',
    component: EditClientComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'clients' },
  },
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'products' },
  },
  {
    path: 'products/create',
    component: CreateProductComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'products' },
  },
  {
    path: 'products/edit/:productId',
    component: EditProductComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'products' },
  },
  {
    path: 'products/:productId',
    component: ProductComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'products' },
  },
  {
    path: 'categories',
    component: CategoriesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'categories/create',
    component: CreateCategoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'subcategories',
    component: SubCategoriesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sub-subcategories',
    component: SubSubcategoriesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'coupons',
    component: CouponsComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'coupons' }, 
  },
  {
    path: 'coupons/create',
    component: CreateCouponComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'coupons' }, 
  },
  {
    path: 'coupons/:couponId',
    component: CreateCouponComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'coupons' }, 
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'users/:Username',
    component: UserDetailsComponent,
    canActivate: [AuthGuard, AuthGuardOwner],
  },
  {
    path: 'add-user',
    component: AddStaffComponent,
    canActivate: [AuthGuard, AuthGuardOwner],
  },
  {
    path: 'profile',
    component: UserProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuardPermission],
    data: { permission: 'config' },
  },
  {
    path: 'settings/general',
    component: GeneralInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'settings/theming',
    component: ThemingSettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'settings/current-plan',
    component: CurrentPlanComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: '**',
  //   redirectTo: 'login',
  //   pathMatch: 'full',
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
