import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { SettingsService } from 'src/app/settings.service';
import { ThemingService } from 'src/app/theming.service';
declare var $: any;

@Component({
  selector: 'app-create-shop-point',
  templateUrl: './create-shop-point.component.html',
  styleUrls: ['./create-shop-point.component.scss']
})
export class CreateShopPointComponent implements OnInit {

  shopPoint = {
    name: '',
    location: '',
    status: 0,
    phone: ''
  }
  saveShopPoint = false;
  shopPoints: any [] = [];
  isActive = true;

  constructor(
    private settingsService: SettingsService,
    private apiService:ApiService,
    public themingService: ThemingService,
  ) { }

  ngOnInit(): void {
    this.saveShopPoint = false;
  }
  
  changeShopPointName(event: Event){
    const element = event.target as HTMLInputElement;
    this.shopPoint.name = element.value;
  }

  ChangeShopPointLocation(event: Event){
    const element = event.target as HTMLInputElement;
    this.shopPoint.location = element.value;
  }

  changeShopPointPhone(event: Event){
    const element = event.target as HTMLInputElement;
    this.shopPoint.phone = element.value;
  }

  validateFormData(){
    if ((! this.shopPoint.name || this.shopPoint.name.trim() === '')) {
      $('#requiredShopPointModal').modal('show');
      return;
    }
    this.createShopPoint();
  }

  createShopPoint (){
    $('#shopPointLoadingModal').modal({ backdrop: 'static', keyboard: false });
    $('#shopPointLoadingModal').modal('show');
    this.saveShopPoint = false;
    this.apiService.createShopPoint(
        this.themingService.shopId,
        this.shopPoint.name,
        this.shopPoint.phone,
        this.shopPoint.location,
        this.isActive ?1 :0
        ).then((data: any) => {
          if(data.status === 200){
            this.saveShopPoint = true;
            this.resetForm();
            $('#shopPointLoadingModal').modal('hide');
            $('#successShopPointsModal').modal('show');
          }
          else{
            $('#errorShopPointsModal').modal('show');
            $('#shopPointLoadingModal').modal('hide');
          }
         
        }) .catch((error) => {
          console.error(error);
        })
  }

  @ViewChild('shopPointName')
  shopPointName!: ElementRef;
  @ViewChild('shopPointLocation')
  shopPointLocation!: ElementRef; 
  @ViewChild('shopPointPhone')
  shopPointPhone!: ElementRef; 
  
  resetForm() {
    this.shopPointName.nativeElement.value = '';
    this.shopPointLocation.nativeElement.value = '';
    this.shopPointPhone.nativeElement.value = '';
    this.shopPoint.name = '';
    this.shopPoint.location = '';
    this.shopPoint.phone = '';
  }

  closeModal(){
    $('#successShopPointsModal').modal('hide');
    $('#errorShopPointsModal').modal('hide');
    $('#requiredShopPointModal').modal('hide');
  }

  setListingShopPoints(){
    this.settingsService.setListingShopPoints();
  }

}
