import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-go-back',
  templateUrl: './go-back.component.html',
  styleUrls: ['./go-back.component.scss']
})
export class GoBackComponent {

  @Input() route: string ="";
  constructor(private router: Router) { }

/**
 * The goBack() function navigates back in the platform's history
 */
  goBack() {
    this.router.navigate([this.route]);
  }
}
