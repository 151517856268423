<section>
  <img class="mobydyg-logo" src="../../../assets/static/mobydyg.png" alt="MobyDyg Logo">
  <div class="card" [@slideLeft]="state" *ngIf="currentStep === 1">
    <div class="illustration">
      <img src="../../../assets/illustrations/moby-sign-in.png" />
    </div>
    <div class="form" *ngIf="!needsNewPassword; else ChangePasswordRequest" [@inOutAnimation]>
      <h2>INICIAR SESIÓN</h2>
      <form action="post" [formGroup]="signInForm" (ngSubmit)="onSignIn()">
        <div class="form-group">
          <div class="alert-span-exeption">
            <span class="error-message" *ngIf="exceptionMessage != ''" [@inOutAnimation]>{{exceptionMessage}}</span>
          </div>
          <input type="text" name="email" placeholder="Tu correo electrónico" class="textInput"
            formControlName="givenEmail" (focus)="exceptionMessage = ''">
          <div class="checkboxContainer">
            <label for="remember-me">
              <input type="checkbox" name="remember-me" class="checkboxInput" [value]="true" id="remember-me"
                (change)="onRememberMe($event)" [checked]="rememberMe">
              <label for="remember-me" class="checkmark"></label>
              Recordar usuario
            </label>
          </div>
        </div>
        <div class="form-buttons d-flex-column">
          <button type="button" [disabled]="isStepInvalid()" (click)="nextStep($event)" class="submit-btn">Siguiente</button><br>
        </div>
      </form>
    </div>
  </div>
  <div class="card" [@slideLeft]="state" *ngIf="currentStep === 2">
    <div class="illustration">
      <img src="../../../assets/illustrations/moby-sign-in.png" />
    </div>
    <div class="form" *ngIf="!needsNewPassword; else ChangePasswordRequest" [@inOutAnimation]>
      <h2>INICIAR SESIÓN</h2>
      <form action="post" [formGroup]="signInForm" (ngSubmit)="onSignIn()">
        <div class="form-group">
          <div class="alert-span-exeption">
            <span class="error-message" *ngIf="exceptionMessage != ''" [@inOutAnimation]>{{exceptionMessage}}</span>
          </div>
          <label class="label-left">Seleccione una tienda</label>
          <select class="form-select" (change)="changeShop($event)" formControlName="givenShop">
            <option *ngFor="let shop of shops" [value]="shop.shopId">
              {{ shop.shopName }}
            </option>
          </select>
        </div>
        <div class="form-buttons d-flex">
          <button type="button" (click)="prevStep($event)" class="submit-btn">Anterior</button>
          <button type="button" [disabled]="isStepInvalid()" (click)="nextStep($event)" class="submit-btn">Siguiente</button><br>
        </div>
      </form>
    </div>
  </div>
  <div class="card" *ngIf="currentStep === 3">
    <div class="illustration">
      <img src="../../../assets/illustrations/moby-sign-in.png" />
    </div>
    <div class="form" *ngIf="!needsNewPassword; else ChangePasswordRequest" [@inOutAnimation]>
      <h2>INICIAR SESIÓN</h2>
      <form action="post" [formGroup]="signInForm" (ngSubmit)="onSignIn()">
        <div class="form-group">
          <div class="alert-span-exeption">
            <span class="error-message" *ngIf="exceptionMessage != ''" [@inOutAnimation]>{{exceptionMessage}}</span>
          </div>
          <div class="password-input">
            <input [type]="isPasswordVisible ? 'text':'password'" name="password" placeholder="Tu contraseña"
              class="textInput" formControlName="givenPassword" (focus)="exceptionMessage = ''">
            <i class="password-icon" [ngClass]="isPasswordVisible ? 'hide-password' : 'show-password'"
              (click)="isPasswordVisible = !isPasswordVisible"></i>
          </div>
          <a class="a-link a-password-recovery" (click)="recoveryPassword()">Olvidé mi contraseña</a>
        </div>
        <div class="form-buttons d-flex">
          <button type="button" (click)="prevStep($event)" class="submit-btn">Anterior</button>
          <button type="submit" [disabled]="isStepInvalid()" class="submit-btn">Iniciar sesión</button><br>
        </div>
      </form>
    </div>
  </div>
</section>

<ng-template #ChangePasswordRequest>
  <div class="form" [@inOutAnimation]>
    <h2>CAMBIAR CONTRASEÑA</h2>
    <label class="info-message">Es necesario cambiar su contraseña al iniciar sesión por primera vez</label>
    <form action="post" [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword()">
      <div class="form-group">
        <div class="alert-span-exeption">
          <span *ngIf="exceptionMessage != ''" [@inOutAnimation] class="error-message">{{exceptionMessage}}</span>
        </div>
        <div class="alert-span"
          *ngIf="changePasswordFormValidation['newPassword'].touched && changePasswordFormValidation['newPassword'].invalid">
          <label class="error-message"
            *ngIf="changePasswordFormValidation['newPassword'].touched && changePasswordFormValidation['newPassword'].errors && changePasswordFormValidation['newPassword'].errors['required']"
            [@inOutAnimation]>
            Este campo es requerido
          </label>
          <label class="error-message"
            *ngIf="changePasswordFormValidation['newPassword'].touched && changePasswordFormValidation['newPassword'].errors && changePasswordFormValidation['newPassword'].errors['pattern']"
            [@inOutAnimation]>
            Formato incorrecto
          </label>
        </div>
        <div class="password-input">
          <input [type]="isPasswordVisible ? 'text':'password'" name="password" placeholder="Tu contraseña"
            class="textInput" formControlName="newPassword" (focus)="exceptionMessage = ''">
          <i class="password-icon" [ngClass]="isPasswordVisible ? 'hide-password' : 'show-password'"
            (click)="isPasswordVisible = !isPasswordVisible"></i>
        </div>
        <div class="alert-span">
          <span>La contraseña debe contener:</span>
          <ul>
            <li><span>Una longitud de 8 a 16 carácteres</span></li>
            <li><span>Al menos una letra en mayúscula</span></li>
            <li><span>Al menos una letra en minúscula</span></li>
            <li><span>Un número</span></li>
          </ul>
        </div>

        <div class="alert-span"
          *ngIf="changePasswordFormValidation['confirmNewPassword'].touched && changePasswordFormValidation['confirmNewPassword'].invalid">
          <label class="error-message"
            *ngIf="changePasswordFormValidation['confirmNewPassword'].errors && changePasswordFormValidation['confirmNewPassword'].errors['required']"
            [@inOutAnimation]>
            Este campo es requerido
          </label>
          <label class="error-message"
            *ngIf="changePasswordFormValidation['confirmNewPassword'].errors && changePasswordFormValidation['confirmNewPassword'].errors['matching']"
            [@inOutAnimation]>
            Las contraseñas no coinciden
          </label>
        </div>
        <div class="password-input">
          <input [type]="isConfirmPasswordVisible ? 'text':'password'" name="confirmNewPassword"
            placeholder="Confirma tu contraseña" class="textInput" formControlName="confirmNewPassword"
            (focus)="exceptionMessage = ''">
          <i class="password-icon" [ngClass]="isConfirmPasswordVisible ? 'hide-password' : 'show-password'"
            (click)="isConfirmPasswordVisible = !isConfirmPasswordVisible"></i>
        </div>
      </div>
      <div class="form-buttons d-flex-column password-form">
        <button type="submit" [disabled]="changePasswordForm.invalid" class="submit-btn">Iniciar sesión</button><br>
      </div>
    </form>
  </div>

</ng-template>
