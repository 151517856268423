import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/settings.service';
import { ApiService } from 'src/app/api.service';
import { ShopTheming, ThemingService } from 'src/app/theming.service';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
declare let $: any; 
@Component({
  selector: 'app-edit-gift-card',
  templateUrl: './edit-gift-card.component.html',
  styleUrls: ['./edit-gift-card.component.scss']
})
export class EditGiftCardComponent implements OnInit {
  myForm!: FormGroup;
  amountFormArray!: FormArray;
  giftCard: any;
  giftCardTypeId = 0;
  giftCardImage: any[] = [];
  imageURL: string = '';
  timestamp: number;
  savedImageUrl: string = '';
  quantityArray: number[] = Array.from({ length: 999 }, (_, index) => index + 1);
  monthsArray: number[] = Array.from({ length: 12 }, (_, index) => index + 1);
  showExistingImage: boolean = true;
  showLocalImage: boolean = true;
  loading: boolean = false;

  public shopTheme: ShopTheming[] = [];
  constructor(
    public appComponent: AppComponent,
    private settingsService: SettingsService,
    private apiService: ApiService,
    private themingService: ThemingService,
    public route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer
  ) {
    this.route.paramMap.subscribe((params) => {
      let giftCardTypeId = Number(params.get('giftCardId'));
      if (giftCardTypeId !== this.giftCardTypeId) {
        this.appComponent.goToTop();
        this.giftCardTypeId = giftCardTypeId;
        this.shopTheme = this.themingService.shopTheming;
      }
    });
    this.timestamp = Date.now()
    this.amountFormArray = this.formBuilder.array([this.createAmountControl()]);
    this.myForm = this.formBuilder.group({
      giftCardName: [''],
      giftCardValidMonths: [''],
      giftCardStock: [''],
      giftCardStatus: [true],
      amount: this.amountFormArray,
    });
  }

  ngOnInit(): void {
    this.getGiftCardTypesById();
  }

  sanitizeImageURL(imageURL: string) {
    return this.sanitizer.bypassSecurityTrustUrl(imageURL);
  }
  
  triggerFileInput(): void {
    const uploadButton = document.getElementById('btn-upload');
    if (uploadButton && this.giftCardImage.length === 0) {
      uploadButton.removeAttribute('disabled');  
      uploadButton.click();
      uploadButton.setAttribute('disabled', 'true');
    }
  }

  onFileSelected(event: any): void {
    const image: File = event.target.files[0];
    if (image && image.type.startsWith('image')) {
      this.uploadImage(image)
        .then((uploadedImage) => {
          console.log('Imagen cargada exitosamente:', uploadedImage);
          const timestamp = Date.now();
          this.giftCardImage.push({ imageURL: uploadedImage.imageURL, blob: uploadedImage.blob, timestamp: timestamp });
          this.imageURL = uploadedImage.imageURL;
        })
        .catch((error) => {
          console.log('Error al cargar la imagen:', error);
        });
    } else {
      console.log('No es una imagen');
    }
    console.log('giftCardImage:', this.giftCardImage);
  }
  
  blobFile = async ($event: any): Promise<any> => {
    try {
      const unSafeImg = window.URL.createObjectURL($event);
      const img = this.sanitizer.bypassSecurityTrustUrl(unSafeImg);
      const reader = new FileReader();
      
      return new Promise((resolve, reject) => {
        reader.onload = () => {
          resolve({
            blob: $event,
            img,
            base: reader.result
          });
        };
        reader.onerror = (error) => {
          reject({
            blob: $event,
            img,
            base: null
          });
        };
        reader.readAsDataURL($event);
      });
    } catch (e) {
      return Promise.reject(null);
    }
  };

  async saveGiftCard() {
    try {
      if (this.giftCardImage.length > 0) {
        const image = this.giftCardImage[0];
        const ext = image.imageURL.split(';')[0].split('/')[1];
        const data: any = await this.apiService.uploadFile(`${this.shopTheme[0].shopId}/${environment.stage}/images/giftcards/giftcard${this.timestamp}.${ext}`, image.blob, {});
        this.savedImageUrl = data.imageURL;
      } else {
        this.savedImageUrl = this.giftCard.images;
      }
    } catch (error) {
      console.error('Error al cargar la imagen de la tarjeta de regalo:', error);
    }
  }
  
  uploadImage(image: File): Promise<any> {
    return this.blobFile(image)
      .then((res: any) => {
        return this.apiService.uploadFile(image.name, res.blob, { position: 1, type: 'original' })
          .then(() => {
            return {
              imageURL: res.base,
              blob: res.blob,
            };
          });
      });
  }

  deleteImage(image: any): void {
    this.giftCardImage = this.giftCardImage.filter(img => img !== image);
    
    this.showExistingImage = false;
    this.showLocalImage = false;
  }

  hideImage(): void {
    this.showExistingImage = false;
  }

  getFormControl(i: number): FormControl {
    return this.amountFormArray.at(i) as FormControl;
  }

  createAmountControl(): FormControl {
    return this.formBuilder.control(0);
  }

  addAmount(): void {
    this.amountFormArray.push(this.createAmountControl());
  }

  removeAmount(index: number): void {
    this.amountFormArray.removeAt(index);
  }

  setListingGiftCardTypes(){
    this.settingsService.setListingGiftCardTypes(); 
  }

  ChangeGiftCardName(event: Event){
    const element = event.target as HTMLInputElement;
    this.giftCard.name = element.value;
  }
  
  ChangeGiftCardValidMonths (event: Event){
    const element = event.target as HTMLInputElement;
    this.giftCard.validMonths = element.value;
  }
  
  ChangeGiftCardStock(event: Event) {
    const element = event.target as HTMLInputElement;
    this.giftCard.stock = element.value;
  }

  ChangeGiftCardStatus(event: Event) {
    const element = event.target as HTMLInputElement;
    this.giftCard.status = element.checked ? 1 : 0;
  }

  async getGiftCardTypesById(): Promise<void> {
    this.loading = true;
    try {
      const data: unknown = await this.apiService.getGiftCardTypesById(this.giftCardTypeId, this.themingService.shopId);
      if (Array.isArray(data)) {
        this.giftCard = data[0];
        this.initializeAmountFormControls();
      } else {
        console.error();
      }
    } catch (error) {
      console.error(error);
    }  finally {
      setTimeout(() => {
        this.loading = false;
      }, 500); 
    }
  }

  initializeAmountFormControls(): void {
    const amountsArray: string[] = this.giftCard.amount.split(',');
    this.amountFormArray.clear();
    amountsArray.forEach((amount: string) => {
      this.amountFormArray.push(this.formBuilder.control(parseFloat(amount)));
    });
  }
  
  async validateFormData(): Promise<void> {
    this.loading = true;
    try {
      await this.saveGiftCard();
      this.apiService.updateGiftCard(
        this.giftCardTypeId,
        this.giftCard.name,
        this.themingService.shopId,
        this.amountFormArray.value.join(','),
        this.giftCard.stock,
        this.giftCard.status,
        this.giftCard.validMonths,
        this.savedImageUrl, 
      ).then((data: any) => {
        console.log(data);
  
        if (data.status === 200) {
          $('#successGiftCardsModal').modal('show');
          this.loading = false;
        } else {
          $('#errorGiftCardsModal').modal('show');
          this.loading = false;
        }
      }).catch((error) => {
        console.error(error); 
      });
    } catch (error) {
      console.error(error);
    }
  }
  
  closeModal(): void {
    $('#successGiftCardsModal').modal('hide');
    $('#errorGiftCardsModal').modal('hide');
  }
}