<div class="menu-container">
  <div class="label">
    <div class="label-group">
      <i class="feather" data-feather="image"></i>
      <strong>Menu</strong>
    </div>
    <ng-container *ngIf="!isCreatingMenu && !isEditingMenu">
      <a class="create-link" (click)="toggleMenuVisibility(true)">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
          <path fill="#EA803F" d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2M8 16V4h12l.002 12z"/>
          <path fill="#EA803F" d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"/>
        </svg>
      </a>
    </ng-container>
    <ng-container *ngIf="isCreatingMenu">
      <a class="create-link" (click)="cancelCreation()">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
          <path fill="#D44646" d="M4 20h14v2H4a2 2 0 0 1-2-2V6h2zM20.22 2H7.78C6.8 2 6 2.8 6 3.78v12.44C6 17.2 6.8 18 7.78 18h12.44c.98 0 1.78-.8 1.78-1.78V3.78C22 2.8 21.2 2 20.22 2M19 13.6L17.6 15L14 11.4L10.4 15L9 13.6l3.6-3.6L9 6.4L10.4 5L14 8.6L17.6 5L19 6.4L15.4 10z"/>
        </svg>
      </a>
    </ng-container>
    <ng-container *ngIf="isEditingMenu">
      <a class="create-link" (click)="cancelEdition()">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
          <path fill="#D44646" d="M4 20h14v2H4a2 2 0 0 1-2-2V6h2zM20.22 2H7.78C6.8 2 6 2.8 6 3.78v12.44C6 17.2 6.8 18 7.78 18h12.44c.98 0 1.78-.8 1.78-1.78V3.78C22 2.8 21.2 2 20.22 2M19 13.6L17.6 15L14 11.4L10.4 15L9 13.6l3.6-3.6L9 6.4L10.4 5L14 8.6L17.6 5L19 6.4L15.4 10z"/>
        </svg>
      </a>
    </ng-container>
  </div>
  <div *ngIf="isCreatingMenu">
    <app-create-menu (creationMenuSuccess)="toggleMenuVisibility(false)"></app-create-menu>
  </div>
  <div *ngIf="isEditingMenu">
    <app-edit-menu [menuId]="editMenuId" (close)="cancelEdition()"></app-edit-menu>
  </div>
  <div class="loading" *ngIf="loading">
    <div class="spinner-border" role="status"></div>
  </div>
  <div *ngIf="isListingMenu && !isCreatingMenu && !loading" class="container-inventory">
    <div *ngFor="let menuItem of menus; let i = index" class="menu-item-container">
      <div class="menu-item">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" [checked]="menuItem.menuStatus === 1" (change)="toggleMenuStatus(menuItem)">
          <label class="form-check-label">
            {{ menuItem.menuStatus === 1 ? 'Activo' : 'Inactivo' }}
          </label>
        </div>
        <h5 class="title">{{ menuItem.menuName }}</h5>
        <a class="edit-link" (click)="editMenu(menuItem.menuId)" (cdkDragStarted)="$event.preventDefault()">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
            <path fill="#EA803F" d="M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h6.525q.5 0 .75.313t.25.687t-.262.688T11.5 5H5v14h14v-6.525q0-.5.313-.75t.687-.25t.688.25t.312.75V19q0 .825-.587 1.413T19 21zm4-7v-2.425q0-.4.15-.763t.425-.637l8.6-8.6q.3-.3.675-.45t.75-.15q.4 0 .763.15t.662.45L22.425 3q.275.3.425.663T23 4.4t-.137.738t-.438.662l-8.6 8.6q-.275.275-.637.438t-.763.162H10q-.425 0-.712-.288T9 14m12.025-9.6l-1.4-1.4zM11 13h1.4l5.8-5.8l-.7-.7l-.725-.7L11 11.575zm6.5-6.5l-.725-.7zl.7.7z"/>
          </svg>
        </a>
      </div>
      <div 
      cdkDropList 
      cdkDropListOrientation="horizontal" 
      class="menu-list" 
      (cdkDropListDropped)="drop($event, menuItem)">
      <div class="menu-box" *ngFor="let option of menuItem.options" cdkDrag>
        <div>{{ option.menuOptionName }}</div>
      </div>
    </div>
    <div class="delete-svg">
    <a class="delete-link" (click)="confirmDelete(menuItem.menuId)" (cdkDragStarted)="$event.preventDefault()">
      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24">
        <path fill="#e11d48" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z"/>
      </svg>
    </a>
    </div>
  </div>
</div>
</div>

<app-confirmation-modal *ngIf="modalDelete" [title]="'¿Seguro que quiere eliminar este menú?'" [message]="'Al eliminar este menú se eliminarán los items que estén relacionados a él'"
  [confirmText]="'Eliminar'" [cancelText]="'Cancelar'" (response)="deleteMenu($event)">
</app-confirmation-modal>

<div class="modal delete-menu-confirm-modal" id="deleteMenuModal" tabindex="-1">
  <div class="modal-dialog menu-modal-dialog" role="document">
    <div class="modal-content menu-modal-content">
      <div class="modal-body menu-modal-body">
        <h3 class="text-center">¡Éxito!</h3>
        <p class="text-center">El menú fue eliminado exitosamente!</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
      </div>
    </div>
  </div>
</div>
