<div class="card" *ngIf="!isVerifing; else VerificationModal">
  <div class="illustration">
    <img src="../../../assets/illustrations/sign-in.png" />
  </div>
  <div class="form">
    <h2>CAMBIAR CONTRASEÑA</h2>
    <form action="post" [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword()">
      <div class="form-group">
        <div class="alert-span-exeption">
          <span *ngIf="exceptionMessage != ''" [@inOutAnimation] class="error-message">{{exceptionMessage}}</span>
        </div>
        <div class="alert-span" *ngIf="changePasswordFormValidation['code'].touched && changePasswordFormValidation['code'].invalid">
          <label class="error-message" *ngIf="changePasswordFormValidation['code'].errors && changePasswordFormValidation['code'].errors['required']" [@inOutAnimation]>
            Este campo es requerido
          </label>
          <label class="error-message" *ngIf="changePasswordFormValidation['code'].errors && changePasswordFormValidation['code'].errors['pattern']" [@inOutAnimation]>
            Formato de código incorrecto
          </label>
        </div>
        <input type="text" name="email" placeholder="Tu código de verificación" class="textInput" formControlName="code" (focus)="exceptionMessage = ''">


        <div class="alert-span" *ngIf="changePasswordFormValidation['givenPassword'].touched && changePasswordFormValidation['givenPassword'].invalid">
          <label class="error-message" *ngIf="changePasswordFormValidation['givenPassword'].touched && changePasswordFormValidation['givenPassword'].errors && changePasswordFormValidation['givenPassword'].errors['required']" [@inOutAnimation]>
            Este campo es requerido
          </label>
          <label class="error-message" *ngIf="changePasswordFormValidation['givenPassword'].touched && changePasswordFormValidation['givenPassword'].errors && changePasswordFormValidation['givenPassword'].errors['pattern']" [@inOutAnimation]>
            Formato incorrecto
          </label>
        </div>
        <div class="password-input">
          <input [type]="isPasswordVisible ? 'text':'password'" name="password" placeholder="Tu contraseña" class="textInput" formControlName="givenPassword" (focus)="exceptionMessage = ''">
          <i
            class="password-icon"
            [ngClass]="isPasswordVisible ? 'hide-password' : 'show-password'"
            (click)="isPasswordVisible = !isPasswordVisible"
          ></i>
        </div>
        <div class="alert-span">
          <span>La contraseña debe contener:</span>
            <ul>
              <li><span>Una longitud de 8 a 16 carácteres</span></li>
              <li><span>Al menos una letra en mayúscula</span></li>
              <li><span>Al menos una letra en minúscula</span></li>
              <li><span>Un número</span></li>
            </ul>
        </div>

        <div class="alert-span" *ngIf="changePasswordFormValidation['confirmGivenPassword'].touched && changePasswordFormValidation['confirmGivenPassword'].invalid">
          <label class="error-message" *ngIf="changePasswordFormValidation['confirmGivenPassword'].errors && changePasswordFormValidation['confirmGivenPassword'].errors['required']" [@inOutAnimation]>
            Este campo es requerido
          </label>
          <label class="error-message" *ngIf="changePasswordFormValidation['confirmGivenPassword'].errors && changePasswordFormValidation['confirmGivenPassword'].errors['matching']" [@inOutAnimation]>
            Las contraseñas no coinciden
          </label>
        </div>
        <div class="password-input">
          <input [type]="isConfirmPasswordVisible ? 'text':'password'" name="confirmPassword" placeholder="Confirma tu contraseña" class="textInput" formControlName="confirmGivenPassword" (focus)="exceptionMessage = ''">
          <i
            class="password-icon"
            [ngClass]="isConfirmPasswordVisible ? 'hide-password' : 'show-password'"
            (click)="isConfirmPasswordVisible = !isConfirmPasswordVisible"
          ></i>
        </div>
      </div>
      <div class="form-buttons">
        <button type="submit" [disabled]="changePasswordForm.invalid" class="submit-btn">Confirmar</button><br>
      </div>
    </form>
  </div>
</div>

<ng-template #VerificationModal>
  <div class="card" [@inOutAnimation]>
    <div class="verificationMessage">
      <h2>CAMBIAR CONTRASEÑA</h2>
      <label>Se ha realizado el cambio de contraseña. <br><strong>Inicie sesión con su nueva contraseña.</strong></label>
      <button type="button" (click)="goToLogin()">Iniciar sesión</button>
    </div>
  </div>
</ng-template>
