<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>

<div class="container">
  <div class="container-giftcards">
    <div class="nav-tab-container">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="home-tab" [routerLink]="['/giftCardTypes']" routerLinkActive="active" role="tab" aria-controls="home" aria-selected="true">Tipos de Tarjeta de Regalo</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="home-tab" [routerLink]="['/giftCardItems']" routerLinkActive="active" role="tab" aria-controls="home" aria-selected="true">Tarjetas de Regalo</a>
        </li>
      </ul>
    </div>
    <div class="header">
      <h1 class="title">{{ getTitle() }}</h1>
      <button *ngIf="isViewingList" type="button" class="btn btn-outline-primary primary-btn" (click)="showCreateView()">Crear</button>
      <app-back-arrow-settings *ngIf="!isViewingList" (click)="showListView()"></app-back-arrow-settings>
    </div>
    <app-create-gift-card-items *ngIf="isCreating"></app-create-gift-card-items>
    <div *ngIf="isViewingList" class="form-card">
      <div class="loading" *ngIf="loading"> 
        <div class="spinner-border" role="status"></div> 
      </div>
      <table *ngIf="!loading" class="table table-bordered table-hover"> 
        <thead>
          <th scope="col" class="table-start">ID</th>
          <th scope="col">Monto</th>
          <th scope="col">Fecha de compra</th>
          <th scope="col">Código</th>
          <th scope="col" class="table-end">Estatus</th>
        </thead>
        <tbody>
          <tr *ngFor="let giftCard of giftCards" (click)="showDetailView(giftCard.giftCardId)"> 
            <td class="gift-card-id">
              {{ giftCard.giftCardId }}
            </td>
            <td>{{ appComponent.formatMoney(giftCard.amount) }}</td>
            <td>{{ formatDate(giftCard.date) }}</td>
            <td>{{ giftCard.code }}</td>
            <td>{{ giftCard.status === 0 ? 'Inactiva' : (giftCard.status === 1 ? 'Activa' : 'Pendiente') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <app-gift-cards-item *ngIf="isViewingDetail" [giftCardId]="selectedGiftCardId !== null ? selectedGiftCardId.toString() : ''"></app-gift-cards-item>
  </div>
</div>
