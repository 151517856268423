/**
 * The function generates a random password of a specified size with at least one uppercase letter,
 * one lowercase letter, and one number.
 * @param {number} [size] - The size parameter is an optional number that determines the length of
 * the generated password. If no value is provided, the default size is 8 characters. If a value
 * greater than 15 is provided, the size is capped at 15 characters.
 * @returns a randomly generated password as a string.
 */
export function generatePassword(size?: number): string {
  size = size ?? 8;
  size = size > 15 ? 15 : size;
  const src = 'abcdefghijklmnopqrstuvwxyz1234567890';
  let result = '';
  for (let i = 0; i <= size; i++) {
    const character = src.charAt(
      parseInt((Math.random() * src.length).toString())
    );
    if (isNaN(Number(character))) {
      result +=
        parseInt((Math.random() * 2 + 1).toString()) % 2
          ? character.toLocaleUpperCase()
          : character;
    } else {
      result += character;
    }
  }
  const regex = new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?!.* ).{8,16}$');
  if (!regex.test(result)) {
    generatePassword(size);
  }
  return result;
}

/**
 * The function normalizes and replaces whitespace in a given string, and returns the result in
 * lowercase.
 * @param {string} text - The input text that needs to be normalized.
 * @param {string} [whiteSpaceReplaceWith] - The optional parameter "whiteSpaceReplaceWith" is a string
 * that specifies what character to replace whitespace with in the normalized text. If this parameter
 * is not provided, the default value is an underscore character "_".
 * @returns a normalized string with whitespace replaced by an optional parameter (default is
 * underscore), diacritics removed, and non-word characters removed. The resulting string is also
 * converted to lowercase.
 */
export function normalizeText(text: string, whiteSpaceReplaceWith?: string): string{
  whiteSpaceReplaceWith = whiteSpaceReplaceWith ?? '_'
  return text.normalize('NFD')
              .split(' ').join('_')
              .replace(/[\u0300-\u036f]/g, '')
              .replace(/\W/g, '')
              .split(' ').join(whiteSpaceReplaceWith)
              .toLowerCase()
}
