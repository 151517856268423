import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
declare var $: any;

@Component({
  selector: 'app-create-gift-card',
  templateUrl: './create-gift-card.component.html',
  styleUrls: ['./create-gift-card.component.scss']
})
export class CreateGiftCardComponent implements OnInit {
  myForm!: FormGroup;
  amountFormArray!: FormArray;

  giftCard = {
    name: '',
    validMonths: 0,
    stock: 999999,
    status: 1,
  };
  
  quantityArray: number[] = Array.from({ length: 999 }, (_, index) => index + 1);
  monthsArray: number[] = Array.from({ length: 12 }, (_, index) => index + 1);
  saveGiftCardType = false;
  
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    public themingService: ThemingService,
  ) {
    this.amountFormArray = this.formBuilder.array([this.createAmountControl()]);
    this.myForm = this.formBuilder.group({
      giftCardName: [''],
      giftCardValidMonths: [''],
      giftCardStock: [''],
      giftCardStatus: [true],
      amount: this.amountFormArray,
    });
  }

  ngOnInit(): void {
    this.saveGiftCardType = false;
  }

  getFormControl(i: number): FormControl {
    return this.amountFormArray.at(i) as FormControl;
  }

  createAmountControl(): FormControl {
    return this.formBuilder.control(0);
  }

  addAmount(): void {
    this.amountFormArray.push(this.createAmountControl());
  }

  removeAmount(index: number): void {
    this.amountFormArray.removeAt(index);
  }

  ChangeGiftCardName(event: Event) {
    const element = event.target as HTMLInputElement;
    this.giftCard.name = element.value;
  }

  ChangeGiftCardValidMonths(event: Event) {
    const element = event.target as HTMLInputElement;
    this.giftCard.validMonths = parseFloat(element.value) || 0;
  }

  ChangeGiftCardStock(event: Event) {
    const element = event.target as HTMLInputElement;
    this.giftCard.stock = parseFloat(element.value) || 0;
  }

  ChangeGiftCardStatus(event: Event) {
    const element = event.target as HTMLInputElement;
    this.giftCard.status = element.checked ? 1 : 0;
  }

  validateFormData() {
    const isValidAmount = this.amountFormArray.controls.some(control => {
        const amountValue = control.value;
        return amountValue && amountValue !== '0';
    });
    if (!isValidAmount || !this.giftCard.name || this.amountFormArray.controls.length === 0) {
        $('#requiredModal').modal('show');
        return;
    }
    if (this.myForm.valid) {
        this.createGiftCardType();
    }
  }

  createGiftCardType() {
    const amount: string = this.amountFormArray.value.join(',');
    $('#giftCardLoadingModal').modal({ backdrop: 'static', keyboard: false });
    $('#giftCardLoadingModal').modal('show');
    this.saveGiftCardType = false;
  
    this.apiService.createGiftCardType(
      this.giftCard.name,
      this.giftCard.validMonths,
      this.themingService.shopId,
      amount, 
      this.giftCard.stock,
      this.giftCard.status,
    )
      .then((result: any) => {
        if (result && Object.keys(result).length > 0) {
          this.saveGiftCardType = true;
          this.resetForm();
          $('#giftCardLoadingModal').modal('hide');
          $('#successGiftCardModal').modal('show');
        } else {
          $('#errorGiftCardModal').modal('show');
          $('#giftCardLoadingModal').modal('hide');
        }
      })
      .catch((error) => {
        console.error(error);
        $('#errorGiftCardModal').modal('show');
        $('#giftCardLoadingModal').modal('hide');
      });
  }
  closeModal() {
    $('#successGiftCardModal').modal('hide');
    $('#errorGiftCardModal').modal('hide');
    $('#requiredModal').modal('hide');
  }
  resetForm() {
    this.giftCard.name = ''; 
    this.giftCard.validMonths = 0; 
    this.giftCard.stock = 999999;
    this.amountFormArray = this.formBuilder.array([this.createAmountControl()]);
  }
  
}