import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ThemingService } from 'src/app/theming.service';
import { AuthService } from 'src/app/auth.service';
declare var $: any;

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  permissions: any = {};

  constructor(
    private router: Router,
    public themingService: ThemingService,
    private authService: AuthService 
  ) {}

  ngOnInit(): void {
    this.authService.getUserPermissions().then((permissions) => {
      this.permissions = permissions;
    }).catch((error) => {
      console.error('Error al obtener permisos del usuario:', error);
    });
  }

  onSignOut() {
    this.authService.onSignOut().then(() => {
      this.router.navigate(['/login']);
    });
  }

  openModal() {
    $('#settingsModal').modal('show');
  }
}
