import { Component, OnInit } from '@angular/core';
import { AuthService, alertsAnimation } from 'src/app/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ThemingService, ShopTheming } from 'src/app/theming.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public shopTheme: ShopTheming[] = [];
  public  shopPlan: string = '';
  public planClass = '';
  permissions: any = {};

  constructor(
    private authService: AuthService,
    private themingService: ThemingService,
    private router: Router
  ) {
    this.shopTheme = this.themingService.shopTheming;
  }


  ngOnInit(): void {
    this.authService.getUserPermissions().then((permissions: any) => {
      this.permissions = permissions;  
    }).catch((error) => {
      console.error('Error al obtener permisos del usuario:', error);
    });
    this.loadCurrentPlan();
  }


  loadCurrentPlan() {
    let currentPlan = this.shopTheme[0].currentContract;

    if (currentPlan === 'Basic') {
      this.shopPlan = currentPlan;
      this.planClass = 'basic';
    } else if (currentPlan === 'Advanced') {
      this.shopPlan = currentPlan;
      this.planClass = 'advanced';
    } else if (currentPlan === 'Corporate') {
      this.shopPlan = currentPlan;
      this.planClass = 'corporate';
    }
  }

  /**
   * The function calls the onSignOut() function from the authService, which returns a promise. When
   * the promise is resolved, the user is redirected to the home page
   */
  onSignOut() {
    this.authService.onSignOut().then(() => {
      this.router.navigate(['/']);
    });
  }
}
