<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>

<div class="container">
  <div class="cashRegister-container">
  <div class="header">
    <h1 class="title">Caja {{ cashRegister?.cashRegisterId }}</h1>
    <app-back-arrow [route]="'/cashRegisters'"></app-back-arrow>
    </div>
    <div class="loading" *ngIf="loading">
      <div class="spinner-border" role="status"></div>  
    </div>
    <div *ngIf="!loading" class="info-container">
      <div class="form-card info">
        <h3 class="subtitle">Montos</h3>
        <table class="cash-register-info-container">
          <tr *ngFor="let paymentType of paymentTypeTotal">
            <td>{{ paymentType.paymentTypeId === 1 ? 'Tarjeta de Crédito' : 
                  paymentType.paymentTypeId === 2 ? 'SINPE' :
                  paymentType.paymentTypeId === 3 ? 'Transferencia Bancaria' :
                  paymentType.paymentTypeId === 4 ? 'Efectivo' : '' }}</td>
            <td>{{ appComponent.formatMoney(paymentType.paymentTypeTotal) }}</td>
          </tr>
          <tr class="subtle-divider">
            <td class="form-label">Subtotal</td>  
            <td>{{ appComponent.formatMoney(subtotal) }}</td>
          </tr>
          <tr class="subtle-divider">
            <td>Monto inicial</td>  
            <td> {{appComponent.formatMoney(cashRegister.initialAmount)}}</td>
          </tr>
          <tr>
            <td>Depósitos</td>  
            <td> {{appComponent.formatMoney(cashRegister.cashDeposit)}}</td>
          </tr>
          <tr>
            <td>Retiros</td>  
            <td>{{appComponent.formatMoney(cashRegister.cashWithdrawn)}}</td>
          </tr>
          <tr class="subtle-divider">
            <td class="form-label">Total</td>
            <td class="form-label">{{ appComponent.formatMoney(totalPaymentAmount) }}</td>
          </tr>
        </table>      
      </div>
      
      <div class="form-card info">
        <h3 class="subtitle">Información de la Caja</h3>
        <table class="cash-register-info-container">
          <tr>
            <td>Vendedor:</td>
            <td>{{ vendorInfo.firstName }} {{ vendorInfo.lastName }}</td>
          </tr>
          <tr>
            <td>Estado:</td>
            <td>{{ cashRegister.status == 0 ? 'Cerrada' : 'Abierta' }}</td>
          </tr>
          <tr>
            <td>Fecha de Apertura:</td>
            <td>{{ appComponent.formatDate(cashRegister?.openDate) }}</td>
          </tr>
          <tr>
            <td>Fecha de Cierre:</td>
            <td>{{ cashRegister.status == 0 ? (appComponent.formatDate(cashRegister.closeDate)) : 'Pendiente' }}</td>
          </tr>
          <tr>
            <td class="form-label">Monto de Cierre:</td>
            <td class="form-label">{{ cashRegister.status == 0 ? (appComponent.formatMoney(cashRegister.finalAmount)) : 'Pendiente' }}</td>
          </tr>
        </table>
      </div>
    </div>

    <div *ngIf="!loading" class="form-card">
      <h3 class="subtitle">Lista de pagos</h3>
      <table class="items-container table table-bordered table-hover">
        <thead>
          <th scope="col" class="table-start">Id</th>
          <th scope="col">Tipo</th>
          <th scope="col">Fecha</th> 
          <th scope="col" class="table-end">Monto</th>
        </thead>
        <tbody>
          <tr *ngFor="let payment of salePayments">
            <td>{{ payment.salePaymentId }}</td>
            <td>{{ payment.paymentTypeId === 1 ? 'Tarjeta de Crédito' : 
                  payment.paymentTypeId === 2 ? 'SINPE' :
                  payment.paymentTypeId === 3 ? 'Transferencia Bancaria' :
                  payment.paymentTypeId === 4 ? 'Efectivo' : '' }}</td>
            <td>{{ appComponent.formatDate(payment.salePaymentDate) }}</td>
            <td>{{ appComponent.formatMoney(payment.salePaymentAmount) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
