<div class="container">
  <div class="container-gift-card">
    <div class="loading" *ngIf="loading">
      <div class="spinner-border" role="status"></div>
    </div>
    <div class="form-card" *ngIf="!loading">
      <div class="gift-card-info-container">
        <div class="column-container">
          <div class="column-info">
            <div  class="info-container">
              <div class="form-label">Nombre:</div>
              {{ giftCard.name }}
            </div>
            <div class="info-container">
              <div class="form-label">Valor:</div>
              {{  appComponent.formatMoney(parseAmount(giftCard.amount)) }}
            </div>
            <div  class="info-container">
              <div class="form-label">Código:</div>
              {{ giftCard.code }}
            </div>
          </div>
          <div class="column-info">
            <div class="info-container">
              <div class="form-label">Estado:</div>
              <span *ngIf="giftCard.status === 0">Inactiva</span>
              <span *ngIf="giftCard.status === 1">Activa</span>
              <span *ngIf="giftCard.status !== 0 && giftCard.status !== 1">Pendiente</span>
            </div>
            <div class="info-container">
              <div class="form-label">Saldo:</div>
              {{  appComponent.formatMoney(parseAmount(giftCard.balance)) }}
            </div>
            <div  class="info-container">
              <div class="form-label">Vigencia:</div>
              {{ giftCard.validMonths }} {{ giftCard.validMonths === 1 ? 'mes' : 'meses' }}
            </div>
          </div>
        </div>
        <div class="images-container">
          <div class="form-label">Imagen:</div>
          <div class="image-list">
            <div class="image-box">
              <img *ngIf="giftCard.images" [src]="giftCard.images" />
              <span *ngIf="!giftCard.images">Sin Imagen</span>
            </div>
          </div>
        </div>
        <div  class="info-container">
          <div class="form-label">Fecha de compra:</div>
          {{ formatDate(giftCard.date)  }}
        </div>
        <div  class="info-container">
          <div class="form-label">Correo electronico:</div>
          {{ giftCard.giftCardEmail }}
        </div>
        <div  class="info-container">
          <div class="form-label">Tipo de entrega:</div>
          {{ giftCard.isVirtual === 1 ? "Virtual" : "Fisica" }}
        </div>
        <div class="info-container" *ngIf="giftCard.giftCardExactDirection && giftCard.giftCardExactDirection.trim() !== ''">
          <div class="form-label">Dirección exacta:</div>
          {{ giftCard.giftCardExactDirection }}
        </div>        
        <div class="info-container" *ngIf="giftCard.giftCardMessage && giftCard.giftCardMessage.trim() !== ''">
          <div class="form-label">Mensaje personalizado:</div>
          {{ giftCard.giftCardMessage }}
        </div>
      </div>
    </div>
  </div>
</div>