<div class="container">
    <div class=" container-inventory">
        
    <div class="header">
        <h1 class="title">Nuevo proveedor</h1>
        <app-back-arrow-settings [callback]="setListingSuppliers"></app-back-arrow-settings>
    </div>
    <form #myForm="ngForm">
        <div class="form-card">
          <label class="form-label is-required" for="supplier_name">Nombre</label>
          <input type="text" class="form-control" id="supplierName" placeholder="Nombre del proveedor"
          (keyup)="changeSupplierName($event)" value="{{supplier.name}}" #supplierName/>

          <label class="form-label" for="supplier_description">Descripción</label>
          <textarea class="form-control" id="supplierDescription" placeholder="Descripción del proveedor"
                    (keyup)="ChangeSupplierDescription($event)" #supplierDescription>{{supplier.description}}</textarea>
          <div class="form-buttons">
              <button type="button" class="btn btn-outline-secondary submit-btn" (click)="validateFormData()">
                Crear
              </button>
            </div>   
        </div>  
      </form>
    </div>
</div>

<div class="modal supplier-confirm-modal" id="supplierLoadingModal" tabindex="-1">
    <div class="modal-dialog supplier-modal-dialog" role="document">
      <div class="modal-content supplier-modal-content">
        <div class="modal-body supplier-modal-body">
          <div class="loading">
            <div class="spinner-border" role="status"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal supplier-confirm-modal" id="successSuppliersModal" tabindex="-1">
    <div class="modal-dialog supplier-modal-dialog" role="document">
      <div class="modal-content supplier-modal-content">
        <div class="modal-body supplier-modal-body">
          <h3 class="text-center">¡Éxito!</h3>
          <p class="text-center">¡El proveedor fue creado exitosamente!</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal error-confirm-modal fade" id="requiredSupplierModal" tabindex="-1">
    <div class="modal-dialog error-modal-dialog" role="document">
      <div class="modal-content error-modal-content">
        <div class="modal-body error-modal-body">
          <h3 class="text-center">¡Error!</h3>
          <p class="text-center">Debe asignar un nombre al Proveedor</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal supplier-confirm-modal fade" id="errorSuppliersModal" tabindex="-1">
    <div class="modal-dialog supplier-modal-dialog" role="document">
      <div class="modal-content supplier-modal-content">
        <div class="modal-body supplier-modal-body">
          <h3 class="text-center">¡Error!</h3>
          <p class="text-center">¡Hubo un error al guardar los cambios!</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>

