<div class="card">
  <div class="illustration">
    <img src="../../../assets/illustrations/sign-in.png" />
  </div>
  <div class="form">
    <h2>CAMBIAR CONTRASEÑA</h2>
    <label class="info-message">Se enviará un <strong>código de verificación</strong> al correo para hacer el cambio de contraseña</label>
    <form action="post" [formGroup]="sendCodeForm" (ngSubmit)="onSendCode()">
      <div class="form-group">
        <div class="alert-span-exeption">
          <span *ngIf="exceptionMessage != ''" [@inOutAnimation] class="error-message">{{exceptionMessage}}</span>
        </div>
        <div class="alert-span" *ngIf="sendCodeFormValidation['givenEmail'].touched && sendCodeFormValidation['givenEmail'].invalid">
          <label class="error-message" *ngIf="sendCodeFormValidation['givenEmail'].errors && sendCodeFormValidation['givenEmail'].errors['required']" [@inOutAnimation]>
            Este campo es requerido
          </label>
          <label class="error-message" *ngIf="sendCodeFormValidation['givenEmail'].errors && sendCodeFormValidation['givenEmail'].errors['pattern'] && sendCodeFormValidation['givenEmail'].errors['email']" [@inOutAnimation]>
            Formato de correo incorrecto
          </label>
        </div>
        <input type="text" name="email" placeholder="Tu correo electrónico" class="textInput" formControlName="givenEmail">
      </div>
      <div class="form-buttons">
        <button type="submit" [disabled]="sendCodeForm.invalid" class="submit-btn">Enviar código</button><br>
      </div>
    </form>
  </div>
</div>
