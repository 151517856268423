<div class="container">
  <div class="container-shopPoint">
    <div class="header">
      <h1 class="title">Puntos de venta</h1>
      <button class="btn btn-outline-primary primary-btn" (click)="setCreatingShopPoint()">Nuevo</button>
    </div>
    <div class="form-card">
      <div class="search-container visually-hidden">
        <input
          type="text"
          placeholder="Buscar"
          [(ngModel)]="searchString"
          (keyup)="searchShopPoints()"
        />
      </div>
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>  
      </div>
      <table *ngIf="!loading" class="table table-bordered table-hover">
        <thead>
          <th scope="col" class="table-start">Nombre</th>
          <th scope="col">Ubicación</th>
          <th scope="col">Editar</th>
          <th scope="col" class="table-end">Eliminar</th>
        </thead>
        <tbody>
          <tr *ngFor="let shopPoint of shopPoints">
            <td>
              <a [routerLink]="['/shopPoints', shopPoint.shopPointId]" routerLinkActive="active">{{ shopPoint.shopPointName }}</a>
            </td>
            <td>{{shopPoint.shopPointLocation}}</td>
            <td>
              <button
                class="btn btn-primary primary-btn"
                (click)="setEditingShopPoint(shopPoint.shopPointId)"
              >
                Editar
              </button>
            </td>
            <td>
              <button
                class="btn btn-primary remove-btn"
                (click)="modalDelete = true;  shopPointIdToDelete = shopPoint.shopPointId"
              >
                Eliminar
              </button>
            </td>
          </tr>  
        </tbody>
      </table>
    </div>
  </div>
</div>
<app-confirmation-modal *ngIf="modalDelete" [title]="'¿Seguro que quiere eliminar este punto de venta?'" [message]="'Al eliminar este punto de venta, no se podrá restaurar una vez eliminado'"
  [confirmText]="'Eliminar'" [cancelText]="'Cancelar'" (response)="deleteShopPoint($event)">
</app-confirmation-modal>

<div class="modal delete-shopPoint-confirm-modal" id="deleteShopPointModal" tabindex="-1">
  <div class="modal-dialog shopPoint-modal-dialog" role="document">
    <div class="modal-content shopPoint-modal-content">
      <div class="modal-body shopPoint-modal-body">
        <h3 class="text-center">¡Éxito!</h3>
        <p class="text-center">¡El punto de venta fue eliminado exitosamente!</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
      </div>
    </div>
  </div>
</div>
