<div class="container">
  <div class="container-inventory">
      <div class="header">
          <h5 class="title">Nuevo menú</h5>
      </div>
      <form #myForm="ngForm">
        <div class="loading" *ngIf="loading">
            <div class="spinner-border" role="status"></div>
        </div>
        <div class="form-card"  *ngIf="!loading">
              <label class="form-label is-required" for="menu_name">Nombre</label>
              <input type="text" class="form-control" id="menuName" placeholder="Nombre del menú"
              (keyup)="ChangeMenuName($event)" [(ngModel)]="menu.menuName" #menuName="ngModel" name="menuName" required/>            
              <div class="input-container">
                  <div>
                      <a class="create-link" (click)="addMenuOption()">
                          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 48 48">
                              <path fill="none" stroke="#6BBECD" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M16 6H8a2 2 0 0 0-2 2v8m10 26H8a2 2 0 0 1-2-2v-8m26 10h8a2 2 0 0 0 2-2v-8M32 6h8a2 2 0 0 1 2 2v8m-10 8H16m8 8V16"/>
                          </svg>
                      </a>
                  </div>
                  <div *ngFor="let menuOption of menuOptions; let i = index" class="form-info">
                      <div class="radios">
                        <div class="form-floating col-md-5">
                            <input type="text" class="form-control" id="option" placeholder="Nombre de la opción"
                            [(ngModel)]="menuOption.optionName" name="optionName{{i}}" required>                          
                          <label for="option">Nombre de la opción</label>
                      </div>
             
                      </div>
                      <div class="radios">
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="inlineRadioOptions{{i}}" value="categories" (change)="onOptionChange('categories', i)">
                              <label class="form-check-label" for="inlineRadioOptions{{i}}">Categorías</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" id="inlineRadioOptions{{i}}" name="inlineRadioOptions{{i}}" value="subCategories" (change)="onOptionChange('subCategories', i)">
                              <label class="form-check-label" for="inlineRadioOptions{{i}}">Subcategorías</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" id="inlineRadioOptions{{i}}" name="inlineRadioOptions{{i}}" value="subSubcategories" (change)="onOptionChange('subSubcategories', i)">
                              <label class="form-check-label" for="inlineRadioOptions{{i}}">Sub-subcategorías</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="inlineRadioOptions{{i}}" value="collections" (change)="onOptionChange('collections', i)">
                              <label class="form-check-label" for="inlineRadioOptions{{i}}">Colecciones</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="inlineRadioOptions{{i}}" value="custom" (change)="onOptionChange('custom', i)">
                              <label class="form-check-label" for="inlineRadioOptions{{i}}">Personalizado</label>
                          </div>
                        </div>
                        <div class="select-container">
                        <div class="remove-button">
                            <a class="create-link" (click)="removeMenuOption(i)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="#e11d48" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z"/></svg>
                            </a>
                        </div>
                      <div *ngIf="menuOption.optionType === 1" class="form-floating col-md-5">
                          <select class="form-select" aria-label="Floating label select example" (change)="onCategoryChange($event, i)"required>
                              <option selected disabled>Seleccione una categoría</option>
                              <option *ngFor="let category of categories" [value]="category.categoryId">
                                  {{ category.categoryName }}
                              </option>
                          </select>
                      </div>
                      <div *ngIf="menuOption.optionType === 2" class="form-floating col-md-5">
                          <select class="form-select" aria-label="Floating label select example" (change)="onSubCategoryChange($event, i)"required>
                              <option selected disabled>Seleccione una subcategoría</option>
                              <option *ngFor="let subCategory of subCategories" [value]="subCategory.subCategoryId">
                                  {{ subCategory.subCategoryName }}
                              </option>
                          </select>
                      </div>
                      <div *ngIf="menuOption.optionType === 3" class="form-floating col-md-5">
                          <select class="form-select" aria-label="Floating label select example" (change)="onSubSubCategoryChange($event, i)"required>
                              <option selected disabled>Seleccione una sub-subcategoría</option>
                              <option *ngFor="let subSubCategory of subSubCategories" [value]="subSubCategory.subSubCategoryId">
                                  {{ subSubCategory.subSubCategoryName }}
                              </option>
                          </select>
                      </div>
                      <div *ngIf="menuOption.optionType === 4" class="form-floating col-md-5">
                          <select class="form-select" aria-label="Floating label select example" (change)="onCollectionChange($event, i)"required>
                              <option selected disabled>Seleccione una colección</option>
                              <option *ngFor="let collection of collections" [value]="collection.collectionId">
                                  {{ collection.collectionName }}
                              </option>
                          </select>
                      </div>
                      <div *ngIf="menuOption.optionType === 5" class="form-floating col-md-5">
                          <input type="text" class="form-control" id="optionUrl" placeholder="URL de la opción"
                              [(ngModel)]="menuOption.optionUrl" name="optionUrl{{i}}" (change)="onUrlChange($event, i)" required>
                          <label for="optionUrl">URL de la opción</label>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="form-buttons">
                  <button type="submit" class="btn btn-outline-secondary submit-btn" (click)="validateFormData()">
                      Crear
                  </button>
              </div>
          </div>
      </form>
  </div>
</div>

<div class="modal menu-confirm-modal fade" id="successMenuModal" tabindex="-1">
  <div class="modal-dialog menu-modal-dialog" role="document">
    <div class="modal-content menu-modal-content">
      <div class="modal-body menu-modal-body">
        <h3 class="text-center">¡Éxito!</h3>
        <p class="text-center">¡El menu fue creado exitosamente!</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal menu-confirm-modal fade" id="errorMenuModal" tabindex="-1">
  <div class="modal-dialog menu-modal-dialog" role="document">
    <div class="modal-content menu-modal-content">
      <div class="modal-body menu-modal-body">
        <h3 class="text-center">¡Error!</h3>
        <p class="text-center">¡Hubo un error al guardar los cambios!</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal menu-confirm-modal fade" id="requiredMenuModal" tabindex="-1">
    <div class="modal-dialog menu-modal-dialog" role="document">
      <div class="modal-content menu-modal-content">
        <div class="modal-body menu-modal-body">
          <h3 class="text-center">¡Error!</h3>
          <p class="text-center">¡Todos los campos son requeridos!</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>