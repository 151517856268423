<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>

<div class="container">
  <div class="container-coupons">

    <div class="header">
      <h1 class="title" *ngIf="!isEditing">Nuevo Cupón</h1>
      <h1 class="title" *ngIf="isEditing">Modificar cupón</h1>
      <app-back-arrow [route]="'/coupons'"></app-back-arrow>
    </div>
    <div class="loading" *ngIf="loading">
      <div class="spinner-border" role="status"></div>
    </div>
    <form *ngIf="!loading" class="form-card" [formGroup]="couponForm" id="parentForm">

      <div class="form-group">
        <label class="form-label" for="couponCode">Código</label>
        <input type="text" class="form-control" id="couponCode" placeholder="Código del cupón" formControlName="couponCode"
          (keyup)="changeObjectValue($event, 'code')" value="{{coupon.couponCode}}">

          <div class="alert-span" *ngIf="couponFormValidation['couponCode'].touched && couponFormValidation['couponCode'].invalid">
            <label class="error-message" *ngIf="couponFormValidation['couponCode'].errors?.['required']">
              Este campo es requerido
            </label>
            <label class="error-message" *ngIf="couponFormValidation['couponCode'].errors?.['pattern']">
              El código debe contener mayúsculas y/o números, sin espacios
            </label>
          </div>
      </div>

      <div class="form-group">
        <label class="form-label" for="couponType">Nombre</label>
        <input type="text" class="form-control" id="couponType" placeholder="Nombre del cupón" formControlName="couponName"
          (keyup)="changeObjectValue($event, 'type')" value="{{coupon.couponType}}">

          <div class="alert-span" *ngIf="couponFormValidation['couponName'].touched && couponFormValidation['couponName'].invalid">
            <label class="error-message" *ngIf="couponFormValidation['couponName'].errors?.['required']">
              Este campo es requerido
            </label>
            <label class="error-message" *ngIf="couponFormValidation['couponName'].errors?.['pattern']">
              El nombre no debe contener caracteres especiales
            </label>
          </div>
      </div>


      <label class="form-label">Tipo de cupón</label>
      <div class="form-row form-group">
        <label for="couponByPercetage" class="form-label checkbox-label">
          <input type="radio" id="couponByPercetage" (change)="changeCheckboxState($event, 'percentage')" name="couponType"
            [checked]="coupon.couponByPercentage || true" [disabled]="inputsDisabled">
          Cupón de porcentaje
        </label>

        <label for="couponByPrice" class="form-label checkbox-label">
          <input type="radio" id="couponByPrice" (change)="changeCheckboxState($event, 'price')" name="couponType"
            [checked]="coupon.couponByPrice" [disabled]="inputsDisabled">
          Cupón de precio
        </label>
      </div>

      <div [hidden]="!isByPercentage" class="form-group">

          <label class="form-label" for="couponMinPrice">Valor en porcentaje</label>
          <input type="number" min="0" class="form-control" id="couponMinPrice" placeholder="%" formControlName="couponPercentageValue"
            (input)="changeObjectValue($event, 'percentage')" value="{{coupon.couponPercentageValue}}" />

            <div class="alert-span" *ngIf="couponFormValidation['couponPercentageValue'].touched && couponFormValidation['couponPercentageValue'].invalid">
              <label class="error-message" *ngIf="couponFormValidation['couponPercentageValue'].errors?.['required']">
                Este campo es requerido
              </label>
              <label class="error-message" *ngIf="couponFormValidation['couponPercentageValue'].errors?.['min'] || couponFormValidation['couponPercentageValue'].errors?.['max']">
                Porcentaje no permitido
              </label>
            </div>
      </div>

      <div [hidden]="isByPercentage" class="form-group">

        <label class="form-label" for="couponPrice">Valor monetario</label>
        <input min="0" class="form-control" id="couponPrice" placeholder="Valor del cupón" formControlName="couponPriceValue"
        (input)="changeObjectValue($event, 'price')" value="{{coupon.couponPriceValue}}"/>

          <div class="alert-span" *ngIf="couponFormValidation['couponPriceValue'].touched && couponFormValidation['couponPriceValue'].invalid">
            <label class="error-message" *ngIf="couponFormValidation['couponPriceValue'].errors?.['required']">
              Este campo es requerido
            </label>
            <label class="error-message" *ngIf="couponFormValidation['couponPriceValue'].errors?.['min']">
              Monto no permitido
            </label>
          </div>

      </div>

      <div class="form-group visually-hidden">
        <label class="form-label" for="couponMaxDiscountPrice">Valor máximo</label>
        <input type="number" min="0" class="form-control" id="couponMaxDiscountPrice"
          placeholder="Valor máximo de la compra para aplicar el cupón (tope)" formControlName="couponMaxValue"
          (input)="changeObjectValue($event, 'maxDiscount')" value="{{coupon.couponMaxCost}}"/>

          <div class="alert-span" *ngIf="couponFormValidation['couponMaxValue'].touched && couponFormValidation['couponMaxValue'].invalid">
            <label class="error-message" *ngIf="couponFormValidation['couponMaxValue'].errors?.['required']">
              Este campo es requerido
            </label>
            <label class="error-message" *ngIf="couponFormValidation['couponMaxValue'].errors?.['min']">
              Monto no permitido
            </label>
          </div>


      </div>

      <hr class="visually-hidden">
      <label class="form-label visually-hidden">Restricciones</label>
      <div class="form-row visually-hidden">
        <label for="couponLimitByCost" class="form-label checkbox-label">
          <input type="checkbox" id="couponLimitByCost" (change)="changeCheckboxState($event, 'limitByCost')"
          [checked]="!limitByCost" [disabled]="inputsDisabled">
          Limitar por valor de la compra
        </label>

        <label for="limitByArticlesQuantity" class="form-label checkbox-label">
          <input type="checkbox" id="limitByArticlesQuantity" [disabled]="inputsDisabled"
            (change)="changeCheckboxState($event, 'limitByArticlesQuantity')" [checked]="!limitByArticlesQuantity">
          Limitar por cantidad de artículos
        </label>
      </div>

      <div [hidden]="limitByCost" class="form-group">

        <label class="form-label" for="couponMinPrice">Valor mínimo</label>
        <input type="number" min="0" class="form-control" id="couponMinPrice"
          placeholder="Valor mínimo de la compra para poder aplicar el cupón" formControlName="couponMinPriceValue"
          (input)="changeObjectValue($event, 'minCost')" value="{{coupon.couponMinCost}}"/>

          <div class="alert-span" *ngIf="couponFormValidation['couponMinPriceValue'].touched && couponFormValidation['couponMinPriceValue'].invalid">
            <label class="error-message" *ngIf="couponFormValidation['couponMinPriceValue'].errors?.['required']">
              Este campo es requerido
            </label>
            <label class="error-message" *ngIf="couponFormValidation['couponMinPriceValue'].errors?.['min']">
              Monto no permitido
            </label>
          </div>

      </div>

      <div [hidden]="limitByArticlesQuantity" class="form-group">

        <label class="form-label" for="couponMinArticlesQuantity">Cantidad mínima de artículos</label>
        <input type="number" min="0" class="form-control" id="couponMinArticlesQuantity" formControlName="couponMinArticlesQuantity"
          placeholder="Cantidad mínima de artículos" (input)="changeObjectValue($event, 'articles')"
          value="{{coupon.couponMinArticlesQuantity}}"/>

          <div class="alert-span" *ngIf="couponFormValidation['couponMinArticlesQuantity'].touched && couponFormValidation['couponMinArticlesQuantity'].invalid">
            <label class="error-message" *ngIf="couponFormValidation['couponMinArticlesQuantity'].errors?.['required']">
              Este campo es requerido
            </label>
            <label class="error-message" *ngIf="couponFormValidation['couponMinArticlesQuantity'].errors?.['min']">
              Cantidad no permitida
            </label>
          </div>

      </div>

      <div class="visually-hidden">
        <label for="couponUses" class="form-label checkbox-label">
          <input type="checkbox" id="couponUses" (change)="changeCheckboxState($event, 'uses')" [checked]="!limitUses"
            [disabled]="inputsDisabled">
          Limitar la cantidad de usos
        </label>
      </div>

      <div [hidden]="limitUses" class="form-group">

        <label class="form-label" for="couponMaxUses">Cantidad máxima de usos</label>
        <input type="number" min="0" class="form-control" id="couponMaxUses" placeholder="Cantidad máxima de usos del cupón" formControlName="couponMaxUses"
        (input)="changeObjectValue($event, 'maxUses')" value="{{coupon.couponMaxUses}}"/>

        <div class="alert-span" *ngIf="couponFormValidation['couponMaxUses'].touched && couponFormValidation['couponMaxUses'].invalid">
            <label class="error-message" *ngIf="couponFormValidation['couponMaxUses'].errors?.['required']">
              Este campo es requerido
            </label>
            <label class="error-message" *ngIf="couponFormValidation['couponMaxUses'].errors?.['min']">
              Cantidad no permitida
            </label>
          </div>

      </div>

      <hr>

      <div class="form-group">
        <label class="form-label" for="couponDateStart">Fecha de inicio del cupón</label>
        <input type="datetime-local" class="form-control" id="couponDateStart" (keyup)="changeCouponDates($event, 1)"
          (change)="changeCouponDates($event, 1)" value="{{coupon.couponDateStart}}" [disabled]="inputsDisabled"/>
      </div>

      <div class="form-group">
        <label class="form-label" for="couponDateEnd">Fecha de cierre del cupón</label>
        <input type="datetime-local" class="form-control" id="couponDateEnd" (keyup)="changeCouponDates($event, 0)"
          (change)="changeCouponDates($event, 0)" value="{{coupon.couponDateEnd}}" [disabled]="inputsDisabled" />
      </div>

      <div class="form-group visually-hidden">
        <label class="form-label" for="product_category">Categoría</label>
        <select class="form-select" id="categoryParentCategory" (change)="changeCategoryId($event, 1)"
          [disabled]="inputsDisabled">
          <option *ngIf="coupon.couponId !== 0">
            {{ coupon.couponCategoryName }}
          </option>
          <option selected hidden disabled value="null"></option>
          <option *ngFor="let category of categories" [value]="category.categoryId">
            {{ category.categoryName }}
          </option>
        </select>
      </div>

      <div class="form-group visually-hidden">
        <label class="form-label" for="product_category">Subcategoría</label>
        <select class="form-select" id="categoryParentCategory" (change)="changeCategoryId($event, 0)"
          [disabled]="inputsDisabled">
          <option *ngIf="coupon.couponId !== 0">
            {{ coupon.couponSubCategoryName }}
          </option>
          <option selected hidden disabled value="null"></option>
          <option *ngFor="let subCategory of subCategories" [value]="subCategory.categoryId">
            {{ subCategory.categoryName }}
          </option>
        </select>
      </div>

      <div class="form-group visually-hidden">
        <label class="form-label" for="product_category">Producto</label>
        <select class="form-select" id="categoryParentCategory" disabled>
          <option selected disabled hidden>Selecione el producto al que pertenece</option>
          <option value="0">Ninguna</option>
          <option>
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="couponActive" class="form-label checkbox-label">
          <input type="checkbox" id="couponActive" (change)="changeCheckboxState($event, '')"
            [checked]="coupon.couponActive" [disabled]="inputsDisabled">
          Cupón Activo
        </label>
      </div>

      <div class="buttons-container">
        <button type="button" class="btn btn-outline-secondary primary-btn" (click)="enabledInputs()"
          [hidden]="!inputsDisabled">
          Modificar
        </button>
        <button type="button" class="btn btn-outline-secondary confirm-btn" (click)="validateFormData()"
          [hidden]="inputsDisabled">
          Aceptar
        </button>
        <button type="button" class="btn btn-outline-secondary cancel-btn" (click)="cancel()"
          [hidden]="inputsDisabled">
          Cancelar
        </button>
      </div>
    </form>
  </div>

<app-confirmation-modal *ngIf="saveCoupon"
    [title]="'Cupón creado con éxito'"
    [message]="'¿Desea crear otro cupón?'"
    [cancelText]="'No, ir a la lista de cupones'"
    [confirmText]="'Sí, crear otro cupón'"
    (response)="addModal($event)">
 ></app-confirmation-modal>

<app-confirmation-modal *ngIf="modifyCoupon"
    [title]="'Cupón actualizado'"
    [message]="'El cupón se ha actualizado con éxito'"
    [confirmText]="'Volver a cupones'"
    [twoFieldModal]="false"
    (response)="updateModal($event)">
 ></app-confirmation-modal>
