import { Component, EventEmitter, Input, Output } from '@angular/core';
import { alertsAnimation } from 'src/app/auth.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  animations: [ alertsAnimation ],
  host: {'[@inOutAnimation]':''}
})
export class ConfirmationModalComponent {

  @Input() title: string = "";
  @Input() message: string = "";
  @Input() confirmText: string = "Confirmar";
  @Input() cancelText: string = "Cancelar";
  @Input() twoFieldModal: boolean = true;

  @Output() response: EventEmitter<any> = new EventEmitter();


  constructor() { }

  cancel() {
    this.response.emit(false);
  }

  confirm() {
    this.response.emit(true);
  }

}
