import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
import { ThemingService } from 'src/app/theming.service';
declare var $: any;

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.scss']
})
export class EditClientComponent implements OnInit {
  clientId = 0;
  clientForm: FormGroup;
  loading: boolean = false;
  showPassword: boolean = false;
  access: any;

  constructor(
    public appComponent: AppComponent,
    private apiService: ApiService,
    public themingService: ThemingService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.clientForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      gender: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      nationalId: ['', Validators.required],
      isPoolRegister: [{ value: false, disabled: true }],
      username: [''],
      password: [{ value: '', disabled: true }]
    });

    this.route.paramMap.subscribe((params) => {
      let clientId = Number(params.get('clientId'));
      if (clientId != this.clientId) {
        this.appComponent.goToTop();
        this.clientId = clientId;
      }
    });
  }

  async ngOnInit(): Promise<void> {
    await this.getClient();
    this.handlePhoneInput();
    this.handleIsPoolRegisterToggle();
  }

  async getClient() {
    this.loading = true;
    try {
      const data: any = await this.apiService.getClient(this.themingService.shopId, this.clientId);
      this.clientForm.patchValue(data);
      
      const username = data.username || '';
      this.toggleControlsBasedOnUsername(username);

    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  } 

  handlePhoneInput(): void {
    this.clientForm.get('phone')?.valueChanges.subscribe((value) => {
      if (value && !value.startsWith('+')) {
        this.clientForm.get('phone')?.setValue('+' + value, { emitEvent: false });
      }
    });
  }

  handleIsPoolRegisterToggle(): void {
    this.clientForm.get('isPoolRegister')?.valueChanges.subscribe((isPoolRegister) => {
      if (isPoolRegister && !this.clientForm.get('username')?.value) {
        this.clientForm.get('password')?.enable();  
      } else {
        this.clientForm.get('password')?.disable();  
        this.clientForm.get('password')?.reset(); 
        this.showPassword = false;  
      }
    });
  }

  generatePassword(): void {
    if (this.clientForm.get('password')?.enabled) {
      const length = 12;
      const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
      let password = "";
      for (let i = 0, n = charset.length; i < length; ++i) {
        password += charset.charAt(Math.floor(Math.random() * n));
      }
      this.clientForm.controls['password'].setValue(password);
    }
  }

  togglePasswordVisibility(): void {
    if (this.clientForm.get('password')?.enabled) {
      this.showPassword = !this.showPassword;
    }
  }

  toggleControlsBasedOnUsername(username: string) {
    const isPoolRegisterControl = this.clientForm.get('isPoolRegister');
    const isPasswordControl = this.clientForm.get('password');

    if (username && username.trim() !== '') {
      isPasswordControl?.disable();
      isPoolRegisterControl?.disable();
      this.clientForm.get('isPoolRegister')?.setValue(true);
    } else {
      isPoolRegisterControl?.enable();
      isPasswordControl?.disable();
    }
  }

  updateClient(): void {
    if (this.clientForm.valid) {
      this.loading = true;
      const clientData = this.clientForm.value;

      this.apiService.updateClient(
        this.themingService.shopId,
        this.clientId,
        clientData.username,
        clientData.firstName,
        clientData.lastName,
        clientData.email,
        clientData.phone,
        clientData.nationalId,
        clientData.isPoolRegister,
        clientData.gender,
        clientData.password,
        this.access = clientData.password
      ).then((data: any) => {
        this.loading = false;
        if (data.statusCode === 200) {
          $('#successEditClientModal').modal('show');
          $('#successEditClientModal').on('hidden.bs.modal', () => {
            this.router.navigate(['/clients']);
          });
        } else {
          $('#errorEditClientModal').modal('show');
        }
      }).catch((error) => {
        console.log(error);
        $('#errorEditClientModal').modal('show');
        this.loading = false;
      });
    } else {
      $('#requiredEditClientModal').modal('show');
    }
  }

  closeModal() {
    $('#successEditClientModal').modal('hide');
    $('#errorEditClientModal').modal('hide');
    $('#requiredEditClientModal').modal('hide');
  }
}
