import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService, alertsAnimation } from 'src/app/auth.service';

@Component({
  selector: 'app-send-code',
  templateUrl: './send-code.component.html',
  styleUrls: ['./send-code.component.scss'],
  animations: [ alertsAnimation ]
})
export class SendCodeComponent {
  @Input() codePageActive: boolean = false;
  @Output() passworPage = new EventEmitter<boolean>();
  @Output() userEmail = new EventEmitter<string>();

  exceptionMessage: String = "";
  public sendCodeForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService)
  {
    /* Creating a form group with a single form control called givenEmail. */
    this.sendCodeForm = this.fb.group({
      "givenEmail": ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
      ]]
    });
  }

  /**
   * It returns the controls of the sendCodeForm
   * @returns The form controls.
   */
  get sendCodeFormValidation() {
    return this.sendCodeForm.controls;
  }

  /**
   * The function calls the forgotPassword() function from the authService, which sends a password
   * reset email to the user. If the email is sent successfully, the function emits the codePageActive
   * variable to the parent component, which changes the view to the code page. If the email is not
   * sent successfully, the function emits the exceptionMessage variable to the parent component, which
   * displays the error message to the user
   */
  onSendCode(){
    this.authService.forgotPassword(this.sendCodeForm.value.givenEmail)
    .then(()=>{
      this.passworPage.emit(this.codePageActive);
      this.userEmail.emit(this.sendCodeForm.value.givenEmail);
    })
    .catch(error =>{
      this.exceptionMessage = this.authService.handleErrors(error.message || JSON.stringify(error), this.sendCodeForm.value.givenEmail);
    });
  }
}
