import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { ThemingService } from './theming.service';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  websocketURL =
    'wss://8zqse8z6y0.execute-api.us-east-1.amazonaws.com/' + environment.stage;

  websocket: any;
  constructor(
    public themingService: ThemingService,
    public authService: AuthService,
    public router: Router
  ) {
    this.createWebSocket();
  }

  createWebSocket() {
    if (this.websocket == undefined) {
      this.websocket = new WebSocket(this.websocketURL);
      this.websocket.addEventListener('open', (event: any) => {
        console.log('Connected');
        this.authService
          .currentAuthUser()
          .then(async () => {
            let aux_this = this;
            await Notification.requestPermission().then(function (permission) {
              if (permission == 'granted') {
                aux_this.sendShopId();
              } else {
                //Display message
              }
              console.log('permiss', permission);
            });
          })
          .catch(() => {});
      });
      this.websocket.addEventListener('close', (event: any) => {
        console.log(event);
        console.log('Disconnected');
        this.websocket = undefined;
        this.createWebSocket();
      });
      this.websocket.addEventListener('message', (event: any) => {
        let message = JSON.parse(event.data);
        //console.log(message);
        if (message.action == 'newSale') {
          let aux_this = this;

          let title = '¡Nueva venta!';
          //let icon = '../favicon.ico';
          let icon = `https://mobydyg-files.s3.amazonaws.com/${this.themingService.shopId}/${environment.stage}/images/logos/favicon.ico`;
          let body = 'Haz click para ver';

          var notification = new Notification(title, { body, icon });
          notification.onclick = function () {
            //aux_this.router.navigateByUrl(`/orders${message.saleId}`);
            aux_this.router.navigate(['/orders', message.saleId]);
            window.parent.focus();
            setTimeout(() => {
              window.location.reload();
            }, 10);
            notification.close();
          };
          console.log(message.saleId);
        }
      });
    }
  }

  sendShopId() {
    console.log('Shop sent');
    this.websocket.send(
      JSON.stringify({
        action: 'sendShop',
        shopId: this.themingService.shopId,
      })
    );
  }
}
