import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { ThemingService } from 'src/app/theming.service';
import { ApiService } from 'src/app/api.service'; 
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  @ViewChild('editor') editorElement!: ElementRef;
  modules = {};
  editor: any;
  termsAndConditionsContent: string = ''; 

  loading = false;
  isEditingTerms: boolean = false;
 
  constructor(
    private appComponent: AppComponent,
    private themingService: ThemingService,
    private apiService: ApiService 
  ) {}

  ngOnInit(): void {
    this.appComponent.goToTop();
    const url = this.themingService.termsAndConditions;
    console.log("url terms: ",url)
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    const self = this;
    xhr.onreadystatechange = function process() {
      if (xhr.readyState == 4 && xhr.status === 200) {
        self.termsAndConditionsContent = xhr.responseText;
      }
    };
    xhr.send();
  }

  switchEditingTerms(){
    this.isEditingTerms = !this.isEditingTerms;
  }

  saveTermsAndConditions() {
    this.loading = true;
    console.log("html a subir",this.termsAndConditionsContent)
    let stage = environment.stage == "test"? "dev": environment.stage; 
    this.apiService.uploadHtmlContent(`${this.themingService.shopId}/${stage}/config-files/terms-file.html`, this.termsAndConditionsContent, {})
    .then((response: any) => {
      console.log('Changes saved successfully:', response);
      $('#successTermsModal').modal('show');
    })
    .catch((error: any) => {
      console.error('Error saving changes:', error);
      $('#errorTermsModal').modal('show');
    });
    this.switchEditingTerms();
  }

  closeModal(){
    $('#successTermsModal').modal('hide');
    $('#errorTermsModal').modal('hide');
  }
}