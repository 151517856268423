import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
import { SettingsService } from 'src/app/settings.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-create-subcategory',
  templateUrl: './create-subcategory.component.html',
  styleUrls: ['./create-subcategory.component.scss']
})
export class CreateSubcategoryComponent implements OnInit {
  @Output() subCategoryCreated = new EventEmitter<void>();
  @Input() categoryId!: string;
  subCategory = {
    categoryId: 0,
    subCategoryName: '',
    subCategoryPosition: 0,
    menuSubCategory: 0,
    homeSubCategory: 0,
    subCategoryDesignType: 1,
    subCategoryImageUrl: ''
  }

  saveSubCategory = false;
  subCategories: any[] = [];
  category: any;
  categories: any[] = [];
  imageUrl: SafeUrl | null = null;

  constructor(
    private apiService: ApiService,
    public themingService: ThemingService,
    private settingsService: SettingsService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.saveSubCategory = false;
    this.getCategories();
  }

  getCategoryNameById(categoryId: string): string {
    const category = this.categories.find((cat: any) => cat.categoryId === categoryId);
    return category ? category.categoryName : 'Nombre de la categoría no encontrado';
}

  changeSubCategoryName(event: Event) {
    const element = event.target as HTMLInputElement;
    this.subCategory.subCategoryName = element.value;
  }

  triggerFileInput() {
    const fileInput = document.getElementById('optionImage') as HTMLInputElement;
    fileInput.click();
  }

  private resizeImage(image: HTMLImageElement, maxWidth: number, maxHeight: number): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    let width = image.width;
    let height = image.height;

    if (width > height) {
      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }
    }

    canvas.width = width;
    canvas.height = height;

    const context = canvas.getContext('2d');
    context?.drawImage(image, 0, 0, width, height);

    return canvas;
  }

  private convertCanvasToFile(canvas: HTMLCanvasElement, fileName: string): File {
    const dataURL = canvas.toDataURL('image/jpeg');
    const base64Data = dataURL.split(',')[1];
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: 'image/jpeg' });
    return new File([blob], fileName);
  }

  async onImageChange(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = async (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(image.src); // Actualizar previsualización

        image.onload = async () => {
          const resizedImage = this.resizeImage(image,  1400, 250);
          const resizedFile = this.convertCanvasToFile(resizedImage, file.name);

          try {
            const imageData = await this.uploadImage(resizedFile);
            this.subCategory.subCategoryImageUrl = imageData.imageURL; // Actualizar URL de la imagen en el objeto categoría
          } catch (error) {
            console.error('Error uploading image:', error);
          }
        };
      };

      reader.readAsDataURL(file);
    }
  }

  removeImage() {
    this.imageUrl = null;
    this.subCategory.subCategoryImageUrl = ''; 
    const fileInput = document.getElementById('optionImage') as HTMLInputElement;
    fileInput.value = ''; 
  }


  uploadImage(image: File): Promise<any> {
    return this.blobFile(image)
      .then((res: any) => {
        const timestamp = Date.now();
        const ext = image.name.split('.').pop();
        const key = `${this.themingService.shopId}/${environment.stage}/images/subcategory/banner${timestamp}.${ext}`;
        return this.apiService.uploadFile(key, res.blob, { position: 1, type: 'original' })
          .then((data: any) => {
            return {
              imageURL: data.imageURL,
              blob: res.blob,
            };
          });
      });
  }

  blobFile = async ($event: any): Promise<any> => {
    try {
      const unSafeImg = window.URL.createObjectURL($event);
      const img = this.sanitizer.bypassSecurityTrustUrl(unSafeImg);
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onload = () => {
          resolve({
            blob: $event,
            img,
            base: reader.result
          });
        };
        reader.onerror = (error) => {
          reject({
            blob: $event,
            img,
            base: null
          });
        };
        reader.readAsDataURL($event);
      });
    } catch (e) {
      return Promise.reject(null);
    }
  };

  validateFormData() {
    this.createSubCategory();
  }

  createSubCategory() {
    if (!this.subCategory.subCategoryName || this.subCategory.subCategoryName.trim() === '') {
      $('#requiredSubCategoryModal').modal('show');
      return;
    }
    $('#subCategoryLoadingModal').modal({ backdrop: 'static', keyboard: false });
    $('#subCategoryLoadingModal').modal('show');
    this.saveSubCategory = false;
    const categoryIdNumber: number = parseInt(this.categoryId, 10);
    this.apiService.createSubCategory(
      this.themingService.shopId,
      this.subCategory.subCategoryName,
      this.subCategory.subCategoryPosition,
      this.subCategory.menuSubCategory,
      this.subCategory.homeSubCategory,
      categoryIdNumber,
      this.subCategory.subCategoryDesignType,
      this.subCategory.subCategoryImageUrl
    ).then((data: any) => {
      if (data.status === 200) {
        $('#subCategoryLoadingModal').modal('hide');
        $('#successSubCategoryModal').modal('show');
        this.saveSubCategory = true;
        this.resetForm();
        setTimeout(() => {
          this.subCategoryCreated.emit();
        }, 2000); 
      } else {
        $('#errorSubCategoryModal').modal('show');
        $('#subCategoryLoadingModal').modal('hide');
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  getCategories() {
    this.apiService
      .getCategories(
        this.themingService.shopId
      ).then((data: any) => {
        this.categories = data.categories;
      }).catch((error) => {
        console.error(error);
      });
  }

  resetForm() {
    this.subCategory.subCategoryPosition = 0;
    this.subCategory.menuSubCategory = 0;
    this.subCategory.homeSubCategory = 0;
    this.subCategory.subCategoryName = '';
  }

  closeModal() {
    $('#successSubCategoryModal').modal('hide');
    $('#errorSubCategoryModal').modal('hide');
    $('#requiredSubCategoryModal').modal('hide');
  }

  preventNegativeNumbers(event: KeyboardEvent) {
    if (event.key === '-' || event.key === '+') {
      event.preventDefault(); 
    }
  }

  setListingSubCategories() {
    this.settingsService.setListingSubCategories();
  }
}

