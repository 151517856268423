import { Component, OnInit, Input } from '@angular/core';
import { SettingsService } from 'src/app/settings.service';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
declare var $: any;

@Component({
  selector: 'app-edit-collection',
  templateUrl: './edit-collection.component.html',
  styleUrls: ['./edit-collection.component.scss']
})
  export class EditCollectionComponent implements OnInit {

  collection: any;
  collections: any = [];
  loading: boolean = true;

  @Input()
  collectionId!: string;

  constructor(
    private settingsService: SettingsService,
    private apiService: ApiService,
    private themingService: ThemingService,
  ) { }

  ngOnInit(): void {
    this.getEditingCollection();
    this.getCollectionsById(parseInt(this.collectionId),'');
  }

  getEditingCollection(){
    this.collectionId = this.settingsService.getEditingCollection();
  }

  setListingCollections(){
    this.settingsService.setListingCollections();
  }

  async getCollectionsById(id: number, filter: string) {
    try {
      this.loading = true; 
      const data: any = await this.apiService.getCollectionById(id, this.themingService.shopId);
      console.log(data);
      this.collection = data.collection;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
  
  changeCollectionName(event: Event){
    const element = event.target as HTMLInputElement;
    this.collection.collectionName = element.value;
  }

  ChangeCollectionDescription(event: Event){
    const element = event.target as HTMLInputElement;
    this.collection.collectionDescription = element.value;
  }

  validateFormData(){
    this.loading = true;
    this.apiService.updateCollection(
      parseInt(this.collectionId),
      this.collection.collectionName,
      this.themingService.shopId,
      this.collection.collectionDescription,
    )
    .then((data: any) => {
      console.log(data);
      if(data.status === 200){
        $('#successCollectionModal').modal('show');
        this.loading = false;
      }
      else{
        $('#errorCollectionModal').modal('show');
        this.loading = false;
      }
    })
    .catch((error) => {
      console.error(error);
    });
  
    }
    closeModal(){
      $('#successCollectionModal').modal('hide');
      $('#errorCollectionModal').modal('hide');
    }
}
