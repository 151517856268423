import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-gift-card-thumbnail',
  templateUrl: './gift-card-thumbnail.component.html',
  styleUrls: ['./gift-card-thumbnail.component.scss']
})
export class GiftCardThumbnailComponent implements OnInit {
  @Input() giftCard: any;  
  constructor() { }

  ngOnInit(): void {
  }
}
