<div class="container-inventory">
  <div class="label">
    <div class="label-group">
      <i class="feather" data-feather="image"></i>
      <strong>Componentes</strong>
    </div>
    <ng-container *ngIf="!isCreatingComponent && !isEditingComponent">
      <a class="create-link" (click)="toggleComponentVisibility(true)">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
          <path fill="#EA803F" d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2M8 16V4h12l.002 12z"/>
          <path fill="#EA803F" d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4zm11-2h-2v3h-3v2h3v3h2v-3h3V9h-3z"/>
        </svg>
      </a>
    </ng-container>
    <ng-container *ngIf="isCreatingComponent">
      <a class="create-link" (click)="cancelCreation()">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
          <path fill="#D44646" d="M4 20h14v2H4a2 2 0 0 1-2-2V6h2zM20.22 2H7.78C6.8 2 6 2.8 6 3.78v12.44C6 17.2 6.8 18 7.78 18h12.44c.98 0 1.78-.8 1.78-1.78V3.78C22 2.8 21.2 2 20.22 2M19 13.6L17.6 15L14 11.4L10.4 15L9 13.6l3.6-3.6L9 6.4L10.4 5L14 8.6L17.6 5L19 6.4L15.4 10z"/>
        </svg>
      </a>
    </ng-container>
    <ng-container *ngIf="isEditingComponent">
      <a class="create-link" (click)="cancelEdition()">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
          <path fill="#D44646" d="M4 20h14v2H4a2 2 0 0 1-2-2V6h2zM20.22 2H7.78C6.8 2 6 2.8 6 3.78v12.44C6 17.2 6.8 18 7.78 18h12.44c.98 0 1.78-.8 1.78-1.78V3.78C22 2.8 21.2 2 20.22 2M19 13.6L17.6 15L14 11.4L10.4 15L9 13.6l3.6-3.6L9 6.4L10.4 5L14 8.6L17.6 5L19 6.4L15.4 10z"/>
        </svg>
      </a>
    </ng-container>
  </div>
</div>

<div *ngIf="isCreatingComponent">
  <app-create-component (creationSuccess)="toggleComponentVisibility(false)"></app-create-component>
</div>

<div *ngIf="isEditingComponent">
  <app-edit-component [componentId]="editComponentId"></app-edit-component>
</div>

<div class="loading" *ngIf="loading">
  <div class="spinner-border" role="status"></div>
</div>
<div *ngIf="showErrorAlert" class="alert-container" role="alert">
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24">
    <path fill="#e11d48" d="M12 17q.425 0 .713-.288Q13 16.425 13 16t-.287-.713Q12.425 15 12 15t-.712.287Q11 15.575 11 16t.288.712Q11.575 17 12 17Zm0 5q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-9q.425 0 .713-.288Q13 12.425 13 12V8q0-.425-.287-.713Q12.425 7 12 7t-.712.287Q11 7.575 11 8v4q0 .425.288.712q.287.288.712.288Z"/>
  </svg>
</div>

<div *ngIf="showSuccessAlert" class="alert-container" role="alert">
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 36 36">
    <path fill="#6BBECD" d="M18 2a16 16 0 1 0 16 16A16 16 0 0 0 18 2m10.45 10.63L15.31 25.76L7.55 18a1.4 1.4 0 0 1 2-2l5.78 5.78l11.14-11.13a1.4 1.4 0 1 1 2 2Z" class="clr-i-solid clr-i-solid-path-1"/>
    <path fill="none" d="M0 0h36v36H0z"/>
  </svg>
</div>
<div *ngIf="isListingComponent && !isCreatingComponent && !loading" class="drag-and-drop-container">
  <div class="component-container">
    <div class="tooltip-box">
      <h2 class="title">Inactivos</h2>
      <div class="hover-text ">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16"><path fill="#6BBECD" d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.496 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24c0 1.08-.635 1.594-1.244 2.057c-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987c.59-.444.965-.736.965-1.371c0-.825-.628-1.168-1.314-1.168c-.803 0-1.253.478-1.342 1.134c-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927c0-.552.425-.94 1.01-.94c.609 0 1.028.388 1.028.94c0 .533-.42.927-1.029.927"/></svg>
        <span class="tooltip-text col-md-8" id="top">Arrastre aquí los componentes para dejar de mostrarlos en su página.</span>
      </div>
    </div>
    <div class="component-list" cdkDropList #inactiveList="cdkDropList" [cdkDropListData]="inactive" [cdkDropListConnectedTo]="[activeList]" (cdkDropListDropped)="drop($event, 'inactive')">
      <div class="component-box" *ngFor="let item of inactive" cdkDrag [attr.data-componentId]="item.componentId">
        {{ item.componentTypeName }}
        <a class="edit-link"   (click)="editComponent(item.componentId)" (cdkDragStarted)="$event.preventDefault()">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
            <path fill="#EA803F" d="M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h6.525q.5 0 .75.313t.25.687t-.262.688T11.5 5H5v14h14v-6.525q0-.5.313-.75t.687-.25t.688.25t.312.75V19q0 .825-.587 1.413T19 21zm4-7v-2.425q0-.4.15-.763t.425-.637l8.6-8.6q.3-.3.675-.45t.75-.15q.4 0 .763.15t.662.45L22.425 3q.275.3.425.663T23 4.4t-.137.738t-.438.662l-8.6 8.6q-.275.275-.637.438t-.763.162H10q-.425 0-.712-.288T9 14m12.025-9.6l-1.4-1.4zM11 13h1.4l5.8-5.8l-.7-.7l-.725-.7L11 11.575zm6.5-6.5l-.725-.7zl.7.7z"/>
          </svg>
        </a>
        <a class="delete-link" *ngIf="item.status === 0" (click)="confirmDelete(item.componentId)" (cdkDragStarted)="$event.preventDefault()">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="#e11d48" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z"/></svg>
        </a>
      </div>
    </div>
  </div>

  <div class="component-container">
  <div class="tooltip-box">
    <h2 class="title">Activos</h2>
    <div class="hover-text ">
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16"><path fill="#6BBECD" d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.496 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24c0 1.08-.635 1.594-1.244 2.057c-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987c.59-.444.965-.736.965-1.371c0-.825-.628-1.168-1.314-1.168c-.803 0-1.253.478-1.342 1.134c-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927c0-.552.425-.94 1.01-.94c.609 0 1.028.388 1.028.94c0 .533-.42.927-1.029.927"/></svg>
      <span class="tooltip-text col-md-8" id="top">Arrastre aquí los componentes para mostrarlos en su página según el orden que haya seleccionado.</span>
    </div>
  </div>
    <div class="component-list" cdkDropList #activeList="cdkDropList" [cdkDropListData]="active" [cdkDropListConnectedTo]="[inactiveList]" (cdkDropListDropped)="drop($event, 'active')">
      <div class="component-box" *ngFor="let item of active" cdkDrag [attr.data-componentId]="item.componentId">
        {{ item.componentTypeName }}
        <a class="edit-link" (click)="editComponent(item.componentId)" (cdkDragStarted)="$event.preventDefault()">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
            <path fill="#EA803F" d="M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h6.525q.5 0 .75.313t.25.687t-.262.688T11.5 5H5v14h14v-6.525q0-.5.313-.75t.687-.25t.688.25t.312.75V19q0 .825-.587 1.413T19 21zm4-7v-2.425q0-.4.15-.763t.425-.637l8.6-8.6q.3-.3.675-.45t.75-.15q.4 0 .763.15t.662.45L22.425 3q.275.3.425.663T23 4.4t-.137.738t-.438.662l-8.6 8.6q-.275.275-.637.438t-.763.162H10q-.425 0-.712-.288T9 14m12.025-9.6l-1.4-1.4zM11 13h1.4l5.8-5.8l-.7-.7l-.725-.7L11 11.575zm6.5-6.5l-.725-.7zl.7.7z"/>
          </svg>
        </a>
        <a class="delete-link" *ngIf="item.status === 0" (click)="confirmDelete(item.componentId)" (cdkDragStarted)="$event.preventDefault()">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="#e11d48" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z"/></svg>
        </a>
      </div>
    </div>
  </div>
</div>

<app-confirmation-modal *ngIf="modalDelete" [title]="'¿Seguro que quiere eliminar este componente?'" [message]="'Al eliminar este componente se eliminarán los items que estén relacionados a él'"
  [confirmText]="'Eliminar'" [cancelText]="'Cancelar'" (response)="deleteComponent($event)">
</app-confirmation-modal>

<div class="modal delete-component-confirm-modal" id="deleteComponentModal" tabindex="-1">
  <div class="modal-dialog component-modal-dialog" role="document">
    <div class="modal-content component-modal-content">
      <div class="modal-body component-modal-body">
        <h3 class="text-center">¡Éxito!</h3>
        <p class="text-center">El componente fue eliminado exitosamente!</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
      </div>
    </div>
  </div>
</div>
