<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>

<div class="container">
  <div class="loading" *ngIf="loading">
    <div class="spinner-border" role="status"></div>
  </div>
  <div class="container-product" *ngIf="!loading">
    <div class="header">
      <h1 class="title">Producto {{ product.product.productCode }}</h1>
      <app-back-arrow [route]="'/products'"></app-back-arrow>
    </div>

    <div class="loading" *ngIf="loading">
      <div class="spinner-border" role="status"></div>
    </div>

    <div class="form-card" *ngIf="!loading">
      <div class="product-info-container">

        <div *ngIf="!edit" class="info-container">
          <div class="form-label">Nombre:</div>
          {{ product.product.productName }}
        </div>

        <div *ngIf="!edit" class="info-container">
          <div class="form-label">Código:</div>
          {{ product.product.productCode }}
        </div>

        <div class="info-container">
          <div class="form-label">Estado:</div>
          <span *ngIf="product.product.productIsActive">Activo</span>
          <span *ngIf=" !product.product.productIsActive && (!edit || themingService.shopTheming[0].shopIntegration)"class="inactivo-text">Inactivo</span>
        </div>

        <div *ngIf="!edit && suppliers && suppliers.length > 0 && product.product.productSupplierId !== null" class="info-container">
          <div class="form-label">Proveedor:</div>
          <ng-container *ngFor="let supplier of suppliers">
            <span *ngIf="supplier.supplierId === product.product.productSupplierId">
              {{ supplier.supplierName }}
            </span>
          </ng-container>
        </div>

        <div *ngIf="!edit && industryTypes && industryTypes.length > 0 && product.product.productIndustryTypeId !== null" class="info-container">
          <div class="form-label">Industria:</div>
          <ng-container *ngFor="let industry of industryTypes">
            <span *ngIf="industry.industryTypeId === product.product.productIndustryTypeId">
              {{ industry.industryTypeName }}
            </span>
          </ng-container>
        </div>
        

        <div *ngIf="!edit && collections && collections.length > 0 && product.product.productCollectionId !== null" class="info-container">
          <div class="form-label">Colección:</div>
          <ng-container *ngFor="let collection of collections">
            <span *ngIf="collection.collectionId === product.product.productCollectionId">
              {{ collection.collectionName }}
            </span>
          </ng-container>
        </div>
        
        
        <div *ngIf="!edit && categories && categories.length > 0" class="info-container">
          <div class="form-label">Categoría:</div>
          <ng-container *ngFor="let category of categories">
            <span *ngIf="category.categoryId === product.product.productCategoryId">
              {{ category.categoryName }}
            </span>
          </ng-container>
        </div>
        
        <div *ngIf="!edit && subCategories && subCategories.length > 0 && product.product.productSubCategoryId !== 0" class="info-container">
          <div class="form-label">Subcategoría:</div>
          <ng-container *ngFor="let subCategory of subCategories">
            <span *ngIf="subCategory.subCategoryId ===product.product.productSubCategoryId">
              {{ subCategory.subCategoryName }}
            </span>
          </ng-container>
        </div>
        
        <div *ngIf="!edit && subSubCategories && subSubCategories.length > 0 && product.product.productSubSubCategoryId !== 0" class="info-container">
          <div class="form-label">Sub-subcategoría:</div>
          <ng-container *ngFor="let subSubCategory of subSubCategories">
            <span *ngIf="subSubCategory.subSubCategoryId === product.product.productSubSubCategoryId">
              {{ subSubCategory.subSubCategoryName }}
            </span>
          </ng-container>
        </div>
                
        <div class="form-label">Imágenes:</div>
        <div class="images-container">
          <div cdkDropList cdkDropListOrientation="horizontal" class="image-list" (cdkDropListDropped)="drop($event)">
            <div class="image-box" *ngFor="let image of productImages; let i = index" [cdkDragDisabled]="!edit"
              cdkDrag>
              <div class="image-placeholder" *cdkDragPlaceholder></div>
              <img [src]="image" />
              <button *ngIf="edit" type="button" class="btn btn-danger delete-button" (click)="deletePhoto(i)">
                x
              </button>
              <button type="button" class="btn btn-link download-button" (click)="downloadImage(i)">
                <i class="feather" data-feather="download"></i>
              </button>
            </div>
            <div class="image-box" cdkDragDisabled *ngIf="edit">
              <button class="add-img" (click)="uploadImage()">+</button>
            </div>
          </div>
        </div>

        <div class="form-label">Stock:</div>
        <div class="info-container">
          <table class="table table-bordered table-hover">
            <thead>
              <th scope="col" class="table-start">Item</th>
              <th scope="col">Código</th>
              <th scope="col">Cantidad</th>
              <th scope="col">Apartados</th>
              <th scope="col" class="table-end">Precio</th>
            </thead>
            <tbody id="variantsList">
              <tr *ngFor="let item of items">
                <td>{{objectValueJoin(item)}}</td>
                <td>{{item.itemCode}}</td>
                <td>{{item.itemQuantity}}</td>
                <td>{{item.itemStockCommitted}}</td>
                <td>{{ item.itemPrice | number : '1.2-2' }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div [hidden]="!edit">
          <label class="form-label" for="product_name">Nombre</label>
          <input type="text" class="form-control" id="product_name" name="name" placeholder="Nombre del producto"
            value="{{ product.product.productName }}" (keyup)="changeName($event)" />
        </div>

        <div [hidden]="!edit">
          <div class="form-label">Categoría:</div>
          <select class="form-select" id="product_category" name="category" (change)="changeCategory($event)">
            <option *ngFor="let category of categories" [value]="category.categoryId"
              [selected]="category.categoryId == product.product.productCategoryId">
              {{ category.categoryName }}
            </option>
          </select>
        </div>

        <div [hidden]="!edit">
          <div class="form-label">Industria:</div>
          <select class="form-select" id="product_industryType" name="industryType" (change)="changeIndustry($event)">
            <option selected disabled hidden>Selecione una industria</option>
            <option *ngFor="let industryType of industryTypes" [value]="industryType.industryTypeId"
              [selected]="industryType.industryTypeId == product.product.productIndustryTypeId">
              {{ industryType.industryTypeName }}
            </option>
          </select>
        </div>

        <div [hidden]="!edit">
          <div class="form-label">Proveedor:</div>
          <select class="form-select" id="product_supplier" name="supplier" (change)="changeSupplier($event)">
            <option selected disabled hidden>Selecione un proveedor</option>
            <option *ngFor="let supplier of suppliers" [value]="supplier.supplierId"
              [selected]="supplier.supplierId == product.product.productSupplierId">
              {{ supplier.supplierName }}
            </option>
          </select>
        </div>

        <div [hidden]="!edit">
          <div class="form-label">Colección:</div>
          <select class="form-select" id="product_collection" name="collection" (change)="changeCollection($event)">
            <option selected disabled hidden>Selecione una colección</option>
            <option *ngFor="let collection of collections" [value]="collection.collectionId"
              [selected]="collection.collectionId == product.product.productCollectionId">
              {{ collection.collectionName }}
            </option>
          </select>
        </div>

        <div class="info-container description">
          <div class="form-label">Descripción:</div>
          <div *ngIf="!edit" class="description-card ql-editor" [innerHTML]="product.product.productDescription"></div>
        </div>
        <!-- [customOptions]="customOptions" -->
        <quill-editor class="description-editor" [styles]="{
            borderRadius: '0 0 10px 10px',
            padding: '5px',
            marginBottom: '2vh'
          }" [theme]="'snow'" id="description-edit" *ngIf="edit" [modules]="modules"
          [(ngModel)]="product.product.productDescription" (onEditorChanged)="descriptionChanged($event)">
        </quill-editor>

        <div [hidden]="!edit">
          <label class="form-label" for="product_cost">Precio sin impuesto</label>
          <input type="number" min="0" class="form-control" id="product_cost" name="cost" placeholder="0.00"
            value="{{product.product.productBaseCost}}" (input)="changeBaseCost($event)" />
        </div>

        <div [hidden]="!edit">
          <label class="form-label" for="product_tax">IVA</label>
          <select class="form-select" id="product_tax" name="tax" (change)="changeTax($event)"
            [value]="product.product.productTaxId">
            <option selected>0%</option>
            <option value="1">13%</option>
          </select>
        </div>

        <hr class="page-separator">

        <div [hidden]="!edit">
          <label class="form-label" for="product_endprice">Precio final</label>
          <input type="number" min="0" class="form-control" id="product_endprice" name="endprice" placeholder="Precio final"
            value="{{product.product.productSellPrice || 0}}" (input)="changeSellPrice($event)" [disabled]="true"/>
        </div>

        <div [hidden]="!edit">
          <label class="form-label" for="product_sale">Descuento</label>
          <input type="number" min="0" class="form-control" id="product_sale" name="sale" placeholder="0%"
          (input)="changeDiscountPrice($event)" [value]="discountPercentage" />
        </div>

        <div [hidden]="!edit">
          <label class="form-label" for="product_revenue">Impuesto</label>
          <p id="product_revenue" *ngIf="product.product.productBaseCost > 0 && product.product.productSellPrice > 0">
            ₡{{revenueValue()}}
          </p>
          <p id="product_revenue"
            *ngIf="product.product.productBaseCost === 0 || product.product.productSellPrice === 0">
            ₡0
          </p>
        </div>

        <div [hidden]="!edit">
          <label class="form-label" for="product_revenue">Precio descuento</label>
          <p id="product_revenue" *ngIf="product.product.productDiscountPrice > 0">
            ₡{{product.product.productDiscountPrice}}
          </p>
          <p id="product_revenue" *ngIf="sale > 0 && product.product.productSellPrice > 0">
            ₡{{discountPrice()}}
          </p>
          <p id="product_revenue"
            *ngIf="sale === 0 || product.product.productSellPrice === 0 || sale === null || product.product.productSellPrice === null">
            ₡0
          </p>
        </div>

      </div>

      <div class="buttons-container" *ngIf="!loading">
        <button *ngIf="!edit" type="button" class="btn btn-primary primary-btn visually-hidden" (click)="editProduct()">
          Editar
        </button>
        <button type="button" class="btn btn-primary primary-btn" [routerLink]="['/products/edit/', productId]">
          Editar Producto
        </button>
      </div>
    </div>

      <div class="container-variables" [hidden]="true">
        <div class="switch-title-container">
          <h1 class="title">Variantes</h1>
          <label class="form-check-label switch" for="flexCheckDefault">
            <input class="form-check-input" type="checkbox" id="flexCheckDefault" [checked]="areVariablesChecked"
              (change)="variablesHidden($event)">
            <span class="slider round"></span>
          </label>
        </div>

        <div [hidden]="hiddenVar1">
          <div class="form-card">

            <!-- <div class="form-label">Imágenes:</div>
          <div class="images-container">
            <div cdkDropList cdkDropListOrientation="horizontal" class="image-list" (cdkDropListDropped)="drop($event)">
              <div class="image-box" *ngFor="let image of productImages.small; let i = index" [cdkDragDisabled]="false"
                cdkDrag>
                <div class="image-placeholder" *cdkDragPlaceholder></div>
                <img [src]="image.imageURL" />
                <button type="button" class="btn btn-danger delete-button" (click)="deletePhoto(i)">
                  x
                </button>
                <button type="button" class="btn btn-link download-button" (click)="downloadImage(i)">
                  <i class="feather" data-feather="download"></i>
                </button>
              </div>
              <div class="image-box" cdkDragDisabled>
                <button class="add-img" (click)="uploadImage()">+</button>
              </div>
            </div>
          </div> -->

            <div id="formVar1" class="variant-container">
              <div>
                <label class="form-label" for="product_variable_type">
                  Tipo
                </label>
                <input list="product_variable_type1" name="varieble_type" class="form-control"
                  placeholder="Ingrese el tipo" id="variantTypes1" />
                <datalist id="product_variable_type1">
                  <option value="Tamaño">
                  <option value="Color">
                  <option value="Material">
                  <option value="Estilo">
                </datalist>
              </div>
              <div class="value-label">
                <label class="form-label" for="product_variable_value">Valor</label>
                <button id="valBtn1" type="button" class="btn btn-outline-secondary  small-btn confirm-btn"
                  (click)="counterVariableInputs1 = addInput('formVar1', 'valBtn1', counterVariableInputs1); showBtn('delVariantBtn', counterVariableInputs1, 'formVar1')">
                  <i class="feather" data-feather="plus"></i>
                </button>
              </div>
            </div>
            <div class="delete-btn-container">
              <button type="button" class="btn btn-outline-secondary cancel-btn" (click)=" varBlock1(); counterVariableInputs1=removeElementsByClass('variantValueInput',
              counterVariableInputs1, 'valBtn1' )">
                Eliminar Variante
              </button>
            </div>

          </div>
        </div>

        <div [hidden]="hiddenVar2">
          <div class="form-card">

            <!-- <div class="form-label">Imágenes:</div>
                <div class="images-container">
                  <div cdkDropList cdkDropListOrientation="horizontal" class="image-list" (cdkDropListDropped)="drop($event)">
                    <div class="image-box" *ngFor="let image of productImages.small; let i = index" [cdkDragDisabled]="false"
                      cdkDrag>
                      <div class="image-placeholder" *cdkDragPlaceholder></div>
                      <img [src]="image.imageURL" />
                      <button type="button" class="btn btn-danger delete-button" (click)="deletePhoto(i)">
                        x
                      </button>
                      <button type="button" class="btn btn-link download-button" (click)="downloadImage(i)">
                        <i class="feather" data-feather="download"></i>
                      </button>
                    </div>
                    <div class="image-box" cdkDragDisabled>
                      <button class="add-img" (click)="uploadImage()">+</button>
                    </div>
                  </div>
                </div> -->

            <div id="formVar2" class="variant-container">
              <div>
                <label class="form-label" for="product_variable_type">
                  Tipo
                </label>
                <input list="product_variable_type2" name="varieble_type" class="form-control"
                  placeholder="Ingrese el tipo" id="variantTypes2" />
                <datalist id="product_variable_type2">
                  <option value="Tamaño">
                  <option value="Color">
                  <option value="Material">
                  <option value="Estilo">
                </datalist>
              </div>
              <div class="value-label">
                <label class="form-label" for="product_variable_value">Valor</label>
                <button id="valBtn2" type="button" class="btn btn-outline-secondary  small-btn confirm-btn"
                  (click)="counterVariableInputs2 = addInput('formVar2', 'valBtn2', counterVariableInputs2); showBtn('delVariantBtn', counterVariableInputs2, 'formVar2')">
                  <i class="feather" data-feather="plus"></i>
                </button>
              </div>
            </div>
            <div class="delete-btn-container">
              <button type="button" class="btn btn-outline-secondary cancel-btn" (click)=" varBlock2(); counterVariableInputs2=removeElementsByClass('variantValueInput',
                    counterVariableInputs2, 'valBtn2' )">
                Eliminar Variante
              </button>
            </div>

          </div>
        </div>

        <div [hidden]="hiddenVar3">
          <div class="form-card">

            <!-- <div class="form-label">Imágenes:</div>
                <div class="images-container">
                  <div cdkDropList cdkDropListOrientation="horizontal" class="image-list" (cdkDropListDropped)="drop($event)">
                    <div class="image-box" *ngFor="let image of productImages.small; let i = index" [cdkDragDisabled]="false"
                      cdkDrag>
                      <div class="image-placeholder" *cdkDragPlaceholder></div>
                      <img [src]="image.imageURL" />
                      <button type="button" class="btn btn-danger delete-button" (click)="deletePhoto(i)">
                        x
                      </button>
                      <button type="button" class="btn btn-link download-button" (click)="downloadImage(i)">
                        <i class="feather" data-feather="download"></i>
                      </button>
                    </div>
                    <div class="image-box" cdkDragDisabled>
                      <button class="add-img" (click)="uploadImage()">+</button>
                    </div>
                  </div>
                </div> -->

            <div id="formVar3" class="variant-container">
              <div>
                <label class="form-label" for="product_variable_type">
                  Tipo
                </label>
                <input list="product_variable_type3" name="varieble_type" class="form-control"
                  placeholder="Ingrese el tipo" id="variantTypes3" />
                <datalist id="product_variable_type1">
                  <option value="Tamaño">
                  <option value="Color">
                  <option value="Material">
                  <option value="Estilo">
                </datalist>
              </div>
              <div class="value-label">
                <label class="form-label" for="product_variable_value">Valor</label>
                <button id="valBtn3" type="button" class="btn btn-outline-secondary  small-btn confirm-btn"
                  (click)="counterVariableInputs3 = addInput('formVar3', 'valBtn3', counterVariableInputs3); showBtn('delVariantBtn', counterVariableInputs3, 'formVar3')">
                  <i class="feather" data-feather="plus"></i>
                </button>
              </div>
            </div>
            <div class="delete-btn-container">
              <button type="button" class="btn btn-outline-secondary cancel-btn" (click)=" varBlock3(); counterVariableInputs3=removeElementsByClass('variantValueInput',
                    counterVariableInputs3, 'valBtn3' )">
                Eliminar Variante
              </button>
            </div>

          </div>
        </div>

        <div class="variant-actions-container">
          <button type="button" class="btn btn-outline-secondary confirm-btn" (click)="showVariables()"
            [hidden]="!areVariablesChecked" [disabled]="variableButtonDisabled()">
            Agregar Variante
          </button>
          <button type="button" class="btn btn-outline-secondary submit-btn" [hidden]="!areVariablesChecked"
            (click)="enableItemTable(); fillVariantArrays(); fillVariantArraysToGenerateItems(); determineCombinationsLength();">
            Crear listado de variantes
          </button>
        </div>

      </div>

      <div class="form-card" [hidden]="!displayItemsTable">
        <table class="table table-bordered table-hover">
          <thead>
            <th scope="col" class="table-start">Item</th>
            <th scope="col">Precio</th>
            <th scope="col">Cantidad</th>
            <th scope="col" class="table-end">Código</th>
          </thead>
          <tbody id="variantsCombinationList"></tbody>
        </table>
      </div>

    <div class="buttons-container" *ngIf="!loading">
      <button *ngIf="edit" type="button" class="btn btn-success confirm-btn" (click)="saveChanges()">
        Guardar
      </button>
      <button *ngIf="edit" type="button" class="btn btn-danger cancel-btn" (click)="cancelEdit()">
        Cancelar
      </button>
    </div>
  </div>
</div>

<div class="modal product-modal" id="productModal" tabindex="-1">
  <div class="modal-dialog product-modal-dialog" role="document">
    <div class="modal-content product-modal-content">
      <div class="modal-body product-modal-body">
        <div class="loading">
          <div class="spinner-border" role="status"></div>
        </div>
      </div>
    </div>
  </div>
</div>