<div class="container">
  <div *ngIf="paymentId" class="container-paymentMethod">
    <div class="header">
      <h1 class="title">Editar Método de Pago</h1>
      <app-back-arrow-settings [callback]="setListingPaymentMethods"></app-back-arrow-settings>
    </div>
    <div class="form-card">
      <div class="loading" *ngIf="loading">
      <div class="spinner-border" role="status"></div>
    </div>
    <div *ngIf="!loading && paymentId" class="form-group-container">

      <!-- CREDIT CARD -->
      <div class="row" *ngIf="paymentId == '1'">
        <div class="col-md-10 d-flex align-items-center">
          <h3 class="subtitle">
            Tárjetas de Crédito y Débito
          </h3>
        </div>
        <div class="col-md-2">
          <div class="switch">
            <label class="switch-label">
                <input type="checkbox"id="shop_point_status" name="status" [(ngModel)]="isActive"/>
                <span class="slider round"></span>
            </label>
          </div>
          <div>
            <span id="statusText" class="status-text">{{isActive ? "Activo" : "Inactivo"}}</span>
          </div>
        </div>
        <p>Pasarela FAC del BAC, seleccionar el ambiente e ingresar los datos del usuario brindado por la pasarela.</p>
        <form [formGroup]="creditCardForm">
          <div class="col-12">
            <label class="form-label is-required">Ambiente</label>
            <select class="form-control" formControlName="enviroment">
              <option value="staging" selected>Pruebas / Staging</option>
              <option value="prod">Producción</option>
            </select>
          </div>
          <div class="col-12">
            <label class="form-label is-required">Id</label>
            <input type="text" class="form-control" formControlName="user" placeholder=""/>
          </div>
          <div class="col-12">
            <label class="form-label is-required">Contraseña</label>
            <input type="text" class="form-control" formControlName="password" placeholder=""/>
          </div>
          <div class="col-12 d-flex justify-content-center form-buttons">
            <button type="button" class="btn submit-btn" (click)="validateFormData()">
              Guardar
            </button>
          </div>
        </form>
      </div>
      <!-- SINPE MOVIL -->
      <div class="row" *ngIf="paymentId == '2'">
        <div class="col-md-10 d-flex align-items-center">
          <h3 class="subtitle">
            Sinpe Móvil
          </h3>
        </div>
        <div class="col-md-2">
          <div class="switch">
            <label class="switch-label">
                <input type="checkbox"id="shop_point_status" name="status" [(ngModel)]="isActive"/>
                <span class="slider round"></span>
            </label>
          </div>
          <div>
            <span id="statusText" class="status-text">{{isActive ? "Activo" : "Inactivo"}}</span>
          </div>
        </div>
        <form [formGroup]="sinpeMovilForm">
          <div class="col-12">
            <label class="form-label is-required">Número</label>
            <input type="text" class="form-control" formControlName="phone" value="currentPaymentConfig.phone" placeholder=""/>
          </div>
          <div class="col-12">
            <label class="form-label">Detalles del pago</label>
            <textarea class="form-control" formControlName="description" rows="4" placeholder="A nombre de Mobydyg"></textarea>
            <small class="form-text">
              Mensaje adicional que se mostrará en el carrito de compra al seleccionar este método de pago.
              Este mensaje es adicional al número de Sinpe Móvil
            </small>
          </div>
          <div class="col-12 d-flex justify-content-center form-buttons">
            <button type="button" class="btn submit-btn" (click)="validateFormData()">
              Guardar
            </button>
          </div>
        </form>
      </div>
      <!-- TRANSFER -->
      <div class="row" *ngIf="paymentId == '3'">
        <div class="col-md-10 d-flex align-items-center">
          <h3 class="subtitle">
            Trasferencia bancaria
          </h3>
        </div>
        <div class="col-md-2">
          <div class="switch">
            <label class="switch-label">
                <input type="checkbox"id="shop_point_status" name="status" [(ngModel)]="isActive"/>
                <span class="slider round"></span>
            </label>
          </div>
          <div>
            <span id="statusText" class="status-text">{{isActive ? "Activo" : "Inactivo"}}</span>
          </div>
        </div>
        <p>Ingresar la o las cuentas bancarias disponibles para pagos por medio de tranferencia bancaria</p>
        <form [formGroup]="transferForm">
          <div class="col-12">
            <label class="form-label is-required">Cuenta IBAN</label>
            <input type="text" class="form-control" formControlName="iban" value="currentPaymentConfig.iban" placeholder=""/>
          </div>
          <div class="col-12">
            <label class="form-label">Cuenta BAC</label>
            <input type="text" class="form-control" formControlName="bac" placeholder=""/>
          </div>
          <div class="col-12 visually-hidden">
            <label class="form-label">Cuenta Banco Nacional</label>
            <input type="text" class="form-control" formControlName="bn" placeholder=""/>
          </div>
          <div class="col-12 visually-hidden">
            <label class="form-label">Cuenta Banco de Costa Rica</label>
            <input type="text" class="form-control" formControlName="bcr" placeholder=""/>
          </div>
          <div class="col-12">
            <label class="form-label">Detalles del pago</label>
            <textarea class="form-control" formControlName="description" rows="4" placeholder="A nombre de Mobydyg"></textarea>
            <small class="form-text">
              Mensaje adicional que se mostrará en el carrito de compra al seleccionar este método de pago.
              Este mensaje es adicional a los números de cuenta.
            </small>
          </div>
          <div class="col-12 d-flex justify-content-center form-buttons">
            <button type="button" class="btn submit-btn" (click)="validateFormData()">
              Guardar
            </button>
          </div>
        </form>
      </div>
      <!-- PayValida -->
      <div class="row" *ngIf="paymentId == '5'">
        <div class="col-md-10 d-flex align-items-center">
          <h3 class="subtitle">
            PayValida
          </h3>
        </div>
        <div class="col-md-2">
          <div class="switch">
            <label class="switch-label">
                <input type="checkbox"id="shop_point_status" name="status" [(ngModel)]="isActive"/>
                <span class="slider round"></span>
            </label>
          </div>
          <div>
            <span id="statusText" class="status-text">{{isActive ? "Activo" : "Inactivo"}}</span>
          </div>
        </div>
        <form [formGroup]="payValidaForm">
          <div class="col-12">
            <label class="form-label is-required">Merchant Id</label>
            <input type="text" class="form-control" formControlName="merchantId" value="currentPaymentConfig.merchantId"/>
          </div>
          <div class="col-12">
            <label class="form-label is-required">Client Secret</label>
            <input type="text" class="form-control" formControlName="clientSecret" value="currentPaymentConfig.clientSecret"/>
          </div>
          <div class="col-12">
            <label class="form-label is-required">Notification Secret</label>
            <input type="text" class="form-control" formControlName="notificationSecret" value="currentPaymentConfig.notificationSecret"/>
          </div>
          <div class="col-12">
            <label class="form-label is-required">Código País</label>
            <input type="text" class="form-control" formControlName="countryCode" value="currentPaymentConfig.countryCode"/>
          </div>
          <div class="col-12">
            <label class="form-label is-required">Código Moneda</label>
            <input type="text" class="form-control" formControlName="currencyCode" value="currentPaymentConfig.currencyCode"/>
          </div>
          <div class="col-12 d-flex justify-content-center form-buttons">
            <button type="button" class="btn submit-btn" (click)="validateFormData()">
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal paymentMethod-confirm-modal fade" id="successPaymentMethodModal" tabindex="-1">
  <div class="modal-dialog paymentMethod-modal-dialog" role="document">
    <div class="modal-content paymentMethod-modal-content">
      <div class="modal-body paymentMethod-modal-body">
        <h3 class="text-center">¡Éxito!</h3>
        <p class="text-center">¡El Punto de venta fue guardado exitosamente!</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
      </div>
    </div>
  </div>
</div>
<div class="modal error-confirm-modal fade" id="requiredPaymentMethodModal" tabindex="-1">
	<div class="modal-dialog error-modal-dialog" role="document">
		<div class="modal-content error-modal-content">
			<div class="modal-body error-modal-body">
				<h3 class="text-center">¡Error!</h3>
				<p class="text-center">Completar todos los campos requeridos correctamente</p>
			</div>
			<div class="modal-buttons">
				<button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
			</div>
		</div>
	</div>
</div>
<div class="modal paymentMethod-confirm-modal fade" id="errorPaymentMethodModal" tabindex="-1">
  <div class="modal-dialog paymentMethod-modal-dialog" role="document">
    <div class="modal-content paymentMethod-modal-content">
      <div class="modal-body paymentMethod-modal-body">
        <h3 class="text-center">¡Error!</h3>
        <p class="text-center">¡Hubo un error al guardar los cambios!</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
      </div>
    </div>
  </div>
</div>
