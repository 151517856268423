import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { SettingsService } from 'src/app/settings.service';
import { ThemingService } from 'src/app/theming.service';
declare let $: any;

@Component({
  selector: 'app-edit-shop-point',
  templateUrl: './edit-shop-point.component.html',
  styleUrls: ['./edit-shop-point.component.scss']
})
export class EditShopPointComponent implements OnInit {

  shopPoint: any;
  shopPoints: any =[];
  loading: boolean = true;

  isActive = false;

  @Input() shopPointId!: string;

  constructor(
    private settingsService: SettingsService,
    private apiService: ApiService,
    private themingService: ThemingService
  ) { }

  ngOnInit(): void {
    this.getEditingShopPoint();
    this.getShopPointById(parseInt(this.shopPointId),'');
  }

  getEditingShopPoint(){
    this.shopPoint = this.settingsService.getEditingShopPoint();
  }

  setListingShopPoints(){
    this.settingsService.setListingShopPoints();
  }

  getShopPointById(id: number, filter: string) {
  this.loading = true;
  this.apiService
    .getShopPointById(id, this.themingService.shopId)
    .then((data: any) => {
      this.shopPoint = data;
      if(data.shopPointStatus == 1){
        this.isActive = true;
      }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      setTimeout(() => {
        this.loading = false;
      }, 500); 
    });
}


  changeShopPointName(event: Event){
    const element = event.target as HTMLInputElement;
    this.shopPoint.shopPointName = element.value;
  }
  
  changeShopPointLocation(event: Event){
    const element = event.target as HTMLInputElement;
    this.shopPoint.shopPointLocation = element.value;
  }

  changeShopPoinPhone(event: Event){
    const element = event.target as HTMLInputElement;
    this.shopPoint.shopPointPhone = element.value;
  }


  validateFormData(){
    this.loading = true;
    this.apiService.updateShopPoint(
      this.themingService.shopId,
      parseInt(this.shopPointId),
      this.shopPoint.shopPointName,
      this.shopPoint.shopPointLocation,
      this.shopPoint.shopPointPhone,
      this.isActive?1:0
    )
    .then((data: any) => {
      if(data.statusCode === 200){
        $('#successShopPointModal').modal('show');
        this.loading = false;
      }
      else{
        $('#errorShopPointModal').modal('show');
      }
    })
    .catch((error) => {
      console.error(error);
      this.loading = false;
    });
  
    }

  closeModal(){
    $('#successShopPointModal').modal('hide');
    $('#errorShopPointModal').modal('hide');
  }
}
