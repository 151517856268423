import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { AuthService, alertsAnimation } from 'src/app/auth.service';
import { AppComponent } from 'src/app/app.component';
import { Router, ActivatedRoute } from '@angular/router';
import { WebsocketService } from 'src/app/websocket.service';
import { UserPoolService } from 'src/app/userPool.service';
import { ThemingService } from 'src/app/theming.service';
import { ApiService } from 'src/app/api.service';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  animations: [
    alertsAnimation,
    trigger('slideLeft', [
      state('void', style({ transform: 'translateX(0)' })),
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.5s ease-in-out'),
      ]),
    ]),
    trigger('fade', [
      state('void', style({ opacity: 0 })),
      transition(':enter, :leave', [animate('0s ease-in-out')]),
    ]),
  ],
  host: { '[@inOutAnimation]': '' },
})
export class SignInComponent implements OnInit {
  exceptionMessage: String = '';
  isPasswordVisible: boolean = false;
  isConfirmPasswordVisible: boolean = false;
  rememberMe: boolean = false;
  needsNewPassword: boolean = false;
  userLoggin: any;
  currentStep: number = 1;
  state: boolean = false;
  shopId: number = 1;
  shops: any[] = []; 

  public signInForm!: FormGroup;
  public changePasswordForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private appComponent: AppComponent,
    private authService: AuthService,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private websocketService: WebsocketService,
    private userPoolService: UserPoolService,
    private themingService: ThemingService
  ) {
    /* Creating a form group with two controls. */
    this.signInForm = this.fb.group({
      givenEmail: ['', [Validators.required, Validators.email]],
      givenShop: ['', [Validators.required]],
      givenPassword: ['', [Validators.required]],
    });

    this.changePasswordForm = this.fb.group(
      {
        newPassword: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?!.* ).{8,16}$'
            ),
          ],
        ],
        confirmNewPassword: ['', [Validators.required]],
      },
      {
        validators: this.authService.confirmPasswordValidator(
          'newPassword',
          'confirmNewPassword'
        ),
      }
    );
  }

  /**
   * It returns the controls of the signInForm.
   * @returns The signInForm.controls object.
   */
  get signUpFormValidation() {
    return this.signInForm.controls;
  }

  /**
   * It returns the form controls of the changePasswordForm
   * @returns The form controls.
   */
  get changePasswordFormValidation() {
    return this.changePasswordForm.controls;
  }

  /**
   * The ngOnInit function checks for stored user and shop information and sets the form values
   * accordingly, as well as setting a rememberMe flag and retrieving the current step from session
   * storage.
   */
  ngOnInit(): void {
    if (localStorage.getItem('userEmail') != null && sessionStorage.getItem('selectedEmail') != null) {
      this.signInForm.patchValue({
        givenEmail: localStorage.getItem('userEmail'),
      });
      localStorage.setItem('rememberMe', 'true');
    }

    if (sessionStorage.getItem('selectedEmail') != null) {
      this.signInForm.patchValue({
        givenShop: sessionStorage.getItem('selectedShop'),
        givenEmail: sessionStorage.getItem('selectedEmail'),
      });
    }
    this.rememberMe = localStorage.getItem('rememberMe') ? true : false;
    this.currentStep = Number(sessionStorage.getItem('selectedStep')) === 0 ? 1 : Number(sessionStorage.getItem('selectedStep'))
  }

  
  /**
   * The function toggles the value of a boolean variable based on a checkbox input and removes a
   * localStorage item if the checkbox is unchecked.
   * @param {any} event - The event parameter is an object that represents the event that triggered the
   * onRememberMe function. It contains information about the event, such as the type of event, the
   * target element, and any additional data related to the event. In this case, the event is a
   * checkbox being checked or unchecked.
   */
  onRememberMe(event: any) {
      event.target.checked ? (this.rememberMe = true) : (this.rememberMe = false);
      if(!this.rememberMe){
        localStorage.removeItem('rememberMe');
      }
  }

  /**
   * It navigates to the recover-password component and passes the email value from the sign-in form as a
   * query parameter.
   */
  recoveryPassword() {
    this.router.navigate(['/recover-password']);
  }

  /**
   * It takes the email and password from the form, sends it to the authService, and if the user is
   * authenticated, it navigates to the my-shops page.
   */
  onSignIn() {
    // Notification.requestPermission().then(function (permission) {
    //   console.log('permiss', permission);
    // });
    sessionStorage.removeItem('selectedShop');
    sessionStorage.removeItem('selectedEmail');
    sessionStorage.removeItem('selectedStep');
    this.authService
      .onLogIn(
        this.signInForm.value.givenEmail,
        this.signInForm.value.givenPassword
      )
      .then((user) => {
        this.rememberMe
          ? localStorage.setItem('userEmail', this.signInForm.value.givenEmail)
          : localStorage.removeItem('userEmail');
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.needsNewPassword = true;
          this.userLoggin = user;
        } else {
          this.websocketService.sendShopId();
          this.router.navigate(['/home']);
        }
      })
      .catch((error) => {
        this.exceptionMessage = this.authService.handleErrors(
          error.message || JSON.stringify(error),
          this.signInForm.value.givenEmail
        );
      });
  }

  onChangePassword() {
    this.authService
      .onNewPasswordRequired(
        this.userLoggin,
        this.changePasswordForm.value.confirmNewPassword
      )
      .then(() => {
        this.router.navigate(['/home']);
      })
      .catch((error) => {
        this.exceptionMessage = this.authService.handleErrors(
          error.message || JSON.stringify(error),
          this.signInForm.value.givenEmail
        );
      });
  }

  /**
   * The function checks if the current step in a sign-up form is valid based on the presence of errors
   * in specific form fields.
   * @returns a boolean value indicating whether the current step in a sign-up form is valid or not.
   */
  isStepInvalid() {
    let stepValid = true;
    switch (this.currentStep){
      case 1:
        stepValid = this.signUpFormValidation['givenEmail'].errors ? true: false
        break;
      case 2:
        stepValid = this.signUpFormValidation['givenShop'].errors ? true : false;
        break;
      case 3:
      stepValid = this.signUpFormValidation['givenPassword'].errors ? true : false;
        break;
      default: stepValid = true;
        break;
    }
    return stepValid;
  }

  /**
   * This function handles the next step in a multi-step form, with additional logic for production
   * environments.
   * @param {any} e - The parameter `e` is an event object, likely triggered by a user action such as a
   * button click or form submission.
   */
  async nextStep(e: any) {
    e.preventDefault();
    e.target.disabled = true;

    if(this.currentStep === 1){
      const pools = await this.userPoolService.getUserPools(this.signInForm.value.givenEmail) ?? [];
      if(pools.length === 0){
        e.target.disabled = false;
        this.exceptionMessage = 'Este usuario no está registrado.'
        return;
      }
      await this.apiService.getShopsByPools(pools.join(',')).then((data: any) => {
        this.shops = data;
        if(this.shops.length === 1){
          const [myShop] = this.shops; 
          this.shopId = myShop.shopId;
          this.currentStep = 3;
        }
      });
      
    }

    if(this.currentStep !== 3){
      this.state = !this.state;
      this.currentStep++;
    }

    if(this.currentStep === 3){
      history.pushState(
        {},
        'null',
        window.location.pathname + `?shopId=${this.shopId}`
      );
      this.router.events.subscribe((val) => {
        history.pushState(
          {},
          'null',
          window.location.pathname + `?shopId=${this.shopId}`
        );
      });
      sessionStorage.setItem('selectedEmail', this.signInForm.value.givenEmail);
      sessionStorage.setItem('selectedStep', this.currentStep.toString());
      window.location.reload()
    }
    
  }

  /**
   * This function handles going back to the previous step in a multi-step process.
   * @param {Event} e - The parameter "e" is an event object that is passed as an argument to the
   * function. It is used to prevent the default behavior of the event and to access properties and
   * methods related to the event.
   */
  prevStep(e: Event) {
    e.preventDefault();
    if(this.currentStep !== 1){
      this.currentStep = 1;
      sessionStorage.removeItem('selectedStep');
      this.state = !this.state;
      this.exceptionMessage = '';
    }
  }

  /**
   * The function changes the value of the shopId property based on the selected option in a dropdown
   * menu.
   * @param {Event} event - Event is a built-in interface in TypeScript that represents an event that
   * occurs in the DOM (Document Object Model) of a web page. It contains information about the event,
   * such as the type of event, the target element that triggered the event, and any additional data
   * related to the event. In this
   */
  changeShop(event: Event){
    const element = event.target as HTMLSelectElement;
    this.shopId = this.shops.find(item => item.shopId === parseInt(element.value)).shopId;
  }
}
