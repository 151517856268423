import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { ShopTheming, ThemingService } from 'src/app/theming.service';

@Component({
  selector: 'app-current-plan',
  templateUrl: './current-plan.component.html',
  styleUrls: ['./current-plan.component.scss']
})
export class CurrentPlanComponent implements OnInit {
  public shopTheme: ShopTheming[] = [];
  public  shopPlan: string = '';
  public planClass = '';

  constructor(
    private authService: AuthService,
    private themingService: ThemingService,
    private router: Router
  ) {
    this.shopTheme = this.themingService.shopTheming;
  }

  ngOnInit(): void {
    this.loadCurrentPlan();
  }

  loadCurrentPlan() {
    let currentPlan = this.shopTheme[0].currentContract;

    if (currentPlan === 'Basic') {
      this.shopPlan = currentPlan;
      this.planClass = 'basic';
    } else if (currentPlan === 'Advanced') {
      this.shopPlan = currentPlan;
      this.planClass = 'advanced';
    } else if (currentPlan === 'Corporate') {
      this.shopPlan = currentPlan;
      this.planClass = 'corporate';
    }
  }

}
