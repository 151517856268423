<div class="container">
  <div *ngIf="collection" class="container-collection">
    <div class="header">
      <h1 class="title">Editar Colección</h1>
      <app-back-arrow-settings [callback]="setListingCollections"></app-back-arrow-settings>
    </div>
    <div class="form-card">
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <div *ngIf="!loading" class="form-group-container">
        <label class="form-label" for="collection_name">Nombre</label>
        <input type="text" class="form-control" id="collectionName"
          placeholder="Nombre de la colección"
          (keyup)="changeCollectionName($event)" value="{{collection.collectionName}}" #collectionName/>
        <label class="form-label" for="collection_description">Descripción</label>
        <textarea class="form-control" id="collectionDescription" placeholder="Descripción de la colección" 
          (keyup)="ChangeCollectionDescription($event)" #collectionDescription>{{collection.collectionDescription}}</textarea>
        <div class="form-buttons">
          <button type="button" class="btn btn-outline-secondary submit-btn" (click)="validateFormData()">
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal collection-confirm-modal fade" id="successCollectionModal" tabindex="-1">
  <div class="modal-dialog collection-modal-dialog" role="document">
    <div class="modal-content collection-modal-content">
      <div class="modal-body collection-modal-body">
        <h3 class="text-center">¡Éxito!</h3>
        <p class="text-center">¡La colección fue guardada exitosamente!</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal collection-confirm-modal fade" id="errorCollectionModal" tabindex="-1">
  <div class="modal-dialog collection-modal-dialog" role="document">
    <div class="modal-content collection-modal-content">
      <div class="modal-body collection-modal-body">
        <h3 class="text-center">¡Error!</h3>
        <p class="text-center">¡Hubo un error al guardar los cambios!</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
      </div>
    </div>
  </div>
</div>
