<div class="container">
    <div *ngIf="shopPoint" class="container-shopPoint">
        <div class="header">
            <h1 class="title">Editar Punto de Venta</h1>
            <app-back-arrow-settings [callback]="setListingShopPoints"></app-back-arrow-settings>
        </div>
        <div class="form-card">
            <div class="loading" *ngIf="loading">
            <div class="spinner-border" role="status"></div>
        </div>
        <div *ngIf="!loading" class="form-group-container">
            <div class="row">
                <div class="col-12">
                    <label class="form-label" for="shopPoint_name">Nombre</label>
                    <input type="text" class="form-control" id="shopPointName"
                        placeholder="Nombre del punto de venta"
                        (keyup)="changeShopPointName($event)" value="{{shopPoint.shopPointName}}" #shopPointName/>
                </div>
                <div class="col-10">
                    <label class="form-label" for="shopPoint_name">Teléfono</label>
                    <input type="text" class="form-control" id="shopPointPhone"
                        placeholder="Nombre del punto de venta"
                        (keyup)="changeShopPoinPhone($event)" value="{{shopPoint.shopPointPhone}}" #shopPointPhone/>
                </div>
                <div class="col-2">
                    <div><label class="form-label" for="shop_point_status">Estado</label></div>
                    <div class="switch">
                    <label class="switch-label">
                        <input type="checkbox"id="shop_point_status" name="status" [(ngModel)]="isActive"/>
                        <span class="slider round"></span>
                    </label>
                    </div>
                    <div><span id="statusText" class="status-text">{{isActive ? "Activo" : "Inactivo"}}</span></div>
                </div>
                <div class="col-12">
                    <label class="form-label" for="shopPointLocation">Ubicación</label>
                    <textarea class="form-control" id="shopPointLocation" placeholder="Ubicación del Punto de venta" 
                        (keyup)="changeShopPointLocation($event)" #shopPointLocation>{{shopPoint.shopPointLocation}}</textarea>
                    <div class="form-buttons">
                        <button type="button" class="btn btn-outline-secondary submit-btn" (click)="validateFormData()">
                        Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  
  <div class="modal shopPoint-confirm-modal fade" id="successShopPointModal" tabindex="-1">
    <div class="modal-dialog shopPoint-modal-dialog" role="document">
      <div class="modal-content shopPoint-modal-content">
        <div class="modal-body shopPoint-modal-body">
          <h3 class="text-center">¡Éxito!</h3>
          <p class="text-center">¡El Punto de venta fue guardado exitosamente!</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal shopPoint-confirm-modal fade" id="errorShopPointModal" tabindex="-1">
    <div class="modal-dialog shopPoint-modal-dialog" role="document">
      <div class="modal-content shopPoint-modal-content">
        <div class="modal-body shopPoint-modal-body">
          <h3 class="text-center">¡Error!</h3>
          <p class="text-center">¡Hubo un error al guardar los cambios!</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
  