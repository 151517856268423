import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
import { SettingsService } from 'src/app/settings.service';
import { ThemingService } from 'src/app/theming.service';

@Component({
  selector: 'app-cash-register',
  templateUrl: './cash-register.component.html',
  styleUrls: ['./cash-register.component.scss']
})
export class CashRegisterComponent implements OnInit {
  cashRegisterId!: number; 
  loading: boolean = false;
  cashRegister: any;
  salePayments: any[] = [];
  paymentTypeTotal: any[] = [];
  vendorInfo: any;
  totalPaymentAmount: number = 0; 
  subtotal: number = 0; 
  total: number = 0;

  constructor(
    private route: ActivatedRoute,
    public settingsService: SettingsService,
    private apiService: ApiService,
    private themingService: ThemingService,
    public appComponent: AppComponent,
  ) { 
    this.route.paramMap.subscribe((params) => {
      this.cashRegisterId = Number(params.get('cashRegisterId'));
    });
  }

  ngOnInit(): void {
    this.getCashRegisterById(this.cashRegisterId);
  }

  getCashRegisterById(id: number) {
    this.loading = true;
    this.apiService
      .getCashRegisterById(id, this.themingService.shopId)
      .then((data: any) => {
        console.log(data);
        this.cashRegister = data.cashRegister;
        this.salePayments = data.salePayments;
        this.paymentTypeTotal = data.paymentTypeTotal;
        this.vendorInfo = data.vendorInfo;
        this.subtotal = this.paymentTypeTotal.reduce((total, paymentType) => total + paymentType.paymentTypeTotal, 0);
        this.totalPaymentAmount = this.subtotal - this.cashRegister.initialAmount + this.cashRegister.cashDeposit - this.cashRegister.cashWithdrawn;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}

