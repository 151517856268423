import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { alertsAnimation, AuthService } from 'src/app/auth.service';
import { SettingsService } from 'src/app/settings.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  animations: [ alertsAnimation ]
})
export class UserDetailsComponent implements OnInit {

  @Input()
  username!: string;

  user: any;
  isLoaded: boolean = false;
  exceptionMessage: String = '';

  modalDisable: boolean = false;
  modalEnable: boolean = false;
  modalDelete: boolean = false;
  deleteFeedbackModal: boolean = false;
  userStatus: string = '';
  updatePermissions: boolean = false;
  configAccess: boolean = false;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private authService: AuthService,
      private settingsService: SettingsService) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      // let username = params['Username'];
      this.authService.getUser(this.username).then(user=>{
        this.user = user;
        this.userStatusDisplay(this.user.UserStatus);
      }).finally(()=>{
        this.isLoaded = true;
      });
    });
  }

  updateUser() {
    this.authService.updateUser(
      this.user.Username,
      this.user.given_name,
      this.user.family_name,
      this.user.phone_number, 
      this.user.permissions 
    ).then(response => {
      this.updatePermissions = true;
      console.log('User updated successfully');
    }).catch(error => {
      this.exceptionMessage = error.message;
    });
  }
  
  reloadUser(username: string = this.user.Username){
    this.authService.getUser(username).then(user=>{
      this.user = user;
      this.userStatusDisplay(this.user.UserStatus);
    })
  }

  disableUserAccess(response: boolean){
    if(response){
      this.authService.disableUserAccess(this.user.Username).then(() =>{
        this.reloadUser();
        this.modalDisable = false;
      }).catch(error=>{
        this.exceptionMessage = error.message;
      });
    } else {
      this.modalDisable = false;
    }
  }

  enableUserAccess(response: boolean){
    if(response){
      this.authService.enableUserAccess(this.user.Username).then(() =>{
        this.reloadUser();
        this.modalEnable = false;
      }).catch(error=>{
        this.exceptionMessage = error.message;
      });
    } else {
      this.modalEnable = false;
    }
  }

  deleteUser(response: boolean){
    if(response){
      this.authService.deleteUser(this.user.Username).then(response=>{
      }).catch(error=>{
        this.exceptionMessage = error.message;
      }).finally(()=>{
        this.modalDelete = false;
        this.deleteFeedbackModal = true;
      });
    } else {
      this.modalDelete = false;
    }
  }

  goToUsers(response: boolean){
    if(response){
      this.router.navigate(['/users']);
    }
  }

  userStatusDisplay(status: string){
    switch(status){
      case 'CONFIRMED':
        this.userStatus = 'Confirmado';
        break;

      case 'UNCONFIRMED':
        this.userStatus = 'Sin confirmar';
        break;

      case 'ARCHIVED':
        this.userStatus = 'Archivado';
        break;

      case 'UNKNOWN':
        this.userStatus = 'Desconocido';
        break;

      case 'RESET_REQUIRED':
        this.userStatus = 'Reinicio requerido';
        break;

      case 'FORCE_CHANGE_PASSWORD':
        this.userStatus = 'Cambio de contraseña requerido';
        var date = new Date();
        date.setDate(date.getDate() - 7);
        //console.log(date);
        break;
    }
  }

  /**
   * The function calls the setListingUsers() function in the settingsService
   */
  setListingUsers(): void {
    this.settingsService.setListingUsers();
  }
}
